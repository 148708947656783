$(document).ready(function () {
    var is_desktop = false;
    if ($('#is_desktop').length > 0) {
        is_desktop = $('#is_desktop').data('value') ? false : true;
    }



    $("#attribute_tagname").on("keyup change", function(e) {
        // var regex = /^[0-9A-Za-z'-\S*]+$/;

        var regex = /^[0-9A-Za-z-()]+$/;
        if(!regex.test($(this).val())){
            var str = $(this).val();
            $(this).val(str.slice(0, -1));
            $(".errorTxt2").css('color','red').html("Only alphabets and dash symbol allowed.");
        }
        else{
            $(".errorTxt2").html("");
        }
    })



    if ($('#CriteriaTable').length > 0) {
        var CriteriaTable = $('#CriteriaTable').DataTable({
            responsive: is_desktop,
            processing: true,
            serverSide: true,
            lengthChange: false,
            paging: false,
            info: false,
            ordering: false,
            ajax: {
                "url": "/settings/config/criteria/ajax",
                "type": "POST",
                "data": function (d) {
                    d._token = $('meta[name="csrf-token"]').attr('content');
                    d.from   = $('#filter-from').val();
                    d.to     = $('#filter-to').val();
                },
            },
            columns: [
                {
                    data: 'name',
                    name: 'name',
                    render: function (data, type, row){
                        let dis = '';
                        if (row.is_dimension) {
                            dis = '<h6><b>'+data+'</b></h6>';
                        } else {
                            dis = '&emsp;'+data+'';
                        }
                        return dis;
                    }
                },
                {
                    data: 'status',
                    name: 'status',
                    render: function (data, type, row) {
                        let dis = '';
                        if (!row.is_dimension) {
                            dis = data == '1' ? '<span class="chip green lighten-5"><span class="green-text">Active</span></span>' : '<span class="chip red lighten-5"><span class="red-text">Disabled</span></span>';
                        }
                        return dis;
                    }
                },
                {
                    data: 'id',
                    name: 'id',
                    orderable: false,
                    className: "dt-body-right",
                    render: function (data, type, row) {
                        let dis = '';
                        if (!row.is_dimension) {
                            dis  = '<button type="button"  data-id="' + data + '"  class="mb-6 btn-floating btn-small waves-effect waves-light indigo gradient-shadow mr-3 tooltipped SettingEditCriteria" data-position="left" data-tooltip="Config"><i class="material-icons">settings</i></button>';
                            if (row.status == '1') {
                                dis += '<button type="button" data-id="' + data + '"  class="mb-6 btn-floating btn-small waves-effect waves-light red gradient-shadow mr-3 tooltipped SettingEditCriteriaStatus" data-position="left" data-tooltip="Disable" data-status="disable"><i class="material-icons">do_not_disturb_on</i></button>';
                            } else {
                                dis += '<button type="button" data-id="' + data + '"  class="mb-6 btn-floating btn-small waves-effect waves-light green gradient-shadow mr-3 tooltipped SettingEditCriteriaStatus" data-position="left" data-tooltip="Enable" data-status="active"><i class="material-icons">check_circle</i></button>';
                            }
                            dis += '<button type="button" ' + (row.enable_delete ? '' : 'disabled') + ' data-id="' + (row.enable_delete ? data : '') + '"  class="js-criteria-delete mb-6 btn-floating btn-small waves-effect waves-light red gradient-shadow mr-3 tooltipped '+(row.enable_delete ? '' : '')+'" data-position="left" data-tooltip="Delete" data-status="active"><i class="material-icons">delete</i></button>';
                        }
                        return dis;
                    },
                },
            ],
            pageLength: 50,
            drawCallback: function( settings ) {
                $('.tooltipped').tooltip();
            },
            "oLanguage": {
                "sSearch": ""
            }
        });
    }

    $(document).on('click', '.SettingEditCriteria', function () {
        let data = $(this).data('id');
        window.location.replace('/settings/config/criteria/' + data + '/edit');
    });

    $(document).on('click', '.SettingEditCriteriaStatus', function () {
        let data = $(this).data('id');
        let status = $(this).data('status');
        if(status == 'active'){
            statusText = 'enable';
        }
        else{
            statusText = status;
        }
        // console.log(1,status);
        Swal.fire({
            // title: 'Proceed With Caution!',
            html: "Are you sure to "+statusText+" this criteria?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#9e9e9e',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.value) {
                let loading = Swal.fire({
                    html: 'Please wait...',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading()
                    }
                });
                $.ajax({
                    type: 'POST',
                    url: '/settings/config/criteria/update_status',
                    data: {
                        _token: $('meta[name="csrf-token"]').attr('content'),
                        id      : data,
                        status : status,
                    },
                    success: function (results) {
                        if (results) {
                            CriteriaTable.ajax.reload();
                            M.toast({html: 'Criteria status has been successfully updated.', classes: 'rounded green'});
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Something went wrong!',
                                allowEscapeKey: false,
                                allowOutsideClick: false,
                            });
                        }
                        loading.close();
                    },
                    error: function (xhr, status, error) {
                        loading.close();
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something went wrong!',
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                        });
                    },
                });
            }
        });



    });

    if($(".only-one").length > 0){
        $(".only-one").on("click", function(){
            $(".only-one").not(this).prop("checked", false);
        });
    }
    if($(".day-only").length > 0){
        $(".day-only").is(':checked') ? $(".day-only-text").prop("disabled", false).prop('required', true).prop("readonly", false) : $(".day-only-text").prop("disabled", true).prop('required', false).prop("readonly", true);
        $(".day-only").on("click", function(){
            // console.log($(this).is("checked"));
            if($(this).is(':checked')){
                $(".day-only-text").prop("readonly", false).prop("disabled", false).prop('required', true);
            }
            else{
                $(".day-only-text").prop("readonly", true).prop("disabled", true).prop('required', false);
            }
        });
    }

    $(document).on("click", ".js-criteria-delete", function(e){
        let id = $(this).data("id");
        let action = $("#actionDeleteCriteria").val();
        Swal.fire({
            title: 'Proceed With Caution!',
            html: "Do you really want to delete these record?<br>This process cannot be undone.",
            type: 'warning',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#9e9e9e',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            // console.log(1,result, result.value);
            if (result.value) {
                // console.log(2,result, result.value);
                let loading = Swal.fire({
                    html: 'Please wait...',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading()
                    }
                });
                $.ajax({
                    type: 'POST',
                    url: action,
                    data: {
                        _token: $('meta[name="csrf-token"]').attr('content'),
                        id      : id,
                    },
                    success: function (results) {
                        // console.log(3,result, result);
                        if (results) {
                            CriteriaTable.ajax.reload();
                            M.toast({html: 'Criteria status has been successfully updated.', classes: 'rounded green'});
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Something went wrong!',
                                allowEscapeKey: false,
                                allowOutsideClick: false,
                            });
                        }
                        loading.close();
                    },
                    error: function (xhr, status, error) {
                        loading.close();
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something went wrong!',
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                        });
                    },
                });
            }
        });
    });

});
