$(document).ready(function () {

    if ($('#SystemLogTable').length > 0) {
        var SystemLogTable = $('#SystemLogTable').DataTable({
            responsive: false,
            processing: true,
            serverSide: true,
            ajax: {
                "url": "/settings/system_log/ajax",
                "type": "POST",
                "data": function (d) {
                    d._token = $('meta[name="csrf-token"]').attr('content');
                    d.from   = $('#filter-from').val();
                    d.to     = $('#filter-to').val();
                },
            },
            columns: [{
                    data: 'DT_RowIndex',
                    name: 'DT_RowIndex',
                    orderable: false,
                    searchable: false,
                    className: "text-center",
                },
                {
                    data: 'created_at',
                    name: 'created_at',
                    render: function (data) {
                        return moment(data).format('DD/MM/YYYY, h:mm:ss A');
                    }
                },
                {
                    data: 'name',
                    name: 'name',
                    render: function (data) {
                        return data.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
                    }
                },
                {
                    data: 'email',
                    name: 'email',
                },
                {
                    data: 'agency_name',
                    name: 'agency_name',
                    render: function (data, type, row) {
                        if(data != null) {
                            return data.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) + '<br><small>' + row.ministry_name.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) + '</small>';
                        } else {
                            return '';
                        }
                    }
                },
                {
                    data: 'activity',
                    name: 'activity',
                    className: "text-center",
                },
            ],
            pageLength: 50
        });
    }

    $(document).on('click', '.filter-system-log-btn', function () {
        // SystemLogTable.column(2).search($('#filter-name').val())
        //                 .column(3).search($('#filter-email').val())
        //                 .draw();
        AppendSystemLog();
        let store = {
            'name'      : $('#filter-name').val(),
            'email'     : $('#filter-email').val(),
            'from'      : $('#filter-from').val(),
            'to'        : $('#filter-to').val(),
        };

        Cookies.set('SystemLogFilter', JSON.stringify(store), { sameSite: 'None' });
    });

    if ($('.filter-system-log-btn').length > 0) {
        let data = Cookies.get('SystemLogFilter');
        if (data) {
            data = JSON.parse(data);
            $('#filter-name').val(data.name);
            $('#filter-email').val(data.email);
            $('#filter-from').val(data.from);
            $('#filter-to').val(data.to);

            $('.filter-system-log-btn').click();
        }
    }

    $(document).on('click', '.filter-system-log-reset-btn', function () {
        $('#filter-name').val('');
        $('#filter-email').val('');
        $('#filter-from').val(moment().format("YYYY-01-01"));
        $('#filter-to').val(moment().format("YYYY-MM-DD"));
        // SystemLogTable.column(2).search($('#filter-name').val())
        //                 .column(3).search($('#filter-email').val())
        //                 .draw();

        AppendSystemLog();

        Cookies.remove('SystemLogFilter');
    });

    if ($('#SystemLogTerminal').length > 0) {
        AppendSystemLog();
    }

    function AppendSystemLog(){
        $.ajax({
            type: 'POST',
            url: '/settings/system_log/ajax',
            data: {
                _token: $('meta[name="csrf-token"]').attr('content'),
                name  : $('#filter-name').val(),
                email : $('#filter-email').val(),
                from  : $('#filter-from').val(),
                to    : $('#filter-to').val(),
            },
            success: function (results) {
                if (results) {
                    $('#SystemLogTerminal').html('');

                    results.forEach(element => {
                        let html = '<div class="row"><div class="col s1" style="max-width: 3px;">$</div><div class="col s11">['+ moment(element.created_at).format('YYYY/MM/DD HH:MM::ss') +']  ' + element.name + '('+element.email+') - ' + element.activity + element.activity + '<br></div></div>';
                        $('#SystemLogTerminal').append(html);
                    });

                    if (results.length == 0) {
                        $('#SystemLogTerminal').html('$');
                    }
                    const element = document.getElementById('SystemLogTerminal');
                    element.scrollTop = element.scrollHeight;
                }
            },
        });
    }

});
