const { random } = require("lodash");

$(document).ready(function () {

    var is_desktop = false;
    if ($('#is_desktop').length > 0) {
        is_desktop = $('#is_desktop').data('value') ? false : true;
    }

    if ($('.collapsible').length > 0) {
        $('.collapsible').collapsible({
            accordion: false
        });
    }

    if ($('#AssessmentProfileWebsiteTable').length > 0) {
        // let is_desktop = $("#is_desktop").data("value");
        // console.log(is_desktop);
        var isRunning = false;
        var length = Cookies.get('AssessmentProfileWebsiteTable_length') ? Cookies.get('AssessmentProfileWebsiteTable_length') : 25;
        // var length = Cookies.get('AssessmentProfileWebsiteTable_paging') ? Cookies.get('AssessmentProfileWebsiteTable_paging') : 0;
        var ProfileWebsiteTable = $('#AssessmentProfileWebsiteTable').DataTable({
            responsive: is_desktop,
            processing: true,
            serverSide: true,
            // stateSave: true,
            // stateSaveCallback: function(settings,data) {
            //     sessionStorage.setItem( 'DataTables_' + settings.sInstance, JSON.stringify(data) )
            //   },
            // stateLoadCallback: function(settings) {
            //   return JSON.parse( sessionStorage.getItem( 'DataTables_' + settings.sInstance ) )
            //   },
            // "deferLoading": 3,
            ajax: {
                "url": "/assessments/website/ajax",
                "type": "POST",
                "data": function (d) {
                    d._token = $('meta[name="csrf-token"]').attr('content');
                    d.filter_agency = $("#filter-agency").val();
                    d.filter_url = $("#filter-url").val();
                    d.filter_profile_mode = $("#filter-profile-mode").val();
                    d.filter_assessment_status = $("#filter-assessment-status").val();
                    d.filter_type = $("#filter-type").val();
                    d.filter_daterange = $("#filter-daterange").val();
                },
            },
            columns: [{
                    data: 'DT_RowIndex',
                    name: 'DT_RowIndex',
                    orderable: false,
                    searchable: false,
                    className: "text-center",
                },
                {
                    data: 'agency_name',
                    name: 'agency_name',
                    render: function (data, type, row) {
                        if (data != null) {
                            var icon = [];
                            var is_reachable = '';
                            if(row.is_reachable == 0){
                                is_reachable = '&nbsp;<div class="tooltip"><i class="fa fa-exclamation-circle red-text"></i><span class="tooltiptext">'+row.is_reachable_txt+'</span></div>';
                            }
                            icon['Production'] = '<div class="tooltip"><i class="fa fa-globe light-blue-text"></i><span class="tooltiptext">Profile Mode: Production</span></div> ';
                            icon['Staging'] = '<div class="tooltip"><i class="fa fa-bug red-text"></i><span class="tooltiptext">Profile Mode: Staging</span></div> ';
                            icon['Development'] = '<div class="tooltip"><i class="fa fa-flask red-text"></i><span class="tooltiptext">Profile Mode: Development</span></div> ';
                            // return icon + row.name_en + '<br>'+data.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) + '<br>' + row.official_website_url;
                            return '<div style="max-width:80%;">'+data+'<br>'+ icon[row.env] + row.name_en + '<br><a href="' + row.official_website_url + '" target="_blank">' + row.official_website_url + '</a>'+is_reachable+'</div>';
                        } else {
                            return '';
                        }
                    }
                },
                {
                    data: 'assessment_current_score',
                    name: 'assessment_current_score',
                    render: function (data, type, row) {
                        // console.log(row);
                        if (data != null) {
                            var icon = '';
                            var loading = '';
                            var dt = '';
                            if (parseFloat(data) > parseFloat(row.assessment_previous_score)) {
                                icon = '<i class="fa fa-arrow-up green-text" aria-hidden="true"></i>';
                            } else if (parseFloat(data) == parseFloat(row.assessment_previous_score)) {
                                icon = ''; //<i class="fa fa-arrow-down" aria-hidden="true"></i>
                            } else {
                                icon = '<i class="fa fa-arrow-down red-text" aria-hidden="true"></i>';
                            }
                            // if(row.assessment_status_queue < 3){
                            //     if(row.assessment_status_queue === 2){
                            //         loading = ' <span class="new badge red" data-badge-caption="Running"><i class="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i></span> ';
                            //     }
                            //     else{
                            //         loading = ' <span class="new badge blue" data-badge-caption="Queue"><i class="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i></span> ';
                            //     }
                            // }
                            if (row.assessment_status_queue == 1) {
                                loading = ' <span class="new badge blue" data-badge-caption="Queue"><i class="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i></span> ';
                            } else if (row.assessment_status_queue == 2) {
                                loading = ' <span class="new badge amber" data-badge-caption="Running" style="color: rgba(0, 0,0,1);"><i class="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i></span> ';
                            } else if (row.assessment_status_queue == 3) {
                                loading = ' <span class="new badge green" data-badge-caption="Completed"><i class="fa fa-check" aria-hidden="true"></i></span> ';
                                // if(row.has_assessment === null || row.assessment_status === null){
                                //     loading = ' <span class="new badge grey" data-badge-caption="No Data"><i class="fa fa-times" aria-hidden="true"></i></span> ';
                                //     return '<span style="font-weight:bold;font-size:16px;">' + data + '</span> ' + icon + '<br>' + loading;
                                // }
                            } else if (row.assessment_status_queue == 4) {
                                loading = ' <span class="new badge red" data-badge-caption="Failed"><i class="fa fa-times" aria-hidden="true"></i></span> ';
                            } else if (row.assessment_status_queue == 5) {

                                loading = ' <span class="new badge red" data-badge-caption="Stopped"><i class="fa fa-times" aria-hidden="true"></i></span> ';

                            }
                            // if (row.has_assessment === null || row.assessment_status === null) {
                            //     loading = ' <span class="new badge grey" data-badge-caption="No Data"><i class="fa fa-times" aria-hidden="true"></i></span> ';
                            //     return '<span style="font-weight:bold;font-size:16px;">' + data + '</span> ' + icon + '<br>' + loading;
                            // }
                            // else{
                                // console.log('test',data);
                                if(data <= 0){
                                    if(row.assessment_status_queue > 3){
                                        data = '-';
                                    }
                                    if(data == -1){
                                        data = '-';
                                    }
                                    dt = row.assessment_current_date;
                                    icon = '';
                                }
                                else{
                                    dt = row.assessment_current_date;
                                }
                                return '<span style="font-weight:bold;font-size:16px;">' + data + '</span> ' + icon + '<br>' + dt + '<br>' + loading;
                            // }
                        } else {
                            return '';
                        }
                    }
                },
                {
                    data: 'assessment_previous_score',
                    name: 'assessment_previous_score',
                    render: function (data, type, row) {
                        if (data != null) {
                            if(data <= 0){
                                if(row.assessment_status_queue > 3){
                                    data = '-';
                                }
                                if(data == -1){
                                    data = '-';
                                }
                                dt = row.assessment_previous_date;
                            }
                            else{
                                dt = row.assessment_previous_date;
                            }
                            return '<div style="vertical-align:top;"><span style="font-weight:bold;font-size:16px;">' + data + '</span><br>'+dt+'</div>';
                        } else {
                            return '';
                        }
                    }
                },
                {
                    data: 'official_website_url',
                    name: 'official_website_url',
                },
                {
                    data: 'env',
                    name: 'env',
                },
                {
                    data: 'name_en',
                    name: 'name_en',
                },
                {
                    data: 'website_id',
                    name: 'website_id',
                    orderable: false,
                    className: "text-center",
                    render: function (data, type, row) {
                        // console.log(row,typeof row.has_assessment,row.has_assessment);
                        if (row.website_id != null) {
                            var btn = '';
                            var disabled = true;
                            if(row.is_reachable == 1){
                                disabled = false;
                            }

                            if (row.assessment_status_queue < 3 && row.assessment_type == 1){
                                disabled = true;
                            }

                            if(!disabled){
                                if (row.assessment_status_queue < 3) {
                                    btn += '<a data-id="' + data + '"  data-action="Stop" data-jobid="' + row.assessment_job_id + '" class="mb-6 btn-floating btn-small waves-effect waves-light red gradient-shadow mr-3 AssessmentRunOnDemandAllCriteria tooltipped" data-position="left" data-tooltip="Stop Now" title="Stop Now"><i class="material-icons">stop</i></a>';
                                }
                                // else if (row.assessment_status_queue < 3) {
                                //     btn += '<button disabled data-id="' + data + '"  data-action="Stop" data-jobid="' + row.assessment_job_id + '" class="mb-6 btn-floating btn-small waves-effect waves-light red gradient-shadow mr-3 AssessmentRunOnDemandAllCriteria" title="Stop Now"><i class="material-icons">stop</i></button>';
                                // }
                                else {
                                    btn += '<a data-id="' + data + '"  data-action="Start" data-jobid="' + row.assessment_job_id + '" class="mb-6 btn-floating btn-small waves-effect waves-light indigo gradient-shadow mr-3 AssessmentRunOnDemandAllCriteria  tooltipped" data-position="left" data-tooltip="Run Now" title="Run Now"><i class="material-icons">play_arrow</i></a>';
                                }
                            }
                            else{
                                if(row.env == 'Production'){
                                    btn += '<a disabled class="mb-6 btn-floating btn-small waves-effect waves-light purple gradient-shadow mr-3  tooltipped" data-position="left" data-tooltip="Run Now" title="Run Now"><i class="material-icons">play_arrow</i></a>';
                                }
                                else{
                                    btn += '<a data-id="' + data + '"  data-action="Start" data-jobid="' + row.assessment_job_id + '" class="mb-6 btn-floating btn-small waves-effect waves-light indigo gradient-shadow mr-3 AssessmentRunOnDemandAllCriteria  tooltipped" data-position="left" data-tooltip="Run Now" title="Run Now"><i class="material-icons">play_arrow</i></a>';
                                }
                            }
                            if (row.has_assessment === null || row.assessment_status === null) {
                                btn += '<a disabled class="mb-6 btn-floating btn-small waves-effect waves-light purple gradient-shadow mr-3 AssessmentView tooltipped" data-position="left" data-tooltip="View Assessment Details" title="View Assessment Details"><i class="material-icons">view_list</i></a>';
                            } else {
                                if(disabled){
                                    btn += '<a disabled class="mb-6 btn-floating btn-small waves-effect waves-light purple gradient-shadow mr-3 AssessmentView  tooltipped" data-position="left" data-tooltip="View Assessment Details" title="View Assessment Details"><i class="material-icons">view_list</i></a>';
                                }
                                else{
                                    if(row.assessment_previous_score == 0 && row.assessment_status_queue > 3){
                                        btn += '<a disabled class="mb-6 btn-floating btn-small waves-effect waves-light purple gradient-shadow mr-3 AssessmentView tooltipped" data-position="left" data-tooltip="View Assessment Details" title="View Assessment Details"><i class="material-icons">view_list</i></a>';
                                    }
                                    else{
                                        btn += '<a data-id="' + data + '" class="mb-6 btn-floating btn-small waves-effect waves-light purple gradient-shadow mr-3 AssessmentView  tooltipped" data-position="left" data-tooltip="View Assessment Details" title="View Assessment Details"><i class="material-icons">view_list</i></a>';
                                    }
                                }
                            }
                            return type === 'display' ? btn : data;
                        } else {
                            return '';
                        }
                    },
                },
                {
                    data: 'assessment_status_queue',
                    name: 'assessment_status_queue',
                },
                // {
                //     data: 'is_ministry',
                //     name: 'is_ministry',
                // },
            ],
            "columnDefs": [{
                    "targets": [4],
                    "visible": false,
                    "searchable": true
                },
                {
                    "targets": [5],
                    "visible": false,
                    "searchable": true
                },
                {
                    "targets": [6],
                    "visible": false,
                    "searchable": true
                },
                {
                    "targets": [8],
                    "visible": false,
                    "searchable": true
                }


            ],
            pageLength: length,
            "drawCallback": function (settings) {
                //
                var api = this.api();
                var rows = api.rows( {page:'current'} ).data()
                var isInterval = false;
                for(var i = 0; i<rows.length;i++){
                    if(rows[i].assessment_status_queue == 1 || rows[i].assessment_status_queue == 2){
                        isInterval = true;
                    }
                }
                const myTimeout = setTimeout(function(){
                    if(isInterval){
                        ProfileWebsiteTable.ajax.reload(null, false);
                    }
                }, 15000);
                $('.tooltipped').tooltip();
                $('.tooltipped').on('click', function () {
                    $(this).tooltip('close');
                    $('.material-tooltip').css("visibility", "hidden");
                });
                $('.tooltipped').mousedown(function(){
                    $('.material-tooltip').css("visibility", "hidden");
                });

                // leaving after click in case you open link in new tab
                $('.tooltipped').mouseleave(function(){
                    $('.material-tooltip').css("visibility", "hidden");
                });
            }
        });
    }
    $(document).on('change', '[name="AssessmentProfileWebsiteTable_length"]', function () {
        let val = $(this).val();
        Cookies.set('AssessmentProfileWebsiteTable_length', val, { sameSite: 'None' });
    });

    if ($('#filter-url').length > 0) {
        $('#filter-url').keydown(function (event) {
            var keyCode = (event.keyCode ? event.keyCode : event.which);
            if (keyCode == 13) {
                $('.filter-assessment-profile-website-btn').click();
            }
        });
    }

    $(document).on('click', '.filter-assessment-profile-website-btn', function () {
        // ProfileWebsiteTable.column(1).search($('#filter-agency').val())
        //                     .column(6).search($('#filter-url').val())
        //                     .column(5).search($("#filter-profile-mode").val())
        //                     .column(8).search($("#filter-assessment-status").val())
        //                     .column(9).search($("#filter-type").val())
        //                     .draw();
        ProfileWebsiteTable.column(8).search($("#filter-assessment-status").val()).draw();
        let store = {
            'agency'             : $('#filter-agency').val(),
            'url'                : $('#filter-url').val(),
            'profile_mode'       : $('#filter-profile-mode').val(),
            'assessment_status'  : $('#filter-assessment-status').val(),
            'type'               : $('#filter-type').val(),
        };

        Cookies.set('AssessmentProfileWebsiteFilter', JSON.stringify(store), { sameSite: 'None' });
    });

    if ($('.filter-assessment-profile-website-btn').length > 0) {
        let data = Cookies.get('AssessmentProfileWebsiteFilter');
        if (data) {
            data = JSON.parse(data);
            $('#filter-agency').val(data.agency);
            $('#filter-url').val(data.url);
            $('#filter-profile-mode').val(data.profile_mode);
            $('#filter-assessment-status').val(data.assessment_status);
            $('#filter-type').val(data.type);

            $('#filter-agency').select2();
            $('#filter-profile-mode').select2();
            $('#filter-assessment-status').select2();
            $('#filter-type').select2();

            $('.filter-assessment-profile-website-btn').click();
        }
    }

    $(document).on('click', '.filter-assessment-profile-website-reset-btn', function () {
        $('#filter-agency').val('');
        $('#filter-url').val('');
        $('#filter-profile-mode').val('');
        $('#filter-assessment-status').val('');
        $('#filter-type').val('');
        let d = $("#filter-daterange").data("value");
        let dt = d.split("-");
        // $("#filter-daterange").val(d);
        // $('#filter-daterange').data('daterangepicker').setStartDate(dt[0]);
        // $('#filter-daterange').data('daterangepicker').setEndDate(dt[1]);
        $("#filter-daterange").val('');
        // $('#filter-daterange').data('daterangepicker').setStartDate('');
        // $('#filter-daterange').data('daterangepicker').setEndDate('');
        $('#filter-agency').select2({
            dropdownAutoWidth: true,
            width: '100%',
            tags: false,
            templateResult: AgencyFormat2,
            templateSelection: AgencyFormat1,
            escapeMarkup: function (es) { return es; }
        });
        $('#filter-type').select2();
        $('#filter-profile-mode').select2();
        $('#filter-assessment-status').select2();
        ProfileWebsiteTable.column(1).search($('#filter-agency').val())
            .column(6).search($('#filter-url').val())
            .column(5).search($("#filter-profile-mode").val())
            .column(8).search($("#filter-assessment-status").val())
            .column(9).search($("#filter-type").val())
            .draw();
        Cookies.remove('AssessmentProfileWebsiteFilter');
    });

    function AgencyFormat1(icon) {
        var originalOption = icon.element;
        if (!icon.id) { return icon.text; }
        var $icon = icon.text ;
        return $icon;
    }

    function AgencyFormat2(icon) {
        var originalOption = icon.element;
        if (!icon.id) { return icon.text; }
        if ($(icon.element).data('ministry')) {
            var $icon = icon.text + "<br><small>"+$(icon.element).data('ministry')+"</small>" ;
        } else {
            var $icon = icon.text ;
        }
        return $icon;
    }

    $(document).on('click', '.AssessmentRunOnDemandAllCriteria', function () {
        let website_id = $(this).data('id');
        let action = $(this).data('action');
        let jobid = $(this).data('jobid');
        let criteria_id = $(this).data('criteria_id');
        // let sel = $(this);
        $.ajax({
            type: 'POST',
            url: '/assessments/website/on-demand-run-now',
            data: {
                _token: $('meta[name="csrf-token"]').attr('content'),
                website_id: website_id,
                action: action,
                jobid: jobid,
                criteria_id: criteria_id
            },
            beforeSend: function(){
                $("#assessment-btn-all-criteria").empty().html('<button data-id="" class="grey darken-2 waves-effect waves-light btn-small mr-1" title="Queue" disabled><i class="fas fa-spinner fa-pulse fa-fw"></i></button>');
                $(".AssessmentRunOnDemandCriteria").prop('disabled', true);
                loading = ' <span class="new badge blue left" data-badge-caption="Queue" style="margin: 0px;"><i class="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i></span> ';
                $("#all-assessment-status-badge").empty().html(loading);
                $(".individual-status").remove();
            },
            success: function (result, status) {
                if (status == 'success') {
                    // sel.attr('data-jobid', result.job_id);
                    if ($('#AssessmentProfileWebsiteTable').length > 0) {
                        ProfileWebsiteTable.ajax.reload(null, false);
                    } else {
                        // console.log('details');
                        // window.location.reload();

                        const myInterval = setInterval(function(){
                            $.ajax({
                                type: 'POST',
                                url: '/assessments/website/status',
                                data: {
                                    _token: $('meta[name="csrf-token"]').attr('content'),
                                    website_id: website_id,
                                    assessment_type: 2
                                },
                                success: function (result, status) {
                                    var loading = '';
                                    if(result.status){
                                        if (result.status == 1) {
                                            loading = ' <span class="new badge blue left" data-badge-caption="Queue" style="margin: 0px;"><i class="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i></span> ';
                                            $("#assessment-btn-all-criteria").empty().html('<a data-id="'+website_id+'" data-action="Stop" data-jobid="'+result.job_id+'" class="red darken-2 waves-effect waves-light btn-small AssessmentRunOnDemandAllCriteria mr-1" title="Stop Now"><i class="fas fa-stop"></i></a>');
                                            $(".AssessmentRunOnDemandCriteria").prop('disabled', true);
                                        } else if (result.status == 2) {
                                            loading = ' <span class="new badge amber left" data-badge-caption="Running" style="margin: 0px;color: rgba(0, 0,0,1);"><i class="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i></span> ';
                                            $("#assessment-btn-all-criteria").empty().html('<button data-id="'+website_id+'" data-action="Stop" data-jobid="'+result.job_id+'" class="red darken-2 waves-effect waves-light btn-small AssessmentRunOnDemandAllCriteria mr-1" title="Stop Now"><i class="fas fa-stop"></i></button>');
                                            // $("#assessment-btn-all-criteria").empty().html('<a data-id="" class="grey darken-2 waves-effect waves-light btn-small mr-1" title="Queue"><i class="fas fa-spinner fa-pulse fa-fw"></i></a>');
                                            $(".AssessmentRunOnDemandCriteria").prop('disabled', true);
                                        } else if (result.status == 3) {
                                            window.location.reload();
                                            loading = ' <span class="new badge green left" data-badge-caption="Completed" style="margin: 0px;"><i class="fa fa-check" aria-hidden="true"></i></span> ';
                                            $("#assessment-btn-all-criteria").empty().html('<a data-id="'+website_id+'" data-action="Start" data-jobid="'+result.job_id+'" class="indigo waves-effect waves-light btn-small AssessmentRunOnDemandAllCriteria mr-1" title="Run Now"><i class="fas fa-play"></i></a>');
                                            clearInterval(myInterval);
                                            $(".AssessmentRunOnDemandCriteria").prop('disabled', false);

                                        } else if (result.status == 4) {
                                            window.location.reload();
                                            loading = ' <span class="new badge red left" data-badge-caption="Failed" style="margin: 0px;"><i class="fa fa-times" aria-hidden="true"></i></span> ';
                                            $("#assessment-btn-all-criteria").empty().html('<a data-id="'+website_id+'" data-action="Start" data-jobid="'+result.job_id+'" class="indigo waves-effect waves-light btn-small AssessmentRunOnDemandAllCriteria mr-1" title="Run Now"><i class="fas fa-play"></i></a>');
                                            clearInterval(myInterval);
                                            $(".AssessmentRunOnDemandCriteria").prop('disabled', false);

                                        } else if (result.status == 5) {
                                            window.location.reload();
                                            loading = ' <span class="new badge red left" data-badge-caption="Stopped" style="margin: 0px;"><i class="fa fa-stop" aria-hidden="true"></i></span> ';
                                            $("#assessment-btn-all-criteria").empty().html('<a data-id="'+website_id+'" data-action="Start" data-jobid="'+result.job_id+'" class="indigo waves-effect waves-light btn-small AssessmentRunOnDemandAllCriteria mr-1" title="Run Now"><i class="fas fa-play"></i></a>');
                                            clearInterval(myInterval);
                                            $(".AssessmentRunOnDemandCriteria").prop('disabled', false);

                                        }
                                        $("#all-assessment-status-badge").empty().html(loading);
                                    }
                                }
                            });
                        }, 3000);
                        // clearInterval(myInterval);
                    }
                }
            },
        });
    });

    $(document).on('click', '.AssessmentRunOnDemandCriteria', function () {
        let website_id = $(this).data('id');
        let action = $(this).data('action');
        let jobid = $(this).data('jobid');
        let criteria_id = $(this).data('criteria_id');
        let btn = $(this);
        let parents = $(this).parent();
        let parentLeft = $(this).parent().prev();
        let parentLeft0 = $(this).parent().prev().prev();
        // console.log(parentLeft0.find('.material-icons'));
        // return;
        // let sel = $(this);
        $.ajax({
            type: 'POST',
            url: '/assessments/website/on-demand-run-now',
            data: {
                _token: $('meta[name="csrf-token"]').attr('content'),
                website_id: website_id,
                action: action,
                jobid: jobid,
                criteria_id: criteria_id
            },
            beforeSend: function(){
                let loading = '<br><span style="float:initial;margin-left:0;" class="new badge blue individual-status" data-badge-caption="Queue"><i class="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i></span>';
                $(btn).parent().empty().html('<button data-id="" class="grey darken-2 waves-effect waves-light btn-small mr-1" title="Queue" disabled><i class="fas fa-spinner fa-pulse fa-fw"></i></button>'+loading);
                $(".AssessmentRunOnDemandAllCriteria").prop('disabled', false);
            },
            success: function (result, status) {
                // console.log($(btn).parent().empty());
                if (status == 'success') {
                    let loading = '<br><span style="float:initial;margin-left:0;" class="new badge amber individual-status" data-badge-caption="Running" style="color: rgba(0, 0,0,1);"><i class="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i></span>';
                    parents.empty().html('<button data-id="" class="grey darken-2 waves-effect waves-light btn-small mr-1" title="Queue" disabled><i class="fas fa-spinner fa-pulse fa-fw"></i></button>' + loading);


                    //AssessmentRunOnDemandCriteria
                    // $($(".AssessmentRunOnDemandCriteria")[0]).parent('div').prev().html().append('sss')
                    // loading = ' <span class="new badge blue left" data-badge-caption="Queue" style="margin: 0px;"><i class="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i></span> ';
                    // $(btn).parent().empty().html('<button data-id="'+website_id+'" data-action="Stop" data-jobid="'+result.job_id+'" class="red darken-2 waves-effect waves-light btn-small AssessmentRunOnDemandAllCriteria mr-1" title="Stop Now"><i class="fas fa-stop"></i></button>');
                    // $("#all-assessment-status-badge").empty().html(loading);

                    //window.location.reload();
                    const myInterval = setInterval(function(){
                        $.ajax({
                            type: 'POST',
                            url: '/assessments/website/status-criteria',
                            data: {
                                _token: $('meta[name="csrf-token"]').attr('content'),
                                website_id: website_id,
                                assessment_type: 2,
                                criteria_id: criteria_id,
                                action: action
                            },
                            success: function (result, status) {
                                var loading = '';
                                // console.log(btn, $(btn).parent());
                                if(result){
                                    // console.log(result);
                                    if (result.status == 0) {
                                        // window.location.reload();
                                        // $("#assessment-btn-criteria").empty().html('<button data-id="'+website_id+'" data-action="Start" data-criteria_id="'+criteria_id+'" class="indigo waves-effect waves-light btn-small AssessmentRunOnDemandCriteria mr-1" title="Run Now"><i class="fas fa-play"></i></button>');
                                        // $(btn).parent().empty().html('<button data-id="'+website_id+'" data-action="Stop" data-jobid="'+result.job_id+'" class="red darken-2 waves-effect waves-light btn-small AssessmentRunOnDemandAllCriteria mr-1" title="Stop Now"><i class="fas fa-stop"></i></button>');
                                        // console.log('action:' + action);
                                        if(action == 'Start'){
                                            loading = '<br><span style="float:initial;margin-left:0;" class="new badge green individual-status" data-badge-caption="Completed"><i class="fa fa-check" aria-hidden="true"></i></span>';
                                        }
                                        else{
                                            loading = '<br><span style="float:initial;margin-left:0;" class="new badge red individual-status" data-badge-caption="Stopped"><i class="fa fa-stop" aria-hidden="true"></i></span>';
                                        }
                                        parents.empty().html('<button data-id="'+website_id+'" data-action="Start" data-criteria_id="'+criteria_id+'" class="red waves-effect waves-light btn-small AssessmentRunOnDemandCriteria mr-1" title="Run Now"><i class="fas fa-play"></i></button>'+loading);
                                        // $(".AssessmentRunOnDemandAllCriteria").prop('disabled', false);
                                        let disabledBtn = 0;
                                        $(".individual-status").each(function(){
                                            if($.inArray($(this).data('badge-caption'), ['Running', 'Queue']) > -1){
                                            // if($(this).attr('data-badge-caption') != 'Completed'){
                                                disabledBtn++;
                                            }
                                        });
                                        if(disabledBtn == 0){
                                            $(".AssessmentRunOnDemandAllCriteria").prop('disabled', false);
                                        }
                                        parentLeft.html(result.html);
                                        $(".collapsible").collapsible({accordion:!0})
                                        clearInterval(myInterval);
                                    } else if (result.status == 1) {
                                        // window.location.reload();
                                        if(action == 'Start'){
                                            loading = '<br><span style="float:initial;margin-left:0;" class="new badge green individual-status" data-badge-caption="Completed"><i class="fa fa-check" aria-hidden="true"></i></span>';
                                        }
                                        else{
                                            loading = '<br><span style="float:initial;margin-left:0;" class="new badge red individual-status" data-badge-caption="Stopped"><i class="fa fa-stop" aria-hidden="true"></i></span>';
                                        }
                                        parents.empty().html('<button data-id="'+website_id+'" data-action="Start" data-criteria_id="'+criteria_id+'" class="indigo waves-effect waves-light btn-small AssessmentRunOnDemandCriteria mr-1" title="Run Now"><i class="fas fa-play"></i></button>'+loading);
                                        // $(".AssessmentRunOnDemandAllCriteria").prop('disabled', false);
                                        let disabledBtn = 0;
                                        $(".individual-status").each(function(){
                                            // if($(this).attr('data-badge-caption') != 'Completed'){
                                            if($.inArray($(this).data('badge-caption'), ['Running', 'Queue']) > -1){
                                                disabledBtn++;
                                            }
                                        });
                                        if(disabledBtn == 0){
                                            $(".AssessmentRunOnDemandAllCriteria").prop('disabled', false);
                                        }
                                        parentLeft.html(result.html);
                                        $(".collapsible").collapsible({accordion:!0})
                                        clearInterval(myInterval);
                                    } else if (result.status == -1) {
                                        if(action == 'Start'){
                                            // loading = '<br><span style="float:initial;margin-left:0;" class="new badge green individual-status" data-badge-caption="Completed"><i class="fa fa-check" aria-hidden="true"></i></span>';
                                            loading = '<br><span style="float:initial;margin-left:0;" class="new badge amber individual-status" data-badge-caption="Running"><i class="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i></span>';
                                        }
                                        else{
                                            loading = '<br><span style="float:initial;margin-left:0;" class="new badge red individual-status" data-badge-caption="Stopped"><i class="fa fa-stop" aria-hidden="true"></i></span>';
                                        }
                                        parents.empty().html('<button data-id="'+website_id+'" data-action="Stop" data-criteria_id="'+criteria_id+'" class="red darken-2 waves-effect waves-light btn-small AssessmentRunOnDemandCriteria mr-1" title="Run Now"><i class="fas fa-stop"></i></button>'+loading);
                                        $(".AssessmentRunOnDemandAllCriteria").prop('disabled', true);

                                    }
                                    // $("#all-assessment-status-badge").empty().html(loading);
                                    if(result.dataset == true){
                                        parentLeft0.find('i').removeClass('blue-text').addClass('red-text').html('error');
                                    }
                                    else{
                                        parentLeft0.find('i').removeClass('red-text').addClass('blue-text').html('info');
                                    }
                                    //update final score
                                    console.log(result.final_score);
                                    var t = 0;
                                    if(result.final_score > 0){
                                        t = result.final_score;
                                    }
                                    $("#Score-On-Demand").gaugeMeter({percent:result.final_score,text:t});
                                    // console.log('update score: '+result.final_score);
                                }
                            }
                        });
                    }, 2000);
                }
            },
        });
    });

    if($("#check-status-queue").length > 0){
        let website = $("#check-status-queue").data('website');
        let queue = $("#check-status-queue").val();
        if(queue < 3){
            // console.log(website, queue);
            const myInterval = setInterval(function(){
                $.ajax({
                    type: 'POST',
                    url: '/assessments/website/status',
                    data: {
                        _token: $('meta[name="csrf-token"]').attr('content'),
                        website_id: website,
                        assessment_type: 2
                    },
                    success: function (result, status) {
                        var loading = '';
                        if(result.status){
                            if (result.status == 1) {
                                loading = ' <span class="new badge blue left" data-badge-caption="Queue" style="margin: 0px;"><i class="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i></span> ';
                                $("#assessment-btn-all-criteria").empty().html('<button data-id="'+website+'" data-action="Stop" data-jobid="'+result.job_id+'" class="red darken-2 waves-effect waves-light btn-small AssessmentRunOnDemandAllCriteria mr-1" title="Stop Now"><i class="fas fa-stop"></i></button>');
                                $(".AssessmentRunOnDemandCriteria").prop('disabled', true);
                            } else if (result.status == 2) {
                                loading = ' <span class="new badge amber left" data-badge-caption="Running" style="margin: 0px;color: rgba(0, 0,0,1);"><i class="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i></span> ';
                                $("#assessment-btn-all-criteria").empty().html('<button data-id="'+website+'" data-action="Stop" data-jobid="'+result.job_id+'" class="red darken-2 waves-effect waves-light btn-small AssessmentRunOnDemandAllCriteria mr-1" title="Stop Now"><i class="fas fa-stop"></i></button>');
                                $(".AssessmentRunOnDemandCriteria").prop('disabled', true);
                            } else if (result.status == 3) {
                                clearInterval(myInterval);
                                window.location.reload();
                                // loading = ' <span class="new badge green left" data-badge-caption="Completed" style="margin: 0px;"><i class="fa fa-check" aria-hidden="true"></i></span> ';
                                // $("#assessment-btn-all-criteria").empty().html('<button data-id="'+website_id+'" data-action="Start" data-jobid="'+result.job_id+'" class="indigo waves-effect waves-light btn-small AssessmentRunOnDemandAllCriteria mr-1" title="Run Now"><i class="fas fa-play"></i></button>');

                                // $(".AssessmentRunOnDemandCriteria").prop('disabled', false);

                            } else if (result.status == 4) {
                                clearInterval(myInterval);
                                window.location.reload();
                                // loading = ' <span class="new badge red left" data-badge-caption="Failed" style="margin: 0px;"><i class="fa fa-times" aria-hidden="true"></i></span> ';
                                // $("#assessment-btn-all-criteria").empty().html('<button data-id="'+website_id+'" data-action="Start" data-jobid="'+result.job_id+'" class="indigo waves-effect waves-light btn-small AssessmentRunOnDemandAllCriteria mr-1" title="Run Now"><i class="fas fa-play"></i></button>');

                                // $(".AssessmentRunOnDemandCriteria").prop('disabled', false);

                            } else if (result.status == 5) {
                                clearInterval(myInterval);
                                window.location.reload();
                                // loading = ' <span class="new badge red left" data-badge-caption="Stopped" style="margin: 0px;"><i class="fa fa-stop" aria-hidden="true"></i></span> ';
                                // $("#assessment-btn-all-criteria").empty().html('<button data-id="'+website_id+'" data-action="Start" data-jobid="'+result.job_id+'" class="indigo waves-effect waves-light btn-small AssessmentRunOnDemandAllCriteria mr-1" title="Run Now"><i class="fas fa-play"></i></button>');

                                // $(".AssessmentRunOnDemandCriteria").prop('disabled', false);

                            }
                            $("#all-assessment-status-badge").empty().html(loading);
                        }
                    }
                });
            }, 3000);
        }
    }

    ///website/status-criteria

    $(document).on('click', '.AssessmentView', function () {
        let data = $(this).data('id');
        window.location.replace('/assessments/' + data + '/website');
    });



});
