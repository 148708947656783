const moment = require("moment");

$(document).ready(function () {

    var is_desktop = false;
    if ($('#is_desktop').length > 0) {
        is_desktop = $('#is_desktop').data('value') ? false : true;
    }

    if ($('#ProfileWebsiteTable').length > 0) {
        var length = Cookies.get('ProfileWebsiteTable_length') ? Cookies.get('ProfileWebsiteTable_length') : 25;

        if($("#WebApproval").length > 0) {
            var columnDef = [ 4, 6, 7, 8, 9 ];
        }
        else{
            var columnDef = [  3, 6, 7, 8, 9 ];
        }

        var ProfileWebsiteTable = $('#ProfileWebsiteTable').DataTable({
            responsive: is_desktop,
            processing: true,
            serverSide: true,
            ajax: {
                "url": "/profiles/website/ajax",
                "type": "POST",
                "data": function (d) {
                    d._token = $('meta[name="csrf-token"]').attr('content');
                    d.from   = $('#filter-from').val();
                    d.to     = $('#filter-to').val();

                    d.filterministry     = $('#filter-minitry').val();
                    d.filterurl     = $('#filter-url').val();
                    d.filtertype     = $('#filter-type').val();
                    d.filterenv_mode     = $('#filter-env_mode').val();
                    d.isapproval = $("#WebApproval").length;
                },
            },
            columns: [{
                    data: 'DT_RowIndex',
                    name: 'DT_RowIndex',
                    orderable: false,
                    searchable: false,
                    className: "text-center",
                },
                {
                    data: 'agency_name',
                    name: 'agency_name',
                    width: "20%",
                    render: function (data, type, row) {
                        if(data != null) {
                            return data.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
                        } else {
                            return '';
                        }
                    }
                },
                {
                    data: 'official_website_url',
                    name: 'official_website_url',
                    width: "10%",
                    render: function (data, type, row) {
                        if(data != null) {
                            var icon = [];
                            icon['Production'] = '<div class="tooltip"><i class="fa fa-globe light-blue-text"></i><span class="tooltiptext">Profile Mode: Production</span></div> ';
                            icon['Staging'] = '<div class="tooltip"><i class="fa fa-bug red-text"></i><span class="tooltiptext">Profile Mode: Staging</span></div> ';
                            icon['Development'] = '<div class="tooltip"><i class="fa fa-flask red-text"></i><span class="tooltiptext">Profile Mode: Development</span></div> ';
                            // return icon + row.name_en + '<br>'+data.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) + '<br>' + row.official_website_url;
                            return icon[row.env] + row.name_en + '<br> <a href="' + row.official_website_url + '" target="_blank">' + row.official_website_url + '</a>';
                        } else {
                            return '';
                        }
                    }
                },
                {
                    data: 'delete_dt',
                    name: 'delete_dt',
                    className: "text-left",
                    render: function (data) {
                        // var d = data.split("T");
                        // var t = d[1].split(".");
                        // return '<div style="text-align:left;">'+d[0]+' '+t[0]+'</div>';
                        return '<div style="text-align:left;">'+data+'</div>';
                    }
                },
                {
                    data: 'deleterequest',
                    orderable: false,
                    className: "text-center",
                    width: "10%",
                    render: function (data) {
                        if (data == 1) {
                            // console.log(data);
                            // var column = ProfileWebsiteTable.column(3);
                            // column.visible(true);
                            // return data == 1 ? '<span class="chip red lighten-5 red-text"><small>Pending Deletion</small></span>' : '<span class="chip green lighten-5 green-text"><small>Active</small></span>';
                            return data == 1 ? '<div class="tooltip" style="z-index:5;"><i class="material-icons red-text">delete_sweep</i><span class="tooltiptext">Pending Deletion</span></div>' : '';
                        }
                        else{
                            return '<div class="tooltip" style="z-index:5;"><i class="material-icons green-text">radio_button_checked</i><span class="tooltiptext">Active</span></div>';
                            // return '<span class="chip green lighten-5 green-text"><small>Active</small></span>';
                        }
                    }
                },
                {
                    data: 'website_id',
                    name: 'website_id',
                    orderable: false,
                    className: "text-center",
                    render: function (data, type, row) {
                        if (row.website_id != null) {
                            let disableDelete = '';
                            let del  = row.self ? '<a data-id="' + data + '" data-count="'+row.deletecount+'" class="mb-6 btn-floating btn-small waves-effect waves-light red gradient-shadow mr-3 ProfileWebsiteDelete tooltipped" data-position="left" data-tooltip="Delete" '+disableDelete+'><i class="material-icons">delete</i></a>' : '<button class="mb-6 btn-floating btn-small waves-effect waves-light red gradient-shadow mr-3" disabled><i class="material-icons">delete</i></button>';
                            let edit = row.self ? 'ProfileWebsiteEdit' : 'ProfileWebsiteShow';
                            let edit_icon = row.self ? 'edit' : 'list';
                            let edit_txt = row.self ? 'Edit' : 'View';

                            let editBtn = '<a data-id="' + data + '"  class="mb-6 btn-floating btn-small waves-effect waves-light indigo gradient-shadow mr-3 '+edit+' tooltipped" data-position="left" data-tooltip="'+edit_txt+'"><i class="material-icons">'+edit_icon+'</i></a>';
                            if(row.deleterequest){
                                del  = '<button class="mb-6 btn-floating btn-small waves-effect waves-light red gradient-shadow mr-3" disabled><i class="material-icons">delete</i></button>';
                                editBtn = '<button class="mb-6 btn-floating btn-small waves-effect waves-light indigo gradient-shadow mr-3 '+edit+'" disabled><i class="material-icons">'+edit_icon+'</i></button>';
                            }

                            if($("#WebApproval").length > 0){
                                editBtn = '';
                                del  = row.self ? '<a data-id="' + data + '" data-count="'+row.deletecount+'" class="mb-6 btn-floating btn-small waves-effect waves-light red gradient-shadow mr-3 ProfileWebsiteDelete tooltipped" data-position="left" data-tooltip="Delete" '+disableDelete+'><i class="material-icons">delete</i></a>' : '<button class="mb-6 btn-floating btn-small waves-effect waves-light red gradient-shadow mr-3" disabled><i class="material-icons">delete</i></button>';
                            }

                            // let tracking_code = row.tracking_code ? '<button  data-id="' + data + '" class="mb-6 btn-floating btn-small waves-effect waves-light indigo gradient-shadow mr-3 website_tracking_code"><i class="material-icons">code</i></button>' : '';
                            return type === 'display' ?
                                // '<button data-id="' + data + '" class="indigo waves-effect waves-light btn-small ProfileWebsiteEdit mr-1" title="Edit"><i class="fas fa-edit"></i></button>' +
                                // '<button data-id="' + data + '" class="red waves-effect waves-light btn-small ProfileWebsiteDelete mr-1" title="Delete"><i class="fas fa-trash"></i></button>' :
                                // tracking_code +
                                editBtn +
                                del :
                                data;
                        } else {
                            return '';
                        }
                    },
                },
                {
                    data: 'agensi',
                    name: 'agensi',
                },

                {
                    data: 'ministry',
                    name: 'ministry',
                },
                {
                    data: 'is_ministry',
                    name: 'is_ministry',
                },
                {
                    data: 'env',
                    name: 'env',
                },
            ],
            columnDefs: [{
                targets: columnDef,
                searchable: true,
                visible: false,

            }
            ],
            // order: [[6, 'asc']],
            pageLength: length,
            drawCallback: function( settings ) {
                $('.tooltipped').tooltip();
                var deleterequest = 0;
                // ProfileWebsiteTable.rows().every( function ( rowIdx, tableLoop, rowLoop ) {
                //     var data = this.data();
                //     if(data.deleterequest == 1){
                //         deleterequest++;
                //     }
                // } );
                // if(deleterequest > 0){
                //     if($("#WebApproval").length == 0){
                //         var column = ProfileWebsiteTable.column(3);
                //         column.visible(true);
                //     }
                // }
            }
        });
    }

    $(document).on('change', '[name="ProfileWebsiteTable_length"]', function () {
        let val = $(this).val();
        Cookies.set('ProfileWebsiteTable_length', val, { sameSite: 'None' });
    });

    $(document).on('click', '.ProfileWebsiteEdit', function () {
        let data = $(this).data('id');
        window.location.replace('/profiles/website/' + data + '/edit');
    });

    $(document).on('click', '.ProfileWebsiteShow', function () {
        let data = $(this).data('id');
        window.location.replace('/profiles/website/' + data + '/show');
    });

    $(document).on('click', '.ProfileWebsiteDelete', function () {
        let id = $(this).data("id");
        let btn_color =  '#f44336';
        if($("#WebApproval").length > 0){
            let deleteCount = $(this).data('count');
            Swal.fire({
                title: 'Proceed With Caution!',
                html: "<span class='new badge orange' data-badge-caption='Delete History : "+deleteCount+"' style='float:none;'></span><br>Please choose <b>Approve OR Reject</b> below.",
                // type: 'warning',
                icon: 'warning',
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonColor: '#4caf50',
                cancelButtonColor: '#bbbbbb',
                confirmButtonText: 'Approve',
                cancelButtonText: 'Cancel',
                denyButtonText: 'Reject',
            }).then((result) => {
                if (result.isConfirmed) {
                    $('#ProfileWebsiteDeleteFormAction').val('approved');
                    $('#ProfileWebsiteDeleteFormReason').val('');
                    $('#ProfileWebsiteDeleteForm').attr('action', "/profiles/website/" + id);
                    $('#ProfileWebsiteDeleteForm').submit();
                }
                else if (result.isDenied) {
                    Swal.fire({
                        title: 'Proceed With Caution!',
                        html: "Are you sure want to <b>Reject</b> this request?",
                        // type: 'warning',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: btn_color,
                        cancelButtonColor: '#9e9e9e',
                        confirmButtonText: 'reject'.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()),
                        input: 'textarea',
                        inputLabel: 'Reason',
                        inputPlaceholder: 'Type your reason here...',
                        inputAttributes: {
                            'aria-label': 'Type your message here',
                            // required: 'true'
                        },
                        preConfirm: (value) => {
                            if (!value) {
                                Swal.showValidationMessage('Reason is required');
                            }
                        }
                    }).then((result) => {
                        if (result.isConfirmed) {
                            $('#ProfileWebsiteDeleteFormAction').val('reject');
                            $('#ProfileWebsiteDeleteFormReason').val(result.value);
                            $('#ProfileWebsiteDeleteForm').attr('action', "/profiles/website/" + id);
                            $('#ProfileWebsiteDeleteForm').submit();
                        }
                    });
                }
            });
        }
        else{

            Swal.fire({
                title: 'Proceed With Caution!',
                html: "Do you really want to delete these record?<br>This process cannot be undone.",
                type: 'warning',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#9e9e9e',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value) {
                    $('#ProfileWebsiteDeleteForm').attr('action', "/profiles/website/" + id);
                    $('#ProfileWebsiteDeleteForm').submit();
                }
            });
        }
    });

    if ($('#filter-url').length > 0) {
        $('#filter-url').keydown(function(event){
            var keyCode = (event.keyCode ? event.keyCode : event.which);
            if (keyCode == 13) {
                $('.filter-profile-website-btn').click();
            }
        });
    }

    $(document).on('click', '.filter-profile-website-btn', function () {
        // ProfileWebsiteTable.column(2).search($('#filter-url').val())
        //                     .column(6).search($('#filter-type').val())
        //                     .column(7).search($('#filter-env_mode').val())
        //                     .draw();

        // if ($('#filter-minitry').val()) {
        //     let ministry = $('#filter-minitry').val().split('|');
        //     if (ministry[0] == 1) {
        //         ProfileWebsiteTable.column(5).search(  "^" + ministry[1] + "$", true, false, true  )
        //                 .column(4).search( '' )
        //                 .draw();
        //     } else {
        //         ProfileWebsiteTable.column(4).search(  "^" + ministry[1] + "$", true, false, true  )
        //                     .column(5).search( '' )
        //                     .draw();
        //     }
        // }

        ProfileWebsiteTable.draw();

        let store = {
            'url'       : $('#filter-url').val(),
            'type'      : $('#filter-type').val(),
            'minitry'   : $('#filter-minitry').val(),
            'env_mode'  : $('#filter-env_mode').val()
        };

        Cookies.set('ProfileWebsiteFilter', JSON.stringify(store), { sameSite: 'None' });
    });

    $(document).on('click', '.filter-profile-website-btn', function () {

    });

    if ($('.filter-profile-website-btn').length > 0) {
        let data = Cookies.get('ProfileWebsiteFilter');
        if (data) {
            data = JSON.parse(data);
            $('#filter-url').val(data.url);
            $('#filter-type').val(data.type);
            $('#filter-minitry').val(data.minitry);
            $('#filter-env_mode').val(data.env_mode);

            $('#filter-type').select2();
            $('#filter-minitry').select2();
            $('#filter-env_mode').select2();

            $('.filter-profile-website-btn').click();
        }
    }

    $(document).on('click', '.filter-profile-website-reset-btn', function () {
        $('#filter-minitry').val('');
        $('#filter-url').val('');
        $('#filter-type').val('');
        $('#filter-env_mode').val('');
        $('#filter-minitry').select2({
            dropdownAutoWidth: true,
            width: '100%',
            tags: false,
            templateResult: AgencyFormat2,
            templateSelection: AgencyFormat1,
            escapeMarkup: function (es) { return es; }
        });
        $('#filter-type').select2();
        $('#filter-env_mode').select2();
        ProfileWebsiteTable.column(4).search('')
                            .column(5).search('')
                            .column(2).search('')
                            .column(6).search('')
                            .column(7).search('')
                            .draw();

        Cookies.remove('ProfileWebsiteFilter');
    });

    function AgencyFormat1(icon) {
        var originalOption = icon.element;
        if (!icon.id) { return icon.text; }
        var $icon = icon.text ;
        return $icon;
    }

    function AgencyFormat2(icon) {
        var originalOption = icon.element;
        if (!icon.id) { return icon.text; }
        if ($(icon.element).data('ministry')) {
            var $icon = icon.text + "<br><small>"+$(icon.element).data('ministry')+"</small>" ;
        } else {
            var $icon = icon.text ;
        }
        return $icon;
    }

    $(document).on('change', '#agency_id', function () {
        $.ajax({
            type:'POST',
            url:'/profiles/website/create/getwebmaster',
            data:{
                _token: $('meta[name="csrf-token"]').attr('content'),
                agency_id : $(this).val(),
             },
             beforeSend: function () {
                $("#Staging").prop('disabled', false);
                $("#Development").prop('disabled', false);
                $("#Production").prop('disabled', false);
                $('#dev_mode').val("").select2();
                $("#name_en").attr("placeholder","Laman Web <Nama Agensi>");
             },
            success:function(result){
                if(result){
                    $('#owner_supervisor').html('');
                    result.supervisor.forEach(element => {
                        $('#owner_supervisor').append('<li>'+element.name+'</li>');
                    });
                    $('#owner_webmaster').html('');
                    result.webmaster.forEach(element => {
                        $('#owner_webmaster').append('<li>'+element.name+'</li>');
                    });
                    result.websiteMode.forEach(element => {
                        $("#"+element).prop('disabled', true);
                    });
                    $('#dev_mode').select2();
                    $("#name_en").attr("placeholder",'Laman Web '+result.agency);
                }
            },
        });
    });

    $(document).on('click', '.addTaggingUrlModal', function () {

        var taging = [];
        let modal_count = $(this).val();
        let modal_count_var = $(this);
        $.ajax({
            type:'POST',
            url:'/profiles/website/geturltagging',
            async: false,
            data:{
                _token: $('meta[name="csrf-token"]').attr('content'),
                ministry_id : $('#ministry_id').val(),
                agency_id   : $('#agency_id').val(),
             },
            success:function(result){
                if(result){
                    taging = result;
                }
            },
        });

        let options = '';
        options += '<option value="" selected disabled>Please Select Tag</option>';
        taging.forEach(element => {
            options += '<option value="'+element.name+'" data-id="'+element.id+'">'+element.name+'</option>';
        });
        let html =  '<div class="row">' +
                        '<div class="col s12 input-field">' +
                            '<label for="TaggingUrlTag">Tagging <span class="red-text">*</span></label>' +
                            '<div class="input-field pt-3">' +
                                '<select id="TaggingUrlTag" class="select2 browser-default">' +
                                    options +
                                '</select>' +
                            '</div>' +
                        '</div>' +
                        '<div id="TaggingUrlInputs">' +
                            '<div class="col s12 input-field">' +
                                '<input type="url" class="form-control" id="TaggingUrlUrl">' +
                                '<label for="TaggingUrlUrl">URL <span class="red-text">*</span></label>' +
                            '</div>' +
                        '</div>' +
                    '</div>';

        Swal.fire({
            title: "Add Tagging Url",
            html: html,
            showCancelButton: true,
            confirmButtonText: 'Add',
            confirmButtonColor: '#3f51b5',
            preConfirm: (data) => {
                if($('#TaggingUrlTag').val() != null && $('#TaggingUrlUrl').val() != null) {
                    let regex_url =/^(https?|s?ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
                    if (!regex_url.test($('#TaggingUrlUrl').val())) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Invalid URL!',
                        });
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'All field is required!',
                    });
                }
            },
        }).then((result) => {
            if (result.isConfirmed && $('#TaggingUrlTag').val() && $('#TaggingUrlUrl').val()) {
                var data = {
                    'criteria'  : $('#TaggingUrlTag').find(":selected").data('id'),
                    'tagging'   : $('#TaggingUrlTag').val(),
                    'attributes': null,
                    'url'       : $('#TaggingUrlUrl').val(),
                };
                if ($('#tagging_url').val().length > 0) {
                    let data2 = JSON.parse($('#tagging_url').val());
                    data2.push(data);
                    $('#tagging_url').val(JSON.stringify(data2));
                } else {
                    let data2 = [];
                    data2.push(data);
                    $('#tagging_url').val(JSON.stringify(data2));
                }
                let card =  '<div class="row" data-id="remove_' + modal_count + '">' +
                                '<div class="col s12 m6 l6">' +
                                    '<div class="card">' +
                                        '<div class="card-content">' +
                                            '<div class="row p-2">' +
                                                '<div class="col s10">' +
                                                    '<p><b>Tagging  :</b> ' + $('#TaggingUrlTag').val() + '</p>' +
                                                    '<p><b>URL      :</b> ' + $('#TaggingUrlUrl').val() + '</p>' +
                                                '</div>' +
                                                '<div class="col s2 right-align">' +
                                                    '<a class="btn-floating waves-effect waves-light deleteTaggingUrl" data-id="' + modal_count + '"><i class="material-icons">delete</i></a>' +
                                                '</div>' +
                                            '</div>' +
                                        '</div>'+
                                    '</div>'+
                                '</div>'+
                            '</div>';
                $('#append_tagging_url').append(card);
                $('#TaggingUrlTag').val('');
                $('#TaggingUrlUrl').val('');
                modal_count_var.val(++modal_count);
            } else if (result.isDenied) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!'
                });
            }
        });
    });

    $(document).on('change', '#TaggingUrlTag', function () {
        let id = $('#TaggingUrlTag').find(":selected").data('id');
        let html = '<div class="col s12 input-field">' +
                        '<input type="url" class="form-control" id="TaggingUrlUrl">' +
                        '<label for="TaggingUrlUrl">URL <span class="red-text">*</span></label>' +
                    '</div>';
        $('#TaggingUrlInputs').html(html);
    });

    $(document).on('click', '.deleteTaggingUrl', function () {
        let data = JSON.parse($('#tagging_url').val());
        let value = $(this).data('id');
        Swal.fire({
            title: 'Proceed With Caution!',
            html: "Do you really want to remove this criteria?<br>This process cannot be undone.",
            type: 'warning',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#9e9e9e',
            confirmButtonText: 'Yes, remove it!'
          }).then((result) => {
            if (result.isConfirmed) {
                // console.log(data, value);
                // data = data.filter(url => url.url != value);
                data.splice((value-1),1);
                // console.log(data);
                $('[data-id="remove_' + value + '"]').remove();
                if (data.length > 0) {
                    $('#tagging_url').val(JSON.stringify(data));
                } else {
                    $('#tagging_url').val('');
                }
            }
          })
    });

    $(document).on('change', '#downtime_start', function () {
        if ($(this).val() != '') {
            $('#downtime_end').prop('required', true);
        } else {
            $('#downtime_end').prop('required', false);
        }
        let remaining = $('#UsedDowntime').data('value') ? $('#UsedDowntime').data('value') : 0;
        $('#downtime_end').val('');
        $('#downtime_end').prop('disabled', false);
        $('#downtime_end').attr('min', moment($(this).val()).format('YYYY-MM-DD HH:mm'));
        $('#downtime_end').attr('max', moment($(this).val()).add(192 - remaining, 'hours').format('YYYY-MM-DD HH:mm'));

        const downtime_end = flatpickr("#downtime_end", {
            enableTime: true,
            dateFormat: "Y-m-d H:i",
            monthSelectorType: 'static',
            maxDate : moment($(this).val()).add(192 - remaining, 'hours').format('YYYY-MM-DD HH:mm'),
            minDate : moment($(this).val()).format('YYYY-MM-DD HH:mm'),
            allowInput: true,
            onReady: function(){
                $("#downtime_end-error").remove();
                $("#downtime_end").after('<div id="downtime_end-error" class="error"></div>');
            },
            onDayCreate: function(dObj, dStr, fp, dayElem){
                if(dStr != '<empty string>' && dStr != ''){
                    var d = new Date(Date.parse(dStr));
                    if (Object.prototype.toString.call(d) === "[object Date]")
                    {
                        if (isNaN(d.getTime())) {
                            $("#downtime_end-error").html('Date format invalid.');
                            downtime_end.clear();
                        }
                        else{
                            $("#downtime_end-error").html('');
                        }
                    }
                }
            },
            onChange: (selectedDates, dateStr, instance) => {
                // console.log(selectedDates,dateStr);
                if(dateStr === ''){
                    $("#downtime_end-error").html('Date format invalid.');
                    // $("#downtime_end").after('<div id="downtime_end-error" class="error">Date format invalid.</div>');
                }
             },
        });

    });

    if ($('#downtime_start').length > 0) {
        const downtime_start = flatpickr("#downtime_start", {
            enableTime: true,
            dateFormat: "Y-m-d H:i",
            monthSelectorType: 'static',
            minDate : moment().add().format('YYYY-MM-DD HH:mm'),
            allowInput: true,
            onReady: function(){
                $("#downtime_start-error").remove();
                $("#downtime_start").after('<div id="downtime_start-error" class="error"></div>');
            },
            onDayCreate: function(dObj, dStr, fp, dayElem){
                // $("#downtime_start-error").html('');
                let isInvalid = false;
                if(dStr != '<empty string>' && dStr != ''){
                    var d = new Date(Date.parse(dStr));
                    if (Object.prototype.toString.call(d) === "[object Date]")
                    {
                        if (isNaN(d.getTime())) {
                            $("#downtime_start-error").html('Date format invalid.');
                            downtime_start.clear();
                            isInvalid=true;
                        }
                        else{
                            $("#downtime_start-error").html('');
                        }
                    }
                }
            },
            onChange: (selectedDates, dateStr, instance) => {
                // console.log(selectedDates,dateStr);
                if(dateStr === ''){
                    $("#downtime_start-error").html('Date format invalid.');
                    // $("#downtime_end").after('<div id="downtime_end-error" class="error">Date format invalid.</div>');
                }
             },

        });
    }

    if ($('#last_update_date').length > 0) {
        flatpickr("#last_update_date", {
            dateFormat: "Y-m-d",
            monthSelectorType: 'static',
            maxDate : moment().format('YYYY-MM-DD HH:mm'),
            allowInput: true,
            onChange: (selectedDates, dateStr, instance) => {
                if(dateStr === ''){
                    // console.log(selectedDates,dateStr);
                    $("#last_update_date").after('<div id="last_update_date-error" class="error">Date format invalid.</div>');
                }
             },
        });



    }

    $(document).on('click', '.WebUrlClick', function () {
        let url = $('#official_website_url').val();
        let regex_url =/^(https?|s?ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
        if (regex_url.test(url)) {
            window.open(url, '_blank');
        } else {
            M.toast({html: 'Oops...  Invalid URL.', classes: 'rounded red'});
        }
    });

    if ($('#WebsiteIDCallback').length > 0) {
        if ($('#WebsiteIDCallback').data('id')) {
            tracking_code($('#WebsiteIDCallback').data('id'));
        }
    }

    $(document).on('click', '.website_tracking_code', function () {
        tracking_code($(this).data('id'));
    });

    function tracking_code(id){
        $.ajax({
            type: 'POST',
            url: '/profiles/website/gettrackingscript',
            data: {
                _token: $('meta[name="csrf-token"]').attr('content'),
                id: id,
            },
            success: function (result) {
                if (result) {
                    Swal.fire({
                        icon: 'info',
                        title: 'Tracking Code',
                        width: '900px',
                        html: '<div class="align-left">Make sure this code is on every page of your website. We recommend pasting it immediately before the closing &lt;/head&gt; tag.<br><br><pre class="m-4"><code class="language-markup">' + result + '</code></pre></b></div>',
                    });
                }
            },
        });
    }

    $(document).on('click', '.website_api_token', function () {
        api_token($(this).data('id'));
    });

    var copy_api_token = '';
    function api_token(id){
        $.ajax({
            type: 'POST',
            url: '/profiles/website/get_token',
            data: {
                _token: $('meta[name="csrf-token"]').attr('content'),
                id: id,
            },
            success: function (result) {
                if (result) {
                    // let half = Math.floor(result.token.length / 2);
                    // let str = result.token.slice(0, half) + '<br>               ' + result.token.slice(half, result.token.length);
                    // let half2 = Math.floor(result.verification_url.length / 2);
                    // let str2 = result.verification_url.slice(0, half2) + '<br>               ' + result.verification_url.slice(half2, result.verification_url.length);
                    // let str3 = result.verification_url.match(/.{44}/g).join('<br>                         ');
                    let html = `
                    <h5 class=""><b>API Token For Widget Score</b></h5>
                    <input type='hidden' id='api_token' value='{"_token":"`+result.token+`"}'>
                    <div class="row">
                    <div class="col s12">
                      <div id="basic-form" class="card card card-default scrollspy">
                        <div class="card-content left-align" style="padding: 2px 20px;">
                            <h5 class="mb-3">Example Request</h5>
                            <div class="chip cyan white-text">
                                <b>POST</b>
                            </div> <b>`+$('#ConfigURL').data('value')+`/api/get_my_score</b>
                            <pre style="display: block; overflow-x: auto; padding: 0.5em; background: rgb(51, 51, 51) none repeat scroll 0% 0%; color: white;"><div class="language-markup">
<b>Body:</b><br>
<i id="language-markup">{
    "_token": "`+result.token+`"

}</i>
                        </div></pre>
                        </div>
                      </div>
                      </div>
                      <div class="col s12">
                      <div id="basic-form" class="card card card-default scrollspy">
                        <div class="card-content left-align" style="padding: 0px 20px;">
                            <pre style="display: block; overflow-x: auto; padding: 0.5em; background: rgb(51, 51, 51) none repeat scroll 0% 0%; color: white;"><div class="language-markup">
<b>Expected Return:</b><br>
{
    "status": true,
    "final_score": `+result.final_score+`,
    "verification_url": "`+$('#ConfigURL').data('value')+`/verify/`+result.verification_url+`",
    "last_check": "`+result.last_check+`"
}
                        </div></pre>
                        </div>
                      </div>
                    </div>` ;
                    Swal.fire({
                        icon: 'info',
                        title: 'API Token',
                        width: '900px',
                        // html: '<pre class="m-4"><code class="language-markup" id="language-markup">' + result.token + '</code></pre></b></div>',
                        html: html,
                        denyButtonText: '<i class="fas fa-sync"></i> Regenerate Token',
                        // confirmButtonText: '<i class="fas fa-sync"></i> Regenerate',
                        confirmButtonColor: '#8e24aa',
                        denyButtonColor: '#8e24aa',
                        showCancelButton: true,
                        showDenyButton: true,
                        // denyButtonText: 'Copy to clipboard',
                        confirmButtonText: '<i class="far fa-clipboard"></i> Copy Token',
                        customClass: {
                            denyButton: 'copyToClipboard'
                        },
                    }).then((result) => {
                        console.log(result);
                        if (result.isDenied) {
                        // if (result.isConfirmed) {
                            $.ajax({
                                type: 'POST',
                                url: '/profiles/website/regenerate_token',
                                data: {
                                    _token: $('meta[name="csrf-token"]').attr('content'),
                                    id: id,
                                },
                                success: function (result) {
                                    api_token(id);
                                },
                            });
                        }

                        if (result.isConfirmed) {
                        // if (result.isDenied) {
                            copyDivToClipboard();
                        }

                    });
                }
            },
        });
    }

    function copyDivToClipboard() {
        var $temp = $("<input>");
        $("body").append($temp);
        $temp.val($("#api_token").val()).select();
        document.execCommand("copy");
        $temp.remove();
        // var range = document.createRange();
        // range.selectNode(document.getElementById("api_token"));
        // window.getSelection().removeAllRanges(); // clear current selection
        // window.getSelection().addRange(range); // to select text
        // document.execCommand("copy");
        // window.getSelection().removeAllRanges();// to deselect

        M.toast({html: 'Token has been copy to clipboard!.', classes: 'rounded green'});
    }

    if($(".agency_website_select").length > 0){

        $('.agency_website_select').on('select2:select', function (e) {
            var data = e.params.data;
            var id = data.id;
            $.ajax({
                type: 'POST',
                url: '/profiles/website/getprofilemode',
                data: {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                    id: id,
                },
                success: function (result) {
                    if(result.length > 0){
                        for(var i=0; i<result.length; i++){
                            $('#'+result[i]).prop('disabled', true);
                        }
                        $('#dev_mode').select2();
                    }
                },
            });
        });
    }

});
