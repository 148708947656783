$(document).ready(function () {

    // var ctx = document.getElementById('myChart').getContext('2d');
    var ctx = $("#barcharts_cms_type");

    // Chart Options
    var chartOptions = {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: true,
        responsiveAnimationDuration: 500,
        title: {
            display: false,
        },
        legend: {
            display: false
        },
        plugins: {
            labels: false
        }
    };

    // Chart Data

        var isRendered = false;
        var barcharts_cms_type = null;
        window.render_barcharts_cms_type = function(){
            $.ajax({
                type: 'POST',
                cache: true,
                url: '/dashboard/cmstypechart',
                data: {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                },
                success: function (result) {
                    if (result) {

                        var chartData = {
                            labels: result.chart.label,
                            datasets: [{
                                axis: 'y',
                                label: "agency",
                                data: result.chart.datas,
                                backgroundColor: [
                                    'rgb(255, 99, 132)',
                                    'rgb(75, 192, 192)',
                                    'rgb(255, 205, 86)',
                                    'rgb(201, 203, 207)',
                                    'rgb(54, 162, 235)',
                                    'rgb(75, 192, 192)',
                                    'rgb(255, 205, 86)',
                                    'rgb(201, 203, 207)',
                                    'rgb(54, 162, 235)',
                                    'rgb(75, 192, 192)',
                                    'rgb(255, 205, 86)',
                                    'rgb(201, 203, 207)',
                                    'rgb(54, 162, 235)',
                                    'rgb(75, 192, 192)',
                                    'rgb(255, 205, 86)',
                                    'rgb(201, 203, 207)',
                                    'rgb(54, 162, 235)'
                                ],
                                hoverBackgroundColor: "#00acc1",
                                borderColor: "transparent"
                            }, ]
                        };

                        var config = {
                            // type: "bar",
                            type: 'horizontalBar',
                            // Chart Options
                            options: chartOptions,

                            data: chartData
                        };

                        // Create the chart
                        if (isRendered) {
                            barcharts_cms_type.data.datasets[0].data = result.chart.datas;
                            barcharts_cms_type.data.labels = result.chart.label;
                            barcharts_cms_type.update();
                        } else {
                            barcharts_cms_type = new Chart(ctx, config);
                            isRendered = !isRendered;
                        }

                        if (result.chart.datas.every( (val, i, arr) => val === arr[0] )) {
                            //$('.barcharts_cms_type').hide();
                        } else {
                            //$('.barcharts_cms_type').show();
                        }

                        if (result.date) {
                            $('#barcharts_cms_type_date').text(moment(result.date).format('DD/MM/YYYY'));
                        } else {
                            $('#barcharts_cms_type_date').text(moment().format('DD/MM/YYYY'));
                        }
                    }
                },
            });
        }
        if ($("#barcharts_cms_type").length > 0 && $('meta[name="splask-login"]').length == 0) {
            window.render_barcharts_cms_type();
        }
        

});
