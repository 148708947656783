$(document).ready(function () {

    var ctx = $("#linechart_average_downtime_annualy");

    // Chart Options
    var chartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        responsiveAnimationDuration: 500,
        title: {
            display: false,
        },
        legend: {
            display: false
        },
        plugins: {
            labels: false
        },
        scales: {
            yAxes: [{
                ticks: {
                    // beginAtZero: true,
                    suggestedMin: 0,
                    stepSize: 10,
                    // min: 50,
                    callback: function(value) {if (value % 1   === 0) {return value;}},
                    autoSkip: true,
                    maxRotation: 0,
                    minRotation: 0
                },
                scaleLabel: {
                    display: true,
                    labelString: 'SLA (%)'
                }
            }],
            xAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: 'Months'
                },
                ticks: {
                    autoSkip: true,
                    maxRotation: 0,
                    minRotation: 0
                }
            }],
        }
    };




    // render_linechart_average_downtime_annualy();
    $('.js_agency_name').text('Government Websites');
    var isRendered = false;
    var linechart_average_downtime_annualy = null;
    window.render_linechart_average_downtime_annualy = function() {

        $.ajax({
            type: 'POST',
            cache: true,
            url: '/dashboard/linechart_average_downtime_annualy',
            data: {
                _token: $('meta[name="csrf-token"]').attr('content'),
                ministry_id:    ($("#main").length > 0) ? $('#filter-analitic-ministry').val() : '',
                agency_id:      ($("#main").length > 0) ? $('#filter-analitic-agency').val() : '',
            },
            beforeSend: function () {
                $('#js_linechart_average_loading_time_monthly').text('Average Loading Time Of Government Websites');
                $('#js_linechart_average_sla_monthly').text('Average SLA Of Government Websites');
                $('.js_agency_name').text('Government Websites');
            },
            success: function (result) {
                if (result) {

                    if($('#filter-analitic-ministry').length == 0){
                        $('#linechart_average_downtime_annualy_total_agency').text(result.chart.total_agency);
                    }


                    if($('#filter-analitic-agency').val() != ''){
                        $('#js_linechart_average_loading_time_monthly').text('Daily Loading Time for '+result.agency_name);
                        $('#js_linechart_average_sla_monthly').text('Daily SLA for '+result.agency_name);
                        $('.js_agency_name').text(result.agency_name);
                    }
                    else{
                        $('#js_linechart_average_loading_time_monthly').text('Average Loading Time for '+result.agency_name);
                        $('#js_linechart_average_sla_monthly').text('Average SLA for '+result.agency_name);
                        $('.js_agency_name').text(result.agency_name);
                    }
                    var chartData = {
                        labels: result.chart.label,
                        datasets: [{
                            label: "SLA",
                            data: result.chart.datas,
                            fill: false,
                            borderColor: 'rgb(255, 99, 132)',
                            tension: 0.1
                        }, ]
                    };

                    // const arr = result.chart.datas;
                    // const onlyNumbers = arr.filter(Number);
                    // let lowest = Math.min.apply(Math,onlyNumbers);

                    // lowest = lowest - (lowest * 0.2);
                    // console.log(window.nearest_round_number(lowest));

                    var config = {
                        type: "line",
                        options: chartOptions,
                        data: chartData
                    };

                    if (isRendered) {
                        // linechart_average_downtime_annualy.data.datasets[0].data = result.chart.datas;
                        // linechart_average_downtime_annualy.data.labels = result.chart.label;
                        // linechart_average_downtime_annualy.update();

                        if($('#filter-analitic-ministry').val() != "" && $('#filter-analitic-agency').val() == ""){

                            linechart_average_downtime_annualy.data.datasets[0].data = result.chart.datas_ministry;
                            linechart_average_downtime_annualy.data.labels = result.chart.label_ministry;
                            linechart_average_downtime_annualy.update();
                            if(result.chart.datas_ministry){
                                if (result.chart.datas_ministry.every( (val, i, arr) => val === arr[0] )) {
                                    //$('.linechart_average_downtime_annualy').hide();
                                } else {
                                    //$('.linechart_average_downtime_annualy').show();
                                }
                            }
                            else{
                                //$('.linechart_average_downtime_annualy').hide();
                            }
                            if(result.chart.total_ministry){
                                $('#linechart_average_downtime_annualy_total_agency').text(result.chart.total_ministry);
                            }
                            else{
                                $('#linechart_average_downtime_annualy_total_agency').text(0);
                            }
                        }
                        if($('#filter-analitic-agency').length > 0 && $('#filter-analitic-agency').val() != "" && $('#filter-analitic-ministry').val() != ""){
                            // console.log(result);
                            linechart_average_downtime_annualy.data.datasets[0].data = result.chart.datas_agency;
                            linechart_average_downtime_annualy.data.labels = result.chart.label_agency;
                            linechart_average_downtime_annualy.update();
                            if(result.chart.datas_agency){
                                if (result.chart.datas_agency.every( (val, i, arr) => val === arr[0] )) {
                                    //$('.linechart_average_downtime_annualy').hide();
                                } else {
                                    //$('.linechart_average_downtime_annualy').show();
                                }
                            }
                            else{
                                //$('.linechart_average_downtime_annualy').hide();
                            }
                            if(result.chart.total_agency){
                                $('#linechart_average_downtime_annualy_total_agency').text(result.chart.total_agency);
                            }
                            else{
                                $('#linechart_average_downtime_annualy_total_agency').text(0);
                            }
                        }
                        if($('#filter-analitic-agency').val() != "" && $('#filter-analitic-ministry').val() == ""){
                            linechart_average_downtime_annualy.data.datasets[0].data = result.chart.datas_agency;
                            linechart_average_downtime_annualy.data.labels = result.chart.label_agency;
                            linechart_average_downtime_annualy.update();
                            if(result.chart.datas_agency){
                                if (result.chart.datas_agency.every( (val, i, arr) => val === arr[0] )) {
                                    //$('.linechart_average_downtime_annualy').hide();
                                } else {
                                    //$('.linechart_average_downtime_annualy').show();
                                }
                            }
                            else{
                                //$('.linechart_average_downtime_annualy').hide();
                            }
                            if(result.chart.total_agency){
                                $('#linechart_average_downtime_annualy_total_agency').text(result.chart.total_agency);
                            }
                            else{
                                $('#linechart_average_downtime_annualy_total_agency').text(0);
                            }
                        }
                        if($('#filter-analitic-ministry').length > 0 && $('#filter-analitic-ministry').val() === "" && $('#filter-analitic-agency').val() === ""){

                            linechart_average_downtime_annualy.data.datasets[0].data = result.chart.datas;
                            linechart_average_downtime_annualy.data.labels = result.chart.label;
                            linechart_average_downtime_annualy.update();
                            if(result.chart.datas){
                                if (result.chart.datas.every( (val, i, arr) => val === arr[0] )) {
                                    //$('.linechart_average_downtime_annualy').hide();
                                } else {
                                    //$('.linechart_average_downtime_annualy').show();
                                }
                            }
                            else{
                                //$('.linechart_average_downtime_annualy').hide();
                            }
                            if(result.chart.total_agency){
                                // console.log('result1',result.chart.total_ministry);
                                $('#linechart_average_downtime_annualy_total_agency').text(result.chart.total_agency);
                            }
                            else{
                                // console.log('result2',result.chart);
                                $('#linechart_average_downtime_annualy_total_agency').text(0);
                            }
                        }


                    } else {
                        if(ctx.length > 0){
                            linechart_average_downtime_annualy = new Chart(ctx, config);
                        }
                        isRendered = !isRendered;
                    }

                    if (result.chart.date_reported) {
                        $('#linechart_average_downtime_annualy_date').text(moment(result.chart.date_reported).format('DD/MM/YYYY'));
                    } else {
                        $('#linechart_average_downtime_annualy_date').text(moment().format('DD/MM/YYYY'));
                    }

                    // if (result.chart.datas.every( (val, i, arr) => val === arr[0] )) {
                    //     //$('.linechart_average_downtime_annualy').hide();
                    // } else {
                    //     //$('.linechart_average_downtime_annualy').show();
                    // }
                }
            },
        });
    }
    if ($("#linechart_average_downtime_annualy").length > 0) {
        if($('meta[name="splask-login"]').length == 0){
            window.render_linechart_average_downtime_annualy();
        }
    }
    $(document).on('change', '.dashboard_changes', function () {
        window.render_linechart_average_downtime_annualy();
    });



});
