$(document).ready(function () {

    var ctx = $("#linechart_loading_time_miti_monthly");

    // Chart Options
    var chartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        responsiveAnimationDuration: 500,
        title: {
            display: false,
        },
        legend: {
            display: false
        },
        plugins: {
            labels: false
        },
        scales: {
            y: {
                type: 'logarithmic',
            },
        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    callback: function(value) {if (value % 1   === 0) {return value;}}
                },
                scaleLabel: {
                    display: true,
                    labelString: 'Seconds'
                }
            }],
            xAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: 'Days'
                }
            }],
        }
    };

    // Chart Data
    if ($("#linechart_loading_time_miti_monthly").length > 0) {
        var isRendered = false;
        var linechart_loading_time_miti_monthly = null;
        $.ajax({
            type: 'POST',
            cache: true,
            url: '/dashboard/linechart_loading_time_miti_monthly',
            data: {
                _token: $('meta[name="csrf-token"]').attr('content'),
            },
            success: function (result) {
                if (result) {

                    var chartData = {
                        labels: result.label,
                        datasets: [{
                            label: "Loading Time",
                            data: result.datas,
                            fill: false,
                            borderColor: 'rgb(255, 99, 132)',
                            tension: 0.1
                        }, ]
                    };

                    var config = {
                        type: "line",
                        options: chartOptions,
                        data: chartData
                    };

                    if (isRendered) {
                        linechart_loading_time_miti_monthly.data.datasets[0].data = result.datas;
                        linechart_loading_time_miti_monthly.data.labels = result.label;
                        linechart_loading_time_miti_monthly.update();
                    } else {
                        linechart_loading_time_miti_monthly = new Chart(ctx, config);
                        isRendered = !isRendered;
                    }

                    if (result.datas.every( (val, i, arr) => val === arr[0] )) {
                        // $('.linechart_loading_time_miti_monthly').hide();
                    } else {
                        // $('.linechart_loading_time_miti_monthly').show();
                    }
                }
            },
        });
    }


});
