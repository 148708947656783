$(document).ready(function () {

    var ctx = $("#linechart_average_downtime_monthly");

    // Chart Options
    var chartOptions = {
        width:'100%',
        responsive: true,
        maintainAspectRatio: true,
        responsiveAnimationDuration: 500,
        title: {
            display: false,
        },
        legend: {
            display: false
        },
        plugins: {
            labels: false
        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    callback: function(value) {if (value % 1   === 0) {return value ;}}
                },
                scaleLabel: {
                    display: true,
                    labelString: 'SLA (%)'
                }
            }],
            xAxes: [{
                ticks: {
                    callback: function(value, index) {
                        if (index == 0) {
                            return '';
                        } else {
                            if (value % 2 === 0) {return value; } else {return ''; }
                        }
                    },
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0
                },
                scaleLabel: {
                    display: true,
                    labelString: 'Days'
                },
            }],
        }
    };


    var isRendered = false;
    var linechart_average_downtime_monthly = null;
    window.render_linechart_average_downtime_monthly = function()
    {
        $.ajax({
            type: 'POST',
            cache: true,
            url: '/dashboard/linechart_average_downtime_monthly',
            data: {
                _token: $('meta[name="csrf-token"]').attr('content'),
                ministry_id:    ($("#main").length > 0) ? $('#filter-analitic-ministry').val() : '',
                agency_id:      ($("#main").length > 0) ? $('#filter-analitic-agency').val() : '',
            },
            success: function (result) {
                if (result) {

                    if($("#main").length == 0 && result.chart.total_agency){
                        $('#linechart_average_downtime_monthly_total_agency').text(result.chart.total_agency);
                        $('#linechart_average_loading_time_monthly_total_agency').text(result.chart.total_agency);

                    }

                    var chartData = {
                        labels: [],
                        datasets: [{
                            label: "SLA",
                            data: [],
                            fill: false,
                            borderColor: 'rgb(255, 99, 132)',
                            tension: 0.1
                        }, ]
                    };

                    var config = {
                        type: "line",
                        options: chartOptions,
                        data: chartData
                    };

                    if (isRendered) {
                        console.log('result',result);

                        if($('#filter-analitic-ministry').val() != "" && $('#filter-analitic-agency').val() == ""){
                            let chartLabels = result.chart.label_ministry;
                            let chartDataSets = result.chart.datas_ministry;
                            chartLabels.unshift("0");
                            chartDataSets.unshift("-");
                            linechart_average_downtime_monthly.data.datasets[0].data = chartDataSets;
                            linechart_average_downtime_monthly.data.labels = chartLabels;
                            linechart_average_downtime_monthly.update();
                            // if(result.chart.datas_ministry){
                            //     if (result.chart.datas_ministry.every( (val, i, arr) => val === arr[0] )) {
                            //         //$('.linechart_average_downtime_monthly').hide();
                            //     } else {
                            //         //$('.linechart_average_downtime_monthly').show();
                            //     }
                            // }
                            // else{
                            //     //$('.linechart_average_downtime_monthly').hide();
                            // }
                            if(result.chart.total_ministry){
                                // console.log('result1',result.chart.total_ministry);
                                $('#linechart_average_downtime_monthly_total_agency').text(result.chart.total_ministry);
                                $('#linechart_average_loading_time_monthly_total_agency').text(result.chart.total_ministry);
                            }
                            else{
                                // console.log('result2',result.chart);
                                $('#linechart_average_downtime_monthly_total_agency').text(0);
                                $('#linechart_average_loading_time_monthly_total_agency').text(0);
                            }
                        }
                        if($('#filter-analitic-agency').length > 0 && $('#filter-analitic-agency').val() != "" && $('#filter-analitic-ministry').val() != ""){
                            let chartLabels = result.chart.label_agency;
                            let chartDataSets = result.chart.datas_agency;



                            // chartLabels.unshift("0");
                            // chartDataSets.unshift("-");
                            linechart_average_downtime_monthly.data.datasets[0].data = chartDataSets;
                            linechart_average_downtime_monthly.data.labels = chartLabels;
                            linechart_average_downtime_monthly.update();
                            // if(result.chart.datas_agency){
                            //     if (result.chart.datas_agency.every( (val, i, arr) => val === arr[0] )) {
                            //         //$('.linechart_average_downtime_monthly').hide();
                            //     } else {
                            //         //$('.linechart_average_downtime_monthly').show();
                            //     }
                            // }
                            // else{
                            //     //$('.linechart_average_downtime_monthly').hide();
                            // }
                            if(result.chart.total_agency){
                                $('#linechart_average_downtime_monthly_total_agency').text(result.chart.total_agency);
                                $('#linechart_average_loading_time_monthly_total_agency').text(result.chart.total_agency);
                            }
                            else{
                                $('#linechart_average_downtime_monthly_total_agency').text(0);
                            }
                        }
                        if($('#filter-analitic-agency').val() != "" && $('#filter-analitic-ministry').val() == ""){
                            let chartLabels = result.chart.label_agency;
                            let chartDataSets = result.chart.datas_agency;
                            // chartLabels.unshift("0");
                            // chartDataSets.unshift("-");
                            linechart_average_downtime_monthly.data.datasets[0].data = chartDataSets;
                            linechart_average_downtime_monthly.data.labels = chartLabels;
                            linechart_average_downtime_monthly.update();
                            // if(result.chart.datas_agency){
                            //     if (result.chart.datas_agency.every( (val, i, arr) => val === arr[0] )) {
                            //         //$('.linechart_average_downtime_monthly').hide();
                            //     } else {
                            //         //$('.linechart_average_downtime_monthly').show();
                            //     }
                            // }
                            // else{
                            //     //$('.linechart_average_downtime_monthly').hide();
                            // }
                            if(result.chart.total_agency){
                                $('#linechart_average_downtime_monthly_total_agency').text(result.chart.total_agency);
                                $('#linechart_average_loading_time_monthly_total_agency').text(result.chart.total_agency);
                            }
                            else{
                                $('#linechart_average_downtime_monthly_total_agency').text(0);
                            }
                        }
                        if($('#filter-analitic-ministry').length > 0 && $('#filter-analitic-ministry').val() === "" && $('#filter-analitic-agency').val() === ""){
                            let chartLabels = result.chart.label;
                            let chartDataSets = result.chart.datas;
                            // chartLabels.unshift("0");
                            // chartDataSets.unshift("-");
                            linechart_average_downtime_monthly.data.datasets[0].data = chartDataSets;
                            linechart_average_downtime_monthly.data.labels = chartLabels;
                            linechart_average_downtime_monthly.update();
                            // if(result.chart.datas){
                            //     if (result.chart.datas.every( (val, i, arr) => val === arr[0] )) {
                            //         //$('.linechart_average_downtime_monthly').hide();
                            //     } else {
                            //         //$('.linechart_average_downtime_monthly').show();
                            //     }
                            // }
                            // else{
                            //     //$('.linechart_average_downtime_monthly').hide();
                            // }
                            if(result.chart.total_agency){
                                $('#linechart_average_downtime_monthly_total_agency').text(result.chart.total_agency);
                                $('#linechart_average_loading_time_monthly_total_agency').text(result.chart.total_agency);
                            }
                            else{
                                $('#linechart_average_downtime_monthly_total_agency').text(0);
                            }
                        }
                        else{
                            if($("#main").length == 0){
                                linechart_average_downtime_monthly.data.datasets[0].data = result.chart.datas;
                                linechart_average_downtime_monthly.data.labels = result.chart.label;
                                linechart_average_downtime_monthly.update();
                            }

                        }

                    } else {
                        if(ctx.length > 0){
                            linechart_average_downtime_monthly = new Chart(ctx, config);
                        }
                        isRendered = !isRendered;
                    }

                    if (result.date) {
                        $('#linechart_average_downtime_monthly_date').text(moment(result.date).format('DD/MM/YYYY'));
                    } else {
                        $('#linechart_average_downtime_monthly_date').text(moment().format('DD/MM/YYYY'));
                    }

                    //
                    // if(result.chart.total_agency){
                    //     $('#linechart_average_downtime_monthly_total_agency').text(result.chart.total_agency);
                    // }
                    // else{
                    //     $('#linechart_average_downtime_monthly_total_agency').text(0);
                    // }

                    // if (result.chart.datas.every( (val, i, arr) => val === arr[0] )) {
                    //     //$('.linechart_average_downtime_monthly').hide();
                    // } else {
                    //     //$('.linechart_average_downtime_monthly').show();
                    // }
                }
            },
        });
    }
    $(document).on('change', '.dashboard_changes', function () {
        window.render_linechart_average_downtime_monthly();
    });

    if($('meta[name="splask-login"]').length == 0){
        if ($("#linechart_average_downtime_monthly").length > 0) {
            window.render_linechart_average_downtime_monthly();
        }
    }

    if ($("#linechart_average_downtime_monthly").length > 0) {
        if($('meta[name="splask-login"]').length == 0){
            window.render_linechart_average_downtime_monthly();
        }
    }



});
