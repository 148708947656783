$(document).ready(function () {

    // var ctx = document.getElementById('myChart').getContext('2d');
    var ctx = $("#barcharts_agencies");

    // Chart Options
    var chartOptions = {
        elements: {
            rectangle: {
                borderWidth: 2,
                borderColor: "rgb(0, 255, 0)",
                borderSkipped: "left"
            }
        },
        responsive: true,
        maintainAspectRatio: true,
        responsiveAnimationDuration: 500,
        animation: {
            duration: 1,
            onComplete: function () {
                var chartInstance = this.chart,
                    ctx = chartInstance.ctx;

                ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                ctx.textAlign = 'center';
                ctx.textBaseline = 'bottom';

                this.data.datasets.forEach(function (dataset, i) {
                    var meta = chartInstance.controller.getDatasetMeta(i);
                    meta.data.forEach(function (bar, index) {
                        var data = dataset.data[index];
                        ctx.fillText(data, bar._model.x, bar._model.y - 5);
                    });
                });
            }
        },
        legend: {
            position: "top"
        },
        scales: {
            xAxes: [{
                display: true,
                gridLines: {
                    color: "#f3f3f3",
                    drawTicks: false
                },
                scaleLabel: {
                    display: true
                }
            }],
            yAxes: [{
                display: true,
                gridLines: {
                    color: "#f3f3f3",
                    drawTicks: false
                },
                scaleLabel: {
                    display: true
                },
                ticks: {
                    beginAtZero: true
                }
            }]
        },
        title: {
            display: false,
        },
        legend: {
            display: false
        },
        plugins: {
            labels: false
        }
    };

    if ($("#barcharts_agencies").length > 0) {
        var isRendered = false;
        var barcharts_agencies = null;
        $.ajax({
            type: 'POST',
            cache: true,
            url: '/dashboard/agenciesChart',
            data: {
                _token: $('meta[name="csrf-token"]').attr('content'),
            },
            success: function (result) {
                if (result) {
                    // Chart Data
                    var chartData = {
                        labels: ["Ministry", "SUK", "Agency"],
                        datasets: [{
                            label: "agency",
                            data: [result.ministry, result.suk, result.agency],
                            backgroundColor: [
                                'rgb(255, 99, 132)',
                                'rgb(54, 162, 235)',
                                'rgb(255, 205, 86)'
                            ],
                            hoverBackgroundColor: "#00acc1",
                            borderColor: "transparent"
                        }, ]
                    };

                    var config = {
                        type: "bar",

                        // Chart Options
                        options: chartOptions,

                        data: chartData
                    };

                    // Create the chart
                    if (isRendered) {
                        barcharts_agencies.data.datasets[0].data = [result.ministry, result.suk, result.agency];
                        barcharts_agencies.update();
                    } else {
                        barcharts_agencies = new Chart(ctx, config);
                        isRendered = !isRendered;
                    }


                    if (result.ministry == 0 && result.suk == 0 && result.agency == 0) {
                        //$('.barcharts_agencies').hide();
                    } else {
                        //$('.barcharts_agencies').show();
                    }
                }
            },
        });
    }
});
