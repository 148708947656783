const { countBy } = require("lodash");

$(document).ready(function () {

    //xtra
    const getOrCreateTooltip = (chart,data) => {
        let tooltipEl = chart.canvas.parentNode.querySelector('div');

        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
          tooltipEl.style.borderRadius = '3px';
          tooltipEl.style.color = 'white';
          tooltipEl.style.opacity = 1;
          tooltipEl.style.pointerEvents = 'none';
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.transform = 'translate(-50%, 0)';
          tooltipEl.style.transition = 'all .1s ease';

          const table = document.createElement('table');
          table.style.margin = '0px';

          tooltipEl.appendChild(table);
          chart.canvas.parentNode.appendChild(tooltipEl);
        }

        return tooltipEl;
      };

      const externalTooltipHandler = (context,data) => {
        // Tooltip Element
        const {chart, tooltip} = context;
        const tooltipEl = getOrCreateTooltip(chart);
        // console.log(tooltipEl);
        // Hide if no tooltip
        if (tooltip.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
        }

        // Set Text
        if (tooltip.body) {
            // console.log('ssss',tooltip);
          const titleLines = tooltip.title || [];
          const bodyLines = tooltip.body.map(b => b.lines);

          const tableHead = document.createElement('thead');

          titleLines.forEach(title => {
            const tr = document.createElement('tr');
            tr.style.borderWidth = 0;

            const th = document.createElement('th');
            th.style.borderWidth = 0;
            const text = document.createTextNode(title);

            th.appendChild(text);
            tr.appendChild(th);
            tableHead.appendChild(tr);
          });

          const tableBody = document.createElement('tbody');
          bodyLines.forEach((body, i) => {
            const colors = tooltip.labelColors[i];

            const span = document.createElement('span');
            span.style.background = colors.backgroundColor;
            span.style.borderColor = colors.borderColor;
            span.style.borderWidth = '2px';
            span.style.marginRight = '10px';
            span.style.height = '10px';
            span.style.width = '10px';
            span.style.display = 'inline-block';

            const tr = document.createElement('tr');
            tr.style.backgroundColor = 'inherit';
            tr.style.borderWidth = 0;

            const td = document.createElement('td');
            td.style.borderWidth = 0;

            const text = document.createTextNode(body);

            td.appendChild(span);
            td.appendChild(text);
            tr.appendChild(td);
            tableBody.appendChild(tr);
          });

          const tableRoot = tooltipEl.querySelector('table');

          // Remove old children
          while (tableRoot.firstChild) {
            tableRoot.firstChild.remove();
          }

          // Add new children
          tableRoot.appendChild(tableHead);
          tableRoot.appendChild(tableBody);
        }

        const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.left = positionX + tooltip.caretX + 'px';
        tooltipEl.style.top = positionY + tooltip.caretY + 'px';
        tooltipEl.style.font = tooltip.options.bodyFont.string;
        tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
      };
    //xtra
    // var ctx = document.getElementById('myChart').getContext('2d');
    var ctx = $("#barcharts_hall_of_fame");

    // Chart Options
    var chartOptions = {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: true,
        responsiveAnimationDuration: 500,
        title: {
            display: false,
        },
        legend: {
            display: false
        },
        events: ["click"],
        // plugins: {
        //     labels: false,

        // },
        plugins: {
            title: {
              display: true,
              text: 'Chart.js Line Chart - External Tooltips'
            },
            // tooltip: {
            //   enabled: false,
            //   position: 'nearest',
            // //   external: externalTooltipHandler
            // }
          },
          tooltips: {
            // Disable the on-canvas tooltip
            enabled: false,

            custom: function(tooltipModel) {
                // Tooltip Element
                // console.log('ssss', sessionStorage.getItem("barcharts_hall_of_fame"));
                // console.log(tooltipModel);
                var tooltipEl = document.getElementById('chartjs-tooltip');

                // Create element on first render
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjs-tooltip';
                    tooltipEl.innerHTML = '<table></table>';
                    tooltipEl.style.backgroundColor = "#000000";
                    tooltipEl.style.borderColor = "#000000";
                    tooltipEl.style.borderWidth = "thin";
                    tooltipEl.style.borderStyle = "solid";
                    tooltipEl.style.height = "250px";
                    tooltipEl.style.maxHeight = "500px";
                    tooltipEl.style.overflowY = "scroll"
                    document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }

                // Set caret Position
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                    tooltipEl.classList.add('no-transform');
                }

                function getBody(bodyItem) {
                    return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                    var titleLines = tooltipModel.title || [];
                    dataIndex = tooltipModel.dataPoints[0].index;
                    jsonData = JSON.parse(sessionStorage.getItem("barcharts_hall_of_fame"))[dataIndex];
                    // console.log('json',jsonData);
                    var bodyLines = tooltipModel.body.map(getBody);

                    var innerHtml = '<thead>';

                    titleLines.forEach(function(title) {
                        innerHtml += '<tr><th style="font-size:16px;">' + title + '</th></tr>';
                    });
                    innerHtml += '</thead><tbody>';
                    // jsonData.forEach(function(body, i) {
                        // console.log('body',body,i);
                        var child = jsonData.split(";");
                        // console.log('body-child',child);

                        var i = '';
                        child.forEach(function(body2, i2){
                        // console.log('body-2',body2);

                            // var colors = tooltipModel.labelColors[i];
                            var style = '';
                            // style += '; border-color:' + colors.borderColor;
                            style += 'border-width: 2px';
                            var span = '<span style="' + style + '">&nbsp;&nbsp;</span>';
                            innerHtml += '<tr><td>' + span + body2 + '</td></tr>';
                        });

                    // });
                    innerHtml += '</tbody>';

                    var tableRoot = tooltipEl.querySelector('table');
                    tableRoot.innerHTML = innerHtml;
                }

                // `this` will be the overall tooltip
                var position = this._chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                tooltipEl.style.opacity = 0.8;
                tooltipEl.style.color = "#FFFFFF";
                tooltipEl.style.position = 'absolute';
                tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                // tooltipEl.style.pointerEvents = 'none';
            }
        },
        scales: {
            yAxes: [{
                ticks: {
                    callback: function(value) {
                        if(value.length > 32){
                            return value.substr(0, 28) + '...';
                        }
                        else{
                            return value.substr(0, 32);//truncate
                        }
                    },
                }
            }]
        },
        // tooltips: {
        //     // callbacks: {
        //     //     label: function(tooltipItem, data) {
        //     //         return data.labeltooltips[tooltipItem.index].split(";");
        //     //     }
        //     // }
        //     // enabled: true,
        //     mode: 'label',
        //     callbacks: {
        //         title: function(tooltipItems, data) {
        //             var idx = tooltipItems[0].index;
        //             return data.labels[idx];
        //         },
        //         label: function(tooltipItems, data) {
        //             return data.labeltooltips[tooltipItems.index].split(";");
        //         }
        //     },
        //     position: "nearest",
        //     // enabled: false,
        //     // external: externalTooltipHandler
        //     enabled: true,
        //     // custom: function(tooltipModel) {
        //     //     console.log(tooltipModel);
        //     //     // Tooltip Element
        //     //     var tooltipEl = document.getElementById('chartjs-tooltip');

        //     //     // Create element on first render
        //     //     if (!tooltipEl) {
        //     //         tooltipEl = document.createElement('div');
        //     //         tooltipEl.id = 'chartjs-tooltip';
        //     //         tooltipEl.innerHTML = '<table></table>';
        //     //         tooltipEl.style.backgroundColor = "#FFFFFF";
        //     //         tooltipEl.style.borderColor = "#000000";
        //     //         tooltipEl.style.borderWidth = "thin";
        //     //         tooltipEl.style.borderStyle = "solid";
        //     //         document.body.appendChild(tooltipEl);
        //     //     }

        //     //     // Hide if no tooltip
        //     //     if (tooltipModel.opacity === 0) {
        //     //         tooltipEl.style.opacity = 0;
        //     //         return;
        //     //     }

        //     //     // Set caret Position
        //     //     tooltipEl.classList.remove('above', 'below', 'no-transform');
        //     //     if (tooltipModel.yAlign) {
        //     //         tooltipEl.classList.add(tooltipModel.yAlign);
        //     //     } else {
        //     //         tooltipEl.classList.add('no-transform');
        //     //     }

        //     //     function getBody(bodyItem) {
        //     //         return bodyItem.lines;
        //     //     }

        //     //     // Set Text
        //     //     console.log('ssss',tooltipModel.body,tooltipModel.title);
        //     //     if (tooltipModel.body) {
        //     //         var titleLines = tooltipModel.title || [];
        //     //         var bodyLines = tooltipModel.body.map(getBody);
        //     //         // console.log(bodyLines);
        //     //         var innerHtml = '<thead>';

        //     //         titleLines.forEach(function(title) {
        //     //             innerHtml += '<tr><th>' + title + '</th></tr>';
        //     //         });
        //     //         innerHtml += '</thead><tbody>';

        //     //         bodyLines.forEach(function(body, i) {
        //     //             var colors = tooltipModel.labelColors[i];
        //     //             var style = 'background:' + colors.backgroundColor;
        //     //             style += '; border-color:' + colors.borderColor;
        //     //             style += '; border-width: 2px';
        //     //             var span = '<span style="' + style + '"></span>';
        //     //             innerHtml += '<tr><td>' + span + body + '</td></tr>';
        //     //         });
        //     //         innerHtml += '</tbody>';

        //     //         var tableRoot = tooltipEl.querySelector('table');
        //     //         tableRoot.innerHTML = innerHtml;
        //     //     }

        //     //     // `this` will be the overall tooltip
        //     //     var position = this._chart.canvas.getBoundingClientRect();

        //     //     // Display, position, and set styles for font
        //     //     tooltipEl.style.opacity = 1;
        //     //     tooltipEl.style.position = 'absolute';
        //     //     tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
        //     //     tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
        //     //     tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
        //     //     tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
        //     //     tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
        //     //     tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
        //     //     tooltipEl.style.pointerEvents = 'none';
        //     // }
        // },
        // yAxes: [{
        //     display: true,
        //     ticks: {
        //         beginAtZero: true,
        //         // callback: function(value) {if (value % 1   === 0) {return value;}}
        //         callback: function(value) {
        //             return value.substr(0, 10);//truncate
        //         },
        //     }
        // }]
    };

    // Chart Data

        var isRendered = false;
        var barcharts_hall_of_fame = null;
        window.render_barcharts_hall_of_fame = function(){
            $.ajax({
                type: 'POST',
                cache: true,
                url: '/dashboard/barcharts_hall_of_fame',
                data: {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                },
                success: function (result) {
                    if (result) {
                        // console.log(result);
                        sessionStorage.setItem("barcharts_hall_of_fame", JSON.stringify(result.chart.tooltips));
                        // console.log('top',result.chart.tooltips);
                        var chartData = {
                            labels: result.chart.label,
                            labeltooltips: result.chart.tooltips,
                            datasets: [{
                                axis: 'y',
                                label: "agency",
                                data: result.chart.datas,
                                backgroundColor: [
                                    'rgb(255, 99, 132)',
                                    'rgb(75, 192, 192)',
                                    'rgb(255, 205, 86)',
                                    'rgb(201, 203, 207)',
                                    'rgb(54, 162, 235)',
                                    'rgb(75, 192, 192)',
                                    'rgb(255, 205, 86)',
                                    'rgb(201, 203, 207)',
                                    'rgb(54, 162, 235)',
                                    'rgb(75, 192, 192)',
                                    'rgb(255, 205, 86)',
                                    'rgb(201, 203, 207)',
                                    'rgb(54, 162, 235)',
                                    'rgb(75, 192, 192)',
                                    'rgb(255, 205, 86)',
                                    'rgb(201, 203, 207)',
                                    'rgb(54, 162, 235)'
                                ],
                                hoverBackgroundColor: "#00acc1",
                                borderColor: "transparent"
                            }, ]
                        };

                        var config = {
                            // type: "bar",
                            type: 'horizontalBar',
                            // Chart Options
                            options: chartOptions,

                            data: chartData
                        };

                        // Create the chart
                        if (isRendered) {
                            // console.log(result);
                            barcharts_hall_of_fame.data.datasets[0].data = result.chart.datas;
                            barcharts_hall_of_fame.data.labels = result.chart.label;
                            barcharts_hall_of_fame.update();
                        } else {
                            barcharts_hall_of_fame = new Chart(ctx, config);
                            isRendered = !isRendered;
                        }

                        if (result.chart.date_reported) {
                            $('#barcharts_hall_of_fame_date').text(moment(result.chart.date_reported).format('DD/MM/YYYY'));
                        } else {
                            $('#barcharts_hall_of_fame_date').text(moment().format('DD/MM/YYYY'));
                        }

                        if (result.chart.total_agency) {
                            $('#barcharts_hall_of_fame_agencies').text(result.chart.total_agency);
                        }



                        if (result.chart.datas.every( (val, i, arr) => val === arr[0] )) {
                            //$('.barcharts_hall_of_fame').hide();
                        } else {
                            //$('.barcharts_hall_of_fame').show();
                        }

                    }
                },
            });
        }
        if ($("#barcharts_hall_of_fame").length > 0 && $('meta[name="splask-login"]').length == 0) {
            window.render_barcharts_hall_of_fame();
        }



});
