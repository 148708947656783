$(document).ready(function () {

    // Create the chart

    if ($("#piecharts_users").length > 0) {
        render_piecharts_users();
    }

    $(document).on('change', '.dashboard_changes', function () {
        render_piecharts_users();
    });

    var isRendered      = false;
    var piecharts_users = null;
    function render_piecharts_users()
    {
        $.ajax({
            type: 'POST',
            url: '/dashboard/usersChart',
            data: {
                _token: $('meta[name="csrf-token"]').attr('content'),
                ministry_id:    ($("#main").length > 0) ? $('#filter-analitic-ministry').val() : '',
                agency_id:      ($("#main").length > 0) ? $('#filter-analitic-agency').val() : '',
            },
            success: function (result) {
                if (result) {
                    var ctx = $("#piecharts_users");

                    // Chart Options
                    var chartOptions = {
                        elements: {
                            rectangle: {
                                borderWidth: 2,
                                borderColor: "rgb(0, 255, 0)",
                                borderSkipped: "left"
                            }
                        },
                        responsive: true,
                        maintainAspectRatio: true,
                        responsiveAnimationDuration: 500,
                        legend: {
                            position: "top"
                        },
                        title: {
                            display: false,
                            text: ""
                        },
                        plugins: {
                            labels: {
                                render: 'value',
                                fontColor: 'white',
                                precision: 2
                            }
                        },
                    };

                    var supervisor = 0;
                    var webmaster = 0;
                    if($('#filter-analitic-ministry').val() != "" && $('#filter-analitic-agency').val() == ""){
                        supervisor = result.chart.count_supervisor_ministry;
                        webmaster = result.chart.count_webmaster_ministry;
                    }
                    if($('#filter-analitic-agency').val() != "" && $('#filter-analitic-ministry').val() != ""){
                        supervisor = result.chart.count_supervisor_agency;
                        webmaster = result.chart.count_webmaster_agency;
                    }
                    if($('#filter-analitic-agency').val() != "" && $('#filter-analitic-ministry').val() == ""){
                        supervisor = result.chart.count_supervisor_agency;
                        webmaster = result.chart.count_webmaster_agency;
                    }
                    if($('#filter-analitic-ministry').val() === "" && $('#filter-analitic-agency').val() === ""){
                        supervisor = result.chart.count_supervisor;
                        webmaster = result.chart.count_webmaster;
                    }
                    // if($)
                    // Chart Data
                    var chartData = {
                        labels: ['Supervisor', 'Webmaster'],
                        datasets: [{
                            label: 'My First Dataset',
                            data: [supervisor, webmaster],
                            backgroundColor: [
                                'rgb(255, 99, 132)',
                                'rgb(54, 162, 235)',
                            ],
                            hoverOffset: 4
                        }]
                    };

                    var config = {
                        type: "doughnut",
                        // Chart Options
                        options: chartOptions,
                        data: chartData
                    };

                    if (isRendered) {
                        piecharts_users.data.datasets[0].data = [supervisor, webmaster];
                        piecharts_users.update();
                    } else {
                        piecharts_users = new Chart(ctx, config);
                        isRendered = !isRendered;
                    }

                    if (result.date) {
                        $('#piecharts_users_date').text(moment(result.date).format('DD/MM/YYYY'));
                    } else {
                        $('#piecharts_users_date').text(moment().format('DD/MM/YYYY'));
                    }

                    // if (supervisor == 0 && webmaster == 0) {
                    //     $('.piecharts_users').hide();
                    // } else {
                    //     $('.piecharts_users').show();
                    // }
                }
            },
        });
    }

});
