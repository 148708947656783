$(document).ready(function () {

    // $.fn.dataTable.ext.errMode = function (settings, helpPage, message) {
    //     window.location.href = '/';
    // };

    window.nearest_round_number = function(num) {
        while (num % 10) {
            num--;
        }
        return num;
    }

    if ($(".datepicker").length > 0) {
        $('.datepicker').datepicker();
    }

    if ($(".render_datatable").length > 0) {
        $('.render_datatable').DataTable();
    }

    if ($(".select2").length > 0) {
        $(".select2").select2({
            dropdownAutoWidth: true,
            width: '100%',
            templateSelection: reformat,
            tags: false,
            templateResult: function (data, container) {
                if (data.element) {
                  $(container).addClass($(data.element).attr("class"));
                }
                return data.text;
            }
        });

        function reformat(icon) {
            var originalOption = icon.element;
            if (!icon.id) {
                return icon.text.replace(/&emsp;/g, '');
            }
            var $icon = icon.text.replace(/&emsp;/g, '');
            return $icon;
        }
    }

    if ($(".select2-icons").length > 0) {
        $(".select2-icons").select2({
            dropdownAutoWidth: true,
            width: '100%',
            minimumResultsForSearch: Infinity,
            templateResult: iconFormat,
            templateSelection: iconFormat,
            escapeMarkup: function (es) {
                return es;
            }
        });

        function iconFormat(icon) {
            var originalOption = icon.element;
            if (!icon.id) {
                return icon.text;
            }
            var $icon = $(icon.element).data('icon') ? "<img src='" + $(icon.element).data('icon') + "'  width='25' height='25'>&emsp;" + icon.text : icon.text;
            return $icon;
        }
    }

    if ($(".select2-agency-dropdown").length > 0) {
        select2_agency_dropdown();
    }

    function select2_agency_dropdown () {
        let _token = $('meta[name="csrf-token"]').attr('content');
        let agency_id = $('#filter-analitic-agency').val();
        let ministry_id = $('#filter-analitic-ministry').val();
        $(".select2-agency-dropdown").select2({
            dropdownAutoWidth: true,
            width: '100%',
            tags: false,
            templateResult: AgencyFormat2,
            templateSelection: AgencyFormat1,
            escapeMarkup: function (es) {
                return es;
            },
            // ajax: {
            //     type: 'POST',
            //     url: '/statistics/getagencies',
            //     data: {
            //         _token: _token,
            //         ministry_id: ministry_id,
            //     },
            //     processResults: function (data) {
            //         console.log(data);
            //         return {
            //             results: $.map(data, function (item) {
            //                 return {
            //                     text: item.agency_name,
            //                     id: item.token
            //                 }
            //             })
            //         };
            //     }
            //   }
        });



        function AgencyFormat1(icon) {
            var originalOption = icon.element;
            if (!icon.id) {
                return icon.text;
            }
            var $icon = icon.text;
            return $icon;
        }

        function AgencyFormat2(icon) {
            var originalOption = icon.element;
            if (!icon.id) {
                return icon.text;
            }
            if ($(icon.element).data('ministry')) {
                var $icon = icon.text + "<br><small>" + $(icon.element).data('ministry') + "</small>";
            } else {
                var $icon = icon.text;
            }
            return $icon;
        }
    }


    function select2_agency_dropdown_login () {
        let _token = $('meta[name="csrf-token"]').attr('content');
        let agency_id = $('#filter-analitic-agency-login').val();
        let ministry_id = $('#filter-analitic-ministry-login').val();
        $(".select2-agency-dropdown").select2({
            dropdownAutoWidth: true,
            width: '100%',
            tags: false,
            templateResult: AgencyFormat2,
            templateSelection: AgencyFormat1,
            escapeMarkup: function (es) {
                return es;
            },
            // ajax: {
            //     type: 'POST',
            //     url: '/statistics/getagencies',
            //     data: {
            //         _token: _token,
            //         ministry_id: ministry_id,
            //     },
            //     processResults: function (data) {
            //         console.log(data);
            //         return {
            //             results: $.map(data, function (item) {
            //                 return {
            //                     text: item.agency_name,
            //                     id: item.token
            //                 }
            //             })
            //         };
            //     }
            //   }
        });



        function AgencyFormat1(icon) {
            var originalOption = icon.element;
            if (!icon.id) {
                return icon.text;
            }
            var $icon = icon.text;
            return $icon;
        }

        function AgencyFormat2(icon) {
            var originalOption = icon.element;
            if (!icon.id) {
                return icon.text;
            }
            if ($(icon.element).data('ministry')) {
                var $icon = icon.text + "<br><small>" + $(icon.element).data('ministry') + "</small>";
            } else {
                var $icon = icon.text;
            }
            return $icon;
        }
    }


    $(document).on('click', '#reload', function () {
        $.ajax({
            type: 'GET',
            url: '/reload-captcha',
            success: function (data) {
                $("#reCaptcha").html(data.captcha);
            }
        });
    });

    $(document).on('change', '[name="nric"]', function () {
        let _token = $('meta[name="csrf-token"]').attr('content');
        let ic = $(this).val();

        $.ajax({
            type: 'POST',
            url: '/nric_validation',
            data: {
                _token: _token,
                nric: ic,
            },
            success: function (result) {
                if (result) {
                    $('#nric_invalid').hide();
                    $('#nric_invalid').text('');
                    $('.form-submit-btn').attr('disabled', false);
                } else {
                    $('#nric_invalid').show();
                    $('#nric_invalid').text('No. Pengenalan Diri tidak sah.');
                    $('.form-submit-btn').attr('disabled', true);
                }
            },
        });
    });

    $('[data-toggle="tooltip"]').tooltip();

    $('.tooltipped').tooltip();

    $(".card-alert .close").click(function(){$(this).closest(".card-alert").fadeOut("slow")});


    window.getagency = function(){
        let _token = $('meta[name="csrf-token"]').attr('content');
        let agency_id = $('#filter-analitic-agency').val();
        let ministry_id = $('#filter-analitic-ministry').val();
        $.ajax({
            type: 'POST',
            url: '/statistics/getagencies',
            data: {
                _token: _token,
                ministry_id: ministry_id,
                internal: $("#main").length
            },
            beforeSend: function () {
                $('#filter-analitic-agency').val(null).trigger('change');
            },
            success: function (result) {
                if($("#main").length == 0){
                    $('#filter-analitic-agency').html('<option value=""> All Participating Websites </option>');
                }
                else{
                    $('#filter-analitic-agency').html('<option value=""> All Agencies </option>');
                }
                if (result) {
                    result.forEach(element => {
                        $('#filter-analitic-agency').append('<option value="'+element.id+'" data-ministry="'+element.ministry_name+'" data-token="'+element.token+'">'+element.agency_name+'</option>');
                    });
                }
                if (ministry_id == '') {
                    $('#filter-analitic-agency[value="'+agency_id+'"]').prop('selected', true);
                } else {
                    $('#filter-analitic-agency[value=""]').prop('selected', true);
                }
                if ($('#filter-analitic-ministry').length > 0) {
                    select2_agency_dropdown();
                }
            },
        });
    }
    $(document).on('change', '#filter-analitic-ministry', function () {
        window.getagency();
    });
    window.getagencylogin = function(){
        let _token = $('meta[name="csrf-token"]').attr('content');
        let agency_id = $('#filter-analitic-agency-login').val();
        let ministry_id = $('#filter-analitic-ministry-login').val();
        $.ajax({
            type: 'POST',
            url: '/statistics/getagencies',
            data: {
                _token: _token,
                ministry_id: ministry_id,
                internal: $("#main").length
            },
            beforeSend: function () {
                $('#filter-analitic-agency-login').val(null).trigger('change');
            },
            success: function (result) {
                if($("#main").length == 0){
                    $('#filter-analitic-agency-login').html('<option value=""> All Participating Websites </option>');
                }
                else{
                    $('#filter-analitic-agency-login').html('<option value=""> All Agencies </option>');
                }
                if (result) {
                    result.forEach(element => {
                        $('#filter-analitic-agency-login').append('<option value="'+element.id+'" data-ministry="'+element.ministry_name+'" data-token="'+element.token+'">'+element.agency_name+'</option>');
                    });
                }
                if (ministry_id == '') {
                    $('#filter-analitic-agency-login[value="'+agency_id+'"]').prop('selected', true);
                } else {
                    $('#filter-analitic-agency-login[value=""]').prop('selected', true);
                }
                if ($('#filter-analitic-ministry-login').length > 0) {
                    select2_agency_dropdown_login();
                }
            },
        });
    }
    $(document).on('change', '#filter-analitic-ministry-login', function () {
        window.getagencylogin();
    });

    // $('#filter-analitic-agency').on('select2:open', function (e) {
    //     // Do something
    //     // window.getagency();
    // });
    // $('#filter-analitic-agency').on('select2:opening', function (e) {
    //     // Do something
    //     // console.log('test; opening');
    //     window.getagency();
    // });
    // $(document).on('change', '#filter-analitic-agency', function () {
    //     window.getagency();
    // });

    // setTimeout(function(){
    //     //
    // },0);
    // window.getagency();


});
