$(document).ready(function () {

    var ctx = $("#linechart_website_visitor_monthly");

    // Chart Options
    var chartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        responsiveAnimationDuration: 500,
        title: {
            display: false,
        },
        legend: {
            display: false
        },
        plugins: {
            labels: false
        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    callback: function(value) {if (value % 1   === 0) {return value;}},
                    autoSkip: true,
                    maxRotation: 0,
                    minRotation: 0
                },
                scaleLabel: {
                    display: true,
                    labelString: 'Visits'
                }
            }],
            xAxes: [{
                ticks: {
                    callback: function(value, index) {
                        if (index == 0) {
                            return '';
                        } else {
                            if (value % 2 === 0) {return value; } else {return ''; }
                        }
                    },
                    autoSkip: false,
                },
                scaleLabel: {
                    display: true,
                    labelString: 'Days'
                },
                ticks: {
                    autoSkip: true,
                    maxRotation: 0,
                    minRotation: 0
                }
            }],
        }
    };

    if ($("#linechart_website_visitor_monthly").length > 0) {
        if($('meta[name="splask-login"]').length == 0){
        render_linechart_website_visitor_monthly();
        }
    }

    $(document).on('change', '.dashboard_changes', function () {
        render_linechart_website_visitor_monthly();
    });

    var isRendered = false;
    var linechart_website_visitor_monthly = null;
    function render_linechart_website_visitor_monthly()
    {

        if(ctx.length > 0){
            $.ajax({
                type: 'POST',
                cache: true,
                url: '/dashboard/linechart_website_visitor_monthly',
                data: {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                    ministry_id:    ($("#main").length > 0) ? $('#filter-analitic-ministry').val() : '',
                    agency_id:      ($("#main").length > 0) ? $('#filter-analitic-agency').val() : '',
                },
                success: function (result) {
                    if (result) {
                        var chartData = {
                            labels: result.chart.label,
                            datasets: [{
                                label: "Visits",
                                data: result.chart.datas,
                                fill: false,
                                borderColor: 'rgb(255, 99, 132)',
                                tension: 0.1
                            }, ]
                        };

                        var config = {
                            type: "line",
                            options: chartOptions,
                            data: chartData
                        };


                        if (isRendered) {
                            let date_reported = result.chart.date_reported;
                            // linechart_website_visitor_monthly.data.datasets[0].data = result.chart.datas;
                            // linechart_website_visitor_monthly.data.labels = result.chart.label;
                            // linechart_website_visitor_monthly.update();
                            if($('#filter-analitic-ministry').val() != "" && $('#filter-analitic-agency').val() == ""){
                                let chartLabels = result.chart.label_ministry;
                                let chartDataSets = result.chart.datas_ministry;
                                // chartLabels.unshift("0");
                                // chartDataSets.unshift("-");
                                linechart_website_visitor_monthly.data.datasets[0].data = chartDataSets;
                                linechart_website_visitor_monthly.data.labels = chartLabels;
                                linechart_website_visitor_monthly.update();
                                if(result.chart.datas_ministry){
                                    if (result.chart.datas_ministry.every( (val, i, arr) => val === arr[0] )) {
                                        //$('.linechart_average_downtime_annualy').hide();
                                    } else {
                                        //$('.linechart_average_downtime_annualy').show();
                                    }
                                }
                                else{
                                    //$('.linechart_average_downtime_annualy').hide();
                                }
                            }
                            if($('#filter-analitic-agency').val() != "" && $('#filter-analitic-ministry').val() != ""){
                                let chartLabels = result.chart.label_agency;
                                let chartDataSets = result.chart.datas_agency;
                                // chartLabels.unshift("0");
                                // chartDataSets.unshift("-");
                                linechart_website_visitor_monthly.data.datasets[0].data = result.chart.datas_agency;
                                linechart_website_visitor_monthly.data.labels = result.chart.label_agency;
                                linechart_website_visitor_monthly.update();
                                if(result.chart.datas_agency){
                                    if (result.chart.datas_agency.every( (val, i, arr) => val === arr[0] )) {
                                        //$('.linechart_average_downtime_annualy').hide();
                                    } else {
                                        //$('.linechart_average_downtime_annualy').show();
                                    }
                                }
                                else{
                                    //$('.linechart_average_downtime_annualy').hide();
                                }
                            }
                            if($('#filter-analitic-agency').val() != "" && $('#filter-analitic-ministry').val() == ""){
                                let chartLabels = result.chart.label_agency;
                                let chartDataSets = result.chart.datas_agency;
                                // chartLabels.unshift("0");
                                // chartDataSets.unshift("-");
                                linechart_website_visitor_monthly.data.datasets[0].data = chartDataSets;
                                linechart_website_visitor_monthly.data.labels = chartLabels;
                                linechart_website_visitor_monthly.update();
                                if(result.chart.datas_agency){
                                    if (result.chart.datas_agency.every( (val, i, arr) => val === arr[0] )) {
                                        //$('.linechart_average_downtime_annualy').hide();
                                    } else {
                                        //$('.linechart_average_downtime_annualy').show();
                                    }
                                }
                                else{
                                    //$('.linechart_average_downtime_annualy').hide();
                                }
                            }
                            if($('#filter-analitic-ministry').val() === "" && $('#filter-analitic-agency').val() === ""){

                                let chartLabels = result.chart.label;
                                let chartDataSets = result.chart.datas;
                                // chartLabels.unshift("0");
                                // chartDataSets.unshift("-");
                                linechart_website_visitor_monthly.data.datasets[0].data = chartDataSets;
                                linechart_website_visitor_monthly.data.labels = chartLabels;
                                linechart_website_visitor_monthly.update();
                                if(result.chart.datas){
                                    if (result.chart.datas.every( (val, i, arr) => val === arr[0] )) {
                                        //$('.linechart_average_downtime_annualy').hide();
                                    } else {
                                        //$('.linechart_average_downtime_annualy').show();
                                    }
                                }
                                else{
                                    //$('.linechart_average_downtime_annualy').hide();
                                }
                            }
                        } else {
                            linechart_website_visitor_monthly = new Chart(ctx, config);
                            isRendered = !isRendered;
                        }

                        if (result.date) {
                            $('#linechart_website_visitor_monthly_date').text(moment(result.date).format('DD/MM/YYYY'));
                        } else {
                            $('#linechart_website_visitor_monthly_date').text(moment().format('DD/MM/YYYY'));
                        }

                        if (result.chart.date_reported) {
                            $('#linechart_website_visitor_monthly_date').text(moment(result.chart.date_reported).format('DD/MM/YYYY'));
                        }

                        // if (result.chart.datas.every( (val, i, arr) => val === arr[0] )) {
                        //     //$('.linechart_website_visitor_monthly').hide();
                        // } else {
                        //     //$('.linechart_website_visitor_monthly').show();
                        // }
                    }
                },
            });

        }
    }

});
