$(document).ready(function () {

    var ctx = $("#linechart_website_visitor_annualy");

    // Chart Options
    var chartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        responsiveAnimationDuration: 500,
        title: {
            display: false,
        },
        legend: {
            display: false
        },
        plugins: {
            labels: false
        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    callback: function(value) {if (value % 1   === 0) {return value;}},
                    autoSkip: true,
                    maxRotation: 0,
                    minRotation: 0

                },
                scaleLabel: {
                    display: true,
                    labelString: 'Visits'
                }
            }],
            xAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: 'Months'
                },
                ticks: {
                    autoSkip: true,
                    maxRotation: 0,
                    minRotation: 0
                }

            }],
        }
    };



    var isRendered = false;
    var linechart_website_visitor_annualy = null;
    window.render_linechart_website_visitor_annualy = function()
    {
        if(ctx.length > 0){
            $.ajax({
                type: 'POST',
                cache: true,
                url: '/dashboard/linechart_website_visitor_annualy',
                data: {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                    ministry_id:    ($("#main").length > 0) ? $('#filter-analitic-ministry').val() : '',
                    agency_id:      ($("#main").length > 0) ? $('#filter-analitic-agency').val() : '',
                },
                success: function (result) {
                    if (result) {

                        var chartData = {
                            labels: result.chart.label,
                            datasets: [{
                                label: "Visits",
                                data: result.chart.datas,
                                fill: false,
                                borderColor: 'rgb(255, 99, 132)',
                                tension: 0.1
                            }, ]
                        };

                        var config = {
                            type: "line",
                            options: chartOptions,
                            data: chartData
                        };

                        if (isRendered) {
                            // linechart_website_visitor_annualy.data.datasets[0].data = result.chart.datas;
                            // linechart_website_visitor_annualy.data.labels = result.chart.label;
                            // linechart_website_visitor_annualy.update();
                            if($('#filter-analitic-ministry').val() != "" && $('#filter-analitic-agency').val() == ""){

                                linechart_website_visitor_annualy.data.datasets[0].data = result.chart.datas_ministry;
                                linechart_website_visitor_annualy.data.labels = result.chart.label_ministry;
                                linechart_website_visitor_annualy.update();
                                if(result.chart.datas_ministry){
                                    if (result.chart.datas_ministry.every( (val, i, arr) => val === arr[0] )) {
                                        //$('.linechart_average_downtime_annualy').hide();
                                    } else {
                                        //$('.linechart_average_downtime_annualy').show();
                                    }
                                }
                                else{
                                    //$('.linechart_average_downtime_annualy').hide();
                                }
                            }
                            if($('#filter-analitic-agency').val() != "" && $('#filter-analitic-ministry').val() != ""){
                                console.log(result);
                                linechart_website_visitor_annualy.data.datasets[0].data = result.chart.datas_agency;
                                linechart_website_visitor_annualy.data.labels = result.chart.label_agency;
                                linechart_website_visitor_annualy.update();
                                if(result.chart.datas_agency){
                                    if (result.chart.datas_agency.every( (val, i, arr) => val === arr[0] )) {
                                        //$('.linechart_average_downtime_annualy').hide();
                                    } else {
                                        //$('.linechart_average_downtime_annualy').show();
                                    }
                                }
                                else{
                                    //$('.linechart_average_downtime_annualy').hide();
                                }
                            }
                            if($('#filter-analitic-agency').val() != "" && $('#filter-analitic-ministry').val() == ""){
                                linechart_website_visitor_annualy.data.datasets[0].data = result.chart.datas_agency;
                                linechart_website_visitor_annualy.data.labels = result.chart.label_agency;
                                linechart_website_visitor_annualy.update();
                                if(result.chart.datas_agency){
                                    if (result.chart.datas_agency.every( (val, i, arr) => val === arr[0] )) {
                                        //$('.linechart_average_downtime_annualy').hide();
                                    } else {
                                        //$('.linechart_average_downtime_annualy').show();
                                    }
                                }
                                else{
                                    //$('.linechart_average_downtime_annualy').hide();
                                }
                            }
                            if($('#filter-analitic-ministry').val() === "" && $('#filter-analitic-agency').val() === ""){
                                linechart_website_visitor_annualy.data.datasets[0].data = result.chart.datas;
                                linechart_website_visitor_annualy.data.labels = result.chart.label;
                                linechart_website_visitor_annualy.update();
                                if(result.chart.datas){
                                    if (result.chart.datas.every( (val, i, arr) => val === arr[0] )) {
                                        //$('.linechart_average_downtime_annualy').hide();
                                    } else {
                                        //$('.linechart_average_downtime_annualy').show();
                                    }
                                }
                                else{
                                    //$('.linechart_average_downtime_annualy').hide();
                                }
                            }
                        } else {
                            linechart_website_visitor_annualy = new Chart(ctx, config);
                            isRendered = !isRendered;
                        }

                        if (result.chart.date_reported) {
                            $('#linechart_website_visitor_annualy_date').text(moment(result.chart.date_reported).format('DD/MM/YYYY'));
                        } else {
                            $('#linechart_website_visitor_annualy_date').text(moment().format('DD/MM/YYYY'));
                        }

                        // if (result.chart.datas.every( (val, i, arr) => val === arr[0] )) {
                        //     //$('.linechart_website_visitor_annualy').hide();
                        // } else {
                        //     //$('.linechart_website_visitor_annualy').show();
                        // }
                    }
                },
            });
        }
    }

    if ($("#linechart_website_visitor_annualy").length > 0) {
        if($('meta[name="splask-login"]').length == 0){
        // window.render_linechart_website_visitor_annualy();
        }
    }

    $(document).on('change', '.dashboard_changes', function () {
        // window.render_linechart_website_visitor_annualy();
    });

});
