$(document).ready(function () {

    var is_desktop = false;
    if ($('#is_desktop').length > 0) {
        is_desktop = $('#is_desktop').data('value') ? false : true;
    }

    if ($('#ComplianceScoringTable').length > 0) {
        var ComplianceScoringTable = $('#ComplianceScoringTable').DataTable({
            responsive: is_desktop,
            processing: true,
            serverSide: true,
            lengthChange: false,
            paging: false,
            info: false,
            ordering: false,
            ajax: {
                "url": "/statistics/compliance_scoring/ajax",
                "type": "POST",
                "data": function (d) {
                    d._token = $('meta[name="csrf-token"]').attr('content');
                },
            },
            columns: [
                {
                    data: 'name',
                    render: function (data, type, row){
                        let dis = '';
                        if (row.dimension) {
                            dis = '<h6><b>'+data+'</b></h6>';
                        } else {
                            dis = '&emsp;'+data+'';
                        }
                        return dis;
                    }
                },
                {
                    data: 'name',
                    render: function (data, type, row){
                        let dis = '';
                        if (!row.dimension) {
                            dis = row.definitions;
                        }
                        return dis;
                    }
                },
                {
                    data: 'name',
                    render: function (data, type, row){
                        let dis = '';
                        if (!row.dimension) {
                            dis = '<div class="center-align"><span id="criteria'+row.id+'">'+row.compliance_score+'</span>%</div>';
                            // dis = row.compliance_score + ' %';
                        }
                        return dis;
                    }
                },
                {
                    data: 'dimension_key',
                    render: function (data, type, row){
                        let dis = '';
                        if (row.dimension) {
                            // console.log(row);
                            dis = '<div class="center-align"><h6><b><span id="dimension'+data+'"></span></b></h6></div>';
                        }
                        return dis;
                    }
                },
            ],
            pageLength: 50,
            drawCallback: function( settings ) {
                $('.tooltipped').tooltip();
            }
        });
    }

});
