$(document).ready(function () {

    var is_desktop = false;
    if ($('#is_desktop').length > 0) {
        is_desktop = $('#is_desktop').data('value') ? false : true;
    }

    if ($('.ImportLoading').length > 0) {
        setInterval(() => {
            $.ajax({
                type: 'POST',
                url: '/settings/agencies/import/status',
                data: {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                    log_id: $('.ImportLoading').data('log_id')
                },
                success: function (result) {
                    // console.log(result);
                    if (result.status) {
                        window.location.reload();
                    } else {
                        if (result.failed) {
                            window.location.replace("/settings/agencies/import/failed");
                        }
                    }
                },
            });
        }, 4000);

        // setTimeout(() => {
        //     window.location.replace("/settings/agencies/import/failed");
        // }, 60000);
    }

    if ($('#ImportFailed').length > 0) {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Import was unsuccessful. Please check your excel format or data.',
            allowEscapeKey: false,
            allowOutsideClick: false,
        });
    }

    if ($('#ImportAgencyTable').length > 0) {
        var ImportAgencyTable = $('#ImportAgencyTable').DataTable({
            responsive: is_desktop,
            processing: true,
            serverSide: true,
            ajax: {
                "url": "/settings/agencies/import/ajax",
                "type": "POST",
                "data": function (d) {
                    d._token = $('meta[name="csrf-token"]').attr('content')
                },
            },
            columns: [
                {
                    data: 'DT_RowIndex',
                    name: 'DT_RowIndex',
                    orderable: false,
                    searchable: false,
                    className: "text-center",
                },
                {
                    data: 'ministry_name',
                    name: 'ministry_name',
                    orderable: false,
                    render: function (data, type, row) {
                        let name = '<b>' + data.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) + '</b>';
                        if (row.agency_name) {
                            name = '<b>' + row.agency_name.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) + '<br><small>' + data.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) + '</small>';
                        }
                        return name;
                    }
                },
                {
                    data: 'status',
                    name: 'status',
                    className: "text-center",
                    render: function (data, type, row) {
                        return (data == '1') ? '<span class="badge green">Valid</span>' : (data == '2') ? '<span class="badge red">Already Exist</span>' : '<span class="badge black">Ministry Not Exist</span>';
                    }
                },
                {
                    data: 'agency_code',
                    name: 'agency_code',
                },
                {
                    data: 'agency_type',
                    name: 'agency_type',
                },
                {
                    data: 'agency_name',
                    name: 'agency_name',
                },
                {
                    data: 'agency',
                    name: 'agency',
                },
                {
                    data: 'ministry',
                    name: 'ministry',
                },
                {
                    data: 'isMinistry',
                    name: 'isMinistry',
                },
            ],
            columnDefs: [{
                targets: [ 3,4,5,6,7,8],
                searchable: true,
                visible: false,

            }],
            "pageLength": 25
        });
    }

    $(document).on('click', '.ImportConfirmationBtn', function(){
        let action = $(this).data('action');
        let btn_color = (action == 'approve') ? '#4caf50' : '#f44336';
        Swal.fire({
            title: 'Proceed With Caution!',
            html: "Are you Sure?<br>You won't be able to revert this process!",
            type: 'warning',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: btn_color,
            cancelButtonColor: '#9e9e9e',
            confirmButtonText: 'Yes, ' + action.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) + ' it!'
        }).then((result) => {
            if (result.isConfirmed) {
                let loading = Swal.fire({
                    html: 'Please wait...',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading()
                    }
                });
                $.ajax({
                    type: 'POST',
                    url: '/settings/agencies/import/confirm',
                    data: {
                        _token: $('meta[name="csrf-token"]').attr('content'),
                        action : action
                    },
                    success: function (result) {
                        if (result) {
                            loading.close();
                            let msg = (action == 'approve') ? '<h5>New agencies successfully imported.</h5>' : '<h5>Import agencies has canceled.</h5>'
                            Swal.fire({
                                icon: 'success',
                                html: msg,
                                showConfirmButton: false,
                                allowEscapeKey: false,
                                allowOutsideClick: false,
                                timer: 2800
                            });
                            setTimeout(() => {
                                window.location.reload();
                            }, 3000);
                        }
                    },
                    error: function (xhr, status, error) {
                        loading.close();
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something went wrong!',
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                        });
                    },
                });

            }
        });
    });

});
