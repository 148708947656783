$(document).ready(function () {

    var invalid_count = {};

    if($('input[name="agency_name"').length > 0){
        // console.log('load');
        $('input[name="agency_name"').on("keyup change", function(e){
            let val = $(this).val();
            //if(val.match(/[^a-z,^A-Z,^0-9 ,\.]/) != null){
            if(val.match(/[^a-z,^A-Z,^0-9 ,(),-\.]/) != null){
                $(this)[0].setCustomValidity('Invalid symbols except comma . , - ( )');
            }
            else{
                $(this)[0].setCustomValidity("");
            }
        });
    }

    $(document).on('change', '#register_agency_id', function () {
        if ($(this).val() == "new") {
            $('.create_agency').show();
            $('#agency_name').prop('required', true);
            $('#agency_type').prop('required', true);
            $('#ministry_id').prop('checked', true);
        } else {
            $('.create_agency').hide();
            $('#agency_name').prop('required', false);
            $('#agency_type').prop('required', false);
            $('#ministry_id').prop('checked', false);
        }
    });

    $(document).on('click', '.register', function () {
        validation();
    });

    $(document).on('change', '.register_role', function () {
        if($(this).val() == 'supervisor'){
            if($('#register_agency_id option[value="new"]').length == 0){
                $('#register_agency_id option:eq(0)').after('<option value="new">&#10133; New Ministry/Agency</option>');
            }
        } else if($(this).val() == 'webmaster'){
            if($('#register_agency_id option[value="new"]').length > 0){
                $('#register_agency_id option[value="new"]').remove();
            }
        }
    });

    $(document).on('click','#isMinistry', function(){
        if ($(this).is(":checked")) {
            $('#not-ministry').hide();
            $('.not-ministry').prop('required', false);
        } else {
            $('#not-ministry').show();
            $('.not-ministry').prop('required', true);
        }
    });

    $(document).on('input', '#tel_no', function(){
        let val = $(this).val();
        if (val.length > 0) {
            if ($(this).val().charAt(0) != '0') {
                invalid_count['tel_no_valid'] = 1;
                $('.validate_tel_no_msg2').html('Invalid Tel No.');
                //validation();
                return 0 ;
            }
        }
        if (val.length < 8 || val.length > 11) {
            // $('[type="submit"]').attr('disabled', true);
            invalid_count['tel_no_valid'] = 1;
            $('.validate_tel_no_msg2').html('Invalid Tel No.');
            //validation();
            return 0 ;
        } else {
            // $('[type="submit"]').attr('disabled', false);
            invalid_count['tel_no_valid'] = 2;
            $('.validate_tel_no_msg2').html(' ');
        }
        //validation();
    });

    $(document).on('input change', 'input, select', function(){
        if ($('#PublicRegisterForm').length > 0) {
            if ($(this).attr('required')) {
                if ($(this).val()) {
                    $('.validate_'+$(this).attr('name')+'_msg').html('');
                    invalid_count[$(this).attr('name')] = 2;
                    validationBtn()
                } else {
                    $('.validate_'+$(this).attr('name')+'_msg').html($(this).data('label')+' field is required');
                        invalid_count[$(this).attr('name')] = 1;
                        $('[type="submit"]').attr('disabled', true);
                }
            }
        }
    });

    // $(document).on('mouseover', '#PublicRegisterForm', function(){
    //     validation();
    // });

    if ($('#PublicRegisterForm').length > 0) {
        $('[data-toggle="tooltip"]').tooltip()
    }

    $(document).on('input', '#captcha', function(){
        if ($('#PublicRegisterForm').length > 0) {
            validation();
        }
    });


    var x = 0;
    function validation(){
        if ($('#PublicRegisterForm').length > 0) {
            // for (let index = 1; index <= 3; index++) {
                x = 0
                if (invalid_count['email_taken'] == 1) {
                    x = 1;
                }
                //     x = 1;
                // }
                $( '#PublicRegisterForm select' ).each(function( ) {
                    if ($(this).attr('required')) {
                        if ($(this).val()) {
                            $('.validate_'+$(this).attr('name')+'_msg').html('');
                        } else {
                            $('.validate_'+$(this).attr('name')+'_msg').html($(this).data('label')+' field is required');
                            $('.pagebtn-').prop('disabled', true);
                            x++;
                        }
                    }
                });

                $('#PublicRegisterForm input' ).each(function( ) {
                    if ($(this).attr('required')) {
                        if ($(this).attr('type') != 'radio') {

                            if ($(this).val()) {
                                $('.validate_'+$(this).attr('name')+'_msg').html('');
                            } else {
                                $('.validate_'+$(this).attr('name')+'_msg').html($(this).data('label')+' field is required');
                                x++;
                            }
                        }

                        if ($(this).attr('type') == 'password')  {
                            passwordValidation($('[name="password"]').val());
                            // passwordValidation($('[name="password_confirmation"]').val());
                        }
                    }

                    if ($(this).attr('type') == 'radio') {
                        if ($('[name="'+$(this).attr('name')+'"]').is(":checked")) {
                            $('.validate_'+$(this).attr('name')+'_msg').html('');
                        } else {
                            $('.validate_'+$(this).attr('name')+'_msg').html($(this).data('label')+' field is required');
                            x++;
                        }
                    }
                });

                if($('.validate_email').hasClass('custom-validate')) {
                    if($('.validate_email').val() != ''){
                        let validation = String($('.validate_email').val())
                                                .toLowerCase()
                                                .match(
                                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                                );
                        if(validation){
                            invalid_count['email'] = 2;
                            $('.email_validation').html("");
                        } else {
                            invalid_count['email'] = 1;
                            $('.email_validation').html("Invalid email address.");
                        }
                    }
                    else{
                        invalid_count['email'] = 2;
                        $('.email_validation').html("");
                    }
                }

                btndisable(x);
            // }
        }
    }
    function validationBtn(){
        if ($('#PublicRegisterForm').length > 0) {
            // for (let index = 1; index <= 3; index++) {
                x = 0
                if (invalid_count['email_taken'] == 1) {
                    x = 1;
                }
                //     x = 1;
                // }
                $( '#PublicRegisterForm select' ).each(function( ) {
                    if ($(this).attr('required')) {
                        if ($(this).val()) {
                            // $('.validate_'+$(this).attr('name')+'_msg').html('');
                        } else {
                            // $('.validate_'+$(this).attr('name')+'_msg').html($(this).data('label')+' field is required');
                            // $('.pagebtn-').prop('disabled', true);
                            x++;
                        }
                    }
                });

                $('#PublicRegisterForm input' ).each(function( ) {
                    if ($(this).attr('required')) {
                        if ($(this).attr('type') != 'radio') {

                            if ($(this).val()) {
                                // $('.validate_'+$(this).attr('name')+'_msg').html('');
                            } else {
                                // $('.validate_'+$(this).attr('name')+'_msg').html($(this).data('label')+' field is required');
                                x++;
                            }
                        }

                        if ($(this).attr('type') == 'password')  {
                            passwordValidation($('[name="password"]').val());
                        }
                    }

                    if ($(this).attr('type') == 'radio') {
                        if ($('[name="'+$(this).attr('name')+'"]').is(":checked")) {
                            // $('.validate_'+$(this).attr('name')+'_msg').html('');
                        } else {
                            // $('.validate_'+$(this).attr('name')+'_msg').html($(this).data('label')+' field is required');
                            x++;
                        }
                    }
                });

                if($('.validate_email').hasClass('custom-validate')) {
                    if($('.validate_email').val() != ''){
                        let validation = String($('.validate_email').val())
                                                .toLowerCase()
                                                .match(
                                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                                );
                        if(validation){
                            invalid_count['email'] = 2;
                            // $('.email_validation').html("");
                        } else {
                            invalid_count['email'] = 1;
                            // $('.email_validation').html("Invalid email address.");
                        }
                    }
                    else{
                        invalid_count['email'] = 2;
                    }
                }

                btndisable(x);
            // }
        }
    }

    function btndisable(x){
        for (const [key, element] of Object.entries(invalid_count)) {
            // console.log(key + ' = ' + element);
            if (element == 1) {
                x++;
            }
        }
        // console.log('x = ' + x);
        if (x == 0) {
            $('[type="submit"]').attr('disabled', false);
        } else {
            $('[type="submit"]').attr('disabled', true);
        }
    }

    $(document).on('input', '[type="email"]', function () {
        if ($('#PublicRegisterForm').length > 0) {
            if($('.validate_email').hasClass('custom-validate')) {
                if($('.validate_email').val() != ''){

                    let validation = String($('.validate_email').val())
                                            .toLowerCase()
                                            .match(
                                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                            );
                    if(validation){
                        invalid_count['email'] = 2;
                        $('.email_validation').html("");
                    } else {
                        invalid_count['email'] = 1;
                        $('.email_validation').html("Invalid email address.");
                    }
                }
                else{
                    // console.log('this');
                    invalid_count['email'] = 2;
                    $('.email_validation').html("");
                    $('.validate_email_msg').html("");
                    $('.validate_email_msg2').html("");
                    $('.validate_email_msg').html("");

                }
            }
        }
    });

    function passwordValidation(val)
    {
        var no = 0;
        if (val != "") {
            // If the password length is less than or equal to 6
            if (val.length <= 7) no = 1;

            // If the password length is greater than 6 and contain any lowercase alphabet or any number or any special character
            if (val.length > 7 && (val.match(/[a-z]/) && val.match(/[A-Z]/) && val.match(/\d+/) || val.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/))) no = 2;

            // If the password length is greater than 6 and contain alphabet,number,special character respectively
            if (val.length > 7 && ((val.match(/[a-z]/) && val.match(/[A-Z]/) && val.match(/\d+/)) || (val.match(/\d+/) && val.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)) || (val.match(/[a-z]/) && val.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)))) no = 3;

            // If the password length is greater than 6 and must contain alphabets,numbers and special characters
            if (val.length > 7 && val.match(/[a-z]/) && val.match(/[A-Z]/) && val.match(/\d+/) && val.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)) no = 4;

            if (no == 1) {
                $('#password_invalid').removeClass();
                if ($('#PublicRegisterForm').length > 0 || $('#ResetPasswordForm').length > 0) {
                    $('#password_invalid').addClass('text-danger');
                } else {
                    $('#password_invalid').addClass('red-text font-weight-bold');
                }
                $('#password_invalid').html("Very Weak.");
                btndisable(x);
                if ($('#EditMyProfileForm').length > 0) {
                    $('#ResetPasswordbtn').attr('disabled', true);
                } else {
                    // $('[type="submit"]').attr('disabled', true);
                    btndisable(x);
                }
                invalid_count['password'] = 1;
                // $('.next-step').attr('disabled', true);
            }

            if (no == 2) {
                $('#password_invalid').removeClass();
                if ($('#PublicRegisterForm').length > 0 || $('#ResetPasswordForm').length > 0) {
                    $('#password_invalid').addClass('text-danger');
                } else {
                    $('#password_invalid').addClass('red-text font-weight-bold');
                }
                $('#password_invalid').html("Weak.");
                btndisable(x);
                if ($('#EditMyProfileForm').length > 0) {
                    $('#ResetPasswordbtn').attr('disabled', true);
                } else {
                    // $('[type="submit"]').attr('disabled', true);
                    btndisable(x);
                }
                invalid_count['password'] = 1;
                // $('.next-step').attr('disabled', true);
            }
            if (no == 3) {
                $('#password_invalid').removeClass();
                if ($('#PublicRegisterForm').length > 0 || $('#ResetPasswordForm').length > 0) {
                    $('#password_invalid').addClass('text-warning');
                } else {
                    $('#password_invalid').addClass('orange-text  font-weight-bold');
                }
                $('#password_invalid').html("Medium");
                if ($('#EditMyProfileForm').length > 0) {
                    $('#ResetPasswordbtn').attr('disabled', false);
                } else {
                    // $('[type="submit"]').attr('disabled', false);
                    btndisable(x);
                }

                invalid_count['password'] = 2;
                // $('.next-step').attr('disabled', false);
            }

            if (no == 4) {
                $('#password_invalid').removeClass();
                if ($('#PublicRegisterForm').length > 0 || $('#ResetPasswordForm').length > 0) {
                    $('#password_invalid').addClass('text-success');
                } else {
                    $('#password_invalid').addClass('green-text  font-weight-bold');
                }
                $('#password_invalid').html("Strong");
                if ($('#EditMyProfileForm').length > 0) {
                    $('#ResetPasswordbtn').attr('disabled', false);
                } else {
                    // $('[type="submit"]').attr('disabled', false);
                    btndisable(x);
                }
                invalid_count['password'] = 2;
            }
        } else {
            $('#password_invalid').html("");
        }

        if(!$('[name="password_confirmation"]').val()){
            if ($('#EditMyProfileForm').length > 0) {
                $('#ResetPasswordbtn').attr('disabled', true);
            } else {
                // $('[type="submit"]').attr('disabled', true);
                btndisable(x);
            }
            invalid_count['password'] = 1;
        }
    }

    $(document).on('input', '#password', function () {
            passwordValidation($(this).val());
    });

    $(document).on('input', '[name="password_confirmation"], [name="password"]', function () {
        if($('[name="password_confirmation"]').val()){
            if($('[name="password_confirmation"]').val() == $('[name="password"]').val()){
                $('#password_confirmation_msg').html('');
                invalid_count['password_confirmation2'] = 2;
                if ($('#EditMyProfileForm').length > 0) {
                    $('#ResetPasswordbtn').attr('disabled', false);
                } else {
                    btndisable(x);
                    // $('[type="submit"]').attr('disabled', false);
                }
                passwordValidation($('[name="password"]').val());
                // $('.next-step').attr('disabled', false);
            } else {
                $('#password_confirmation_msg').html("Password confirmation does not match.");
                invalid_count['password_confirmation2'] = 1;
                if ($('#EditMyProfileForm').length > 0) {
                    $('#ResetPasswordbtn').attr('disabled', true);
                } else {
                    btndisable(x);
                }
                // invalid_count['password'] = 1;
            }
            passwordValidation($('[name="password"]').val());
        } else {
            if($('#LoginPage').length == 0) {
                $('#password_confirmation_msg').html('');
                if ($('#EditMyProfileForm').length > 0) {
                    $('#ResetPasswordbtn').attr('disabled', false);
                } else {
                    btndisable(x);
                    // $('[type="submit"]').attr('disabled', false);
                }
                invalid_count['password'] = 2;
                passwordValidation($('[name="password"]').val());
                // $('.next-step').attr('disabled', false);
            }
        }
    });


    $(document).on('change', '.validate_email', function () {
        email_check($(this).val());
    });

    function email_check(email)
    {
        if(email){
            $.ajax({
                type: 'POST',
                url: '/email_validation',
                data: {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                    email: email,
                },
                success: function (result) {
                    if (result) {
                        btndisable(x);
                        invalid_count['email_taken'] = 2;
                        $('.validate_email_msg2').html('');
                    } else {
                        btndisable(x);
                        invalid_count['email_taken'] = 1;
                        $('.validate_email_msg2').html('The email has already been taken.');
                    }
                },
            });
        } else {
            btndisable(x);
            $('.validate_email_msg2').html('');
        }
    }


});
