const moment = require("moment");

$(document).ready(function () {


    if($(".js-compliance-score").length > 0){
        $(".js-compliance-score").on("click", function(e){
            e.preventDefault();
            var $this = $(this);
            var route = $this.data("route");
            var ministry_id = $('#filter-analitic-ministry').select2().find(":selected").data("token");
            var agency_id = $('#filter-analitic-agency').select2().find(":selected").data("token");
            if(typeof ministry_id == 'undefined'){
                ministry_id = '';
            }
            if(typeof agency_id == 'undefined'){
                agency_id = '';
            }
            var url = route + "?ministry_id=" + ministry_id + "&agency_id=" + agency_id;
            // console.log(url);
            window.location.href = url;
        });
    }
    // var ctx = document.getElementById('barchart_compliance_scoring').getContext('2d');
    var ctx = $("#barchart_compliance_scoring");

    // Chart Options
    var chartOptions = {
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            }
        },
        // elements: {
        //     rectangle: {
        //         borderWidth: 2,
        //         // borderColor: "rgb(0, 255, 0)",
        //         borderSkipped: "left"
        //     }
        // },
        responsive: true,
        maintainAspectRatio: true,
        responsiveAnimationDuration: 500,
        legend: {
            position: "top"
        },
        scales: {
            xAxes: [{
                display: true,
                gridLines: {
                    color: "#f3f3f3",
                    drawTicks: false
                },
                scaleLabel: {
                    display: true
                },
                ticks: {
                    padding: 10,
                }
            }],
            yAxes: [{
                display: true,
                ticks: {
                    padding: 10,
                    max: 100,
                    min: 0,
                    stepSize: 10,
                    beginAtZero: true,
                    callback: function(value) {if (value % 1   === 0) {return value;}}
                },
                gridLines: {
                    color: "#f3f3f3",
                    drawTicks: false
                },
                scaleLabel: {
                    display: true
                }
            }]
        },
        // title: {
        //     display: false,
        //     text: "Chart.js Horizontal Bar Chart"
        // },
        plugins: {
            labels: false
        }
    };


    var isRendered = false;
    var barchart_compliance_scoring = null;
    window.render_barchart_compliance_scoring = function(){
        $.ajax({
            type: 'POST',
            cache: true,
            url: '/dashboard/barchart_compliance_scoring',
            data: {
                _token:         $('meta[name="csrf-token"]').attr('content'),
                ministry_id:    ($("#main").length > 0) ? $('#filter-analitic-ministry').val() : '',
                agency_id:      ($("#main").length > 0) ? $('#filter-analitic-agency').val() : '',
            },
            success: function (result) {
                // console.log('results', result);
                if (result) {

                    // console.log(result);
                    // Chart Data

                    var dataset = {};
                    if($("#guestonly").length > 0){
                        dataset = [{
                                        label: "Dimension Rating",
                                        data: result.chart.datas,
                                        backgroundColor: "#00bcd4",
                                        hoverBackgroundColor: "#00acc1",
                                        borderColor: "transparent"
                                    }];
                    }else{
                        dataset = [{
                                label: "Dimension Rating",
                                data: result.chart.datas,
                                backgroundColor: "#00bcd4",
                                hoverBackgroundColor: "#00acc1",
                                borderColor: "transparent"
                            },
                            {
                                label: 'National Average',
                                borderWidth: 1,
                                data: result.overall.datas
                            }];
                    }

                    var chartData = {
                        labels: result.chart.label,
                        datasets: dataset
                    };

                    var config = {
                        type: "bar",
                        // Chart Options
                        options: chartOptions,
                        data: chartData
                    };
                    console.log('isrendered', isRendered);

                    // Create the chart
                    if ($("#barchart_compliance_scoring").length > 0) {

                        if (isRendered) {
                            // console.log('rendered');
                            barchart_compliance_scoring.data.labels = result.overall.label;
                            if($('#filter-analitic-ministry').val() != "" && $('#filter-analitic-agency').val() == ""){
                                barchart_compliance_scoring.data.datasets[0].data = result.chart.datas_ministry;
                                barchart_compliance_scoring.data.labels = result.overall.label;
                                if(result.chart.datas_ministry){
                                    if (result.chart.datas_ministry.every( (val, i, arr) => val === arr[0] )) {
                                        //$('.barchart_compliance_scoring').hide();
                                    } else {
                                        //$('.barchart_compliance_scoring').show();
                                    }
                                    barchart_compliance_scoring.update();
                                }
                                else{
                                    //$('.barchart_compliance_scoring').hide();
                                }

                                if(result.chart.total_ministry){
                                    // console.log('result1',result.chart.total_ministry);
                                    $('#barchart_compliance_scoring_total_agency').text(result.chart.total_ministry);
                                    $('#linechart_average_loading_time_monthly_total_agency').text(result.chart.total_ministry);
                                }
                                else{
                                    // console.log('result2',result.chart);
                                    $('#barchart_compliance_scoring_total_agency').text(0);
                                }
                                // console.log('ministry');
                            }
                            if($('#filter-analitic-agency').val() != "" && $('#filter-analitic-ministry').val() != ""){
                                barchart_compliance_scoring.data.datasets[0].data = result.chart.datas_agency;
                                barchart_compliance_scoring.data.labels = result.overall.label;
                                if(result.chart.datas_agency){
                                    if (result.chart.datas_agency.every( (val, i, arr) => val === arr[0] )) {
                                        //$('.barchart_compliance_scoring').hide();
                                    } else {
                                        //$('.barchart_compliance_scoring').show();
                                    }
                                    barchart_compliance_scoring.update();
                                }
                                else{
                                    //$('.barchart_compliance_scoring').hide();
                                }
                                if(result.chart.total_agency_agency){
                                    $('#barchart_compliance_scoring_total_agency').text(result.chart.total_agency_agency);
                                    $('#linechart_average_loading_time_monthly_total_agency').text(result.chart.total_agency_agency);
                                }
                                else{
                                    $('#barchart_compliance_scoring_total_agency').text(0);
                                }
                                // console.log('agency');
                            }
                            if($('#filter-analitic-agency').val() != "" && $('#filter-analitic-ministry').val() == ""){
                                barchart_compliance_scoring.data.datasets[0].data = result.chart.datas_agency;
                                barchart_compliance_scoring.data.labels = result.overall.label;
                                if(result.chart.datas_agency){
                                    if (result.chart.datas_agency.every( (val, i, arr) => val === arr[0] )) {
                                        //$('.barchart_compliance_scoring').hide();
                                    } else {
                                        //$('.barchart_compliance_scoring').show();
                                    }
                                    barchart_compliance_scoring.update();
                                }
                                else{
                                    //$('.barchart_compliance_scoring').hide();
                                }
                                if(result.chart.total_agency_agency){
                                    $('#barchart_compliance_scoring_total_agency').text(result.chart.total_agency_agency);
                                    $('#linechart_average_loading_time_monthly_total_agency').text(result.chart.total_agency_agency);
                                }
                                else{
                                    $('#barchart_compliance_scoring_total_agency').text(0);
                                }
                                // console.log('agency 2');
                            }
                            if($('#filter-analitic-ministry').val() === "" && $('#filter-analitic-agency').val() === ""){
                                barchart_compliance_scoring.data.datasets[0].data = result.chart.datas;
                                barchart_compliance_scoring.data.labels = result.overall.label;
                                if(result.chart.datas){
                                    if (result.chart.datas.every( (val, i, arr) => val === arr[0] )) {
                                        //$('.barchart_compliance_scoring').hide();
                                    } else {
                                        //$('.barchart_compliance_scoring').show();
                                    }
                                    barchart_compliance_scoring.update();
                                }
                                else{
                                    //$('.barchart_compliance_scoring').hide();
                                }
                                if(result.chart.total_agency){
                                    $('#barchart_compliance_scoring_total_agency').text(result.chart.total_agency);
                                    $('#linechart_average_loading_time_monthly_total_agency').text(result.chart.total_agency);

                                }
                                else{
                                    $('#barchart_compliance_scoring_total_agency').text(0);
                                }
                                // console.log('total');

                            }
                            // barchart_compliance_scoring.data.datasets[0].data = result.chart.datas;
                            // barchart_compliance_scoring.data.labels = result.chart.lables;

                        } else {
                            barchart_compliance_scoring = new Chart(ctx, config);
                            if($("#main").length > 0){
                                isRendered = !isRendered;
                            }
                            else{
                                isRendered = false;
                            }
                        }
                    }
                    if (result.date) {
                        $('#barchart_compliance_scoring_date').text(moment(result.date).format('DD/MM/YYYY'));
                    } else {
                        $('#barchart_compliance_scoring_date').text(moment().format('DD/MM/YYYY'));
                    }

                    if($('#filter-analitic-ministry').length > 0 && $('#filter-analitic-ministry').val() === "" && $('#filter-analitic-agency').val() === ""){
                        if(result.chart.total_agency){
                            $('#barchart_compliance_scoring_total_agency').text(result.chart.total_agency);
                            $('#linechart_average_loading_time_monthly_total_agency').text(result.chart.total_agency);
                        }
                        else{
                            $('#barchart_compliance_scoring_total_agency').text(0);
                        }
                    }
                    else{
                        if(result.chart.total_agency){
                            $('#barchart_compliance_scoring_total_agency').text(result.chart.total_agency);
                            $('#linechart_average_loading_time_monthly_total_agency').text(result.chart.total_agency);
                        }
                    }


                    //set the compliance score
                    setTimeout(() => {
                        const total = new Array();
                        const complience_score_ = new Array();
                        if($('#filter-analitic-ministry').val() != "" && $('#filter-analitic-agency').val() == ""){
                            $.each(result.chart.data_extra_ministry, function (index, value) {
                                if($("#"+index).length > 0){
                                    $("#"+index).text(value);
                                }
                            })
                            $.each(result.chart.datas_ministry, function (index, value) {
                                if($("#dimension"+index).length > 0){
                                    $("#dimension"+index).text(value);
                                    total.push(value);
                                    // console.log('test1');
                                }
                                complience_score_.push(value);
                            });
                        }
                        if($('#filter-analitic-agency').val() != "" && $('#filter-analitic-ministry').val() != ""){
                            $.each(result.chart.data_extra_agency, function (index, value) {
                                if($("#"+index).length > 0){
                                    $("#"+index).text(value);
                                }
                            });

                            $.each(result.chart.datas_agency, function (index, value) {
                                if($("#dimension"+index).length > 0){
                                    $("#dimension"+index).text(value);
                                    total.push(value);
                                    // console.log('test2',result);
                                }
                                complience_score_.push(value);
                            });
                        }
                        if($('#filter-analitic-agency').val() != "" && $('#filter-analitic-ministry').val() == ""){
                            $.each(result.chart.data_extra_agency, function (index, value) {
                                if($("#"+index).length > 0){
                                    $("#"+index).text(value);
                                }
                            });

                            $.each(result.chart.datas_agency, function (index, value) {
                                if($("#dimension"+index).length > 0){
                                    $("#dimension"+index).text(value);
                                    total.push(value);
                                    // console.log('test3');
                                }
                                complience_score_.push(value);
                            });
                        }
                        if($('#filter-analitic-ministry').val() === "" && $('#filter-analitic-agency').val() === ""){
                            $.each(result.chart.data_extra, function (index, value) {
                                if($("#"+index).length > 0){
                                    $("#"+index).text(value);
                                }
                            });

                            $.each(result.chart.datas, function (index, value) {
                                if($("#dimension"+index).length > 0){
                                    $("#dimension"+index).text(value);
                                    total.push(value);
                                    // console.log('test4');
                                }
                                complience_score_.push(value);
                            });
                        }
                        $.each(result.chart.data_extra, function (index, value) {
                            if($("#"+index).length > 0){
                                $("#"+index).text(value);
                            }
                        });
                        // console.log(result.chart.datas);
                        $.each(result.chart.datas, function (index, value) {
                            if($("#dimension"+index).length > 0){
                                $("#dimension"+index).text(value);
                                total.push(value);
                                // console.log('test5');
                            }
                            complience_score_.push(value);
                        });
                        if(total.length > 0){
                            // sum = total.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
                            var complience_score = 0;
                            for(i=0;i<6;i++){
                                complience_score += parseFloat(total[i]);
                            }
                            sum = ((complience_score / 6)).toFixed(2);
                            if($("#compliance_mark").length > 0){
                                if(sum > 0){
                                    $("#compliance_mark").text(sum + '%');
                                }
                                else{
                                    $("#compliance_mark").text('0.00%');
                                }
                            }
                        }
                        else{
                            $("#compliance_mark").text('0.00%');
                        }
                        if(complience_score_.length > 0){
                            // sum = total.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
                            var complience_score = 0;
                            for(i=0;i<6;i++){
                                complience_score += parseFloat(complience_score_[i]);
                            }
                            sum = ((complience_score / 6)).toFixed(2);
                            if($("#compliance_mark").length > 0){
                                if(sum > 0){
                                    $("#compliance_mark").text(sum + '%');
                                }
                                else{
                                    $("#compliance_mark").text('0.00%');
                                }
                            }
                        }
                        else{
                            $("#compliance_mark").text('0.00%');
                        }
                    }, 1000);

                }
            },
        });
    }
    if($("#compliance_details").length > 0){
        setTimeout(() => {
            window.render_barchart_compliance_scoring();
        }, 500);
    }
    if ($("#barchart_compliance_scoring").length > 0) {
        if($('meta[name="splask-login"]').length == 0){
            window.render_barchart_compliance_scoring();
        }
    }
    $(document).on('change', '.dashboard_changes', function () {
        window.render_barchart_compliance_scoring();
    });


});
