$(document).ready(function () {

    // var ctx = document.getElementById('myChart').getContext('2d');
    var ctx = $("#barcharts_top_web");

    // Chart Options
    var chartOptions = {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: true,
        responsiveAnimationDuration: 500,
        title: {
            display: false,
        },
        legend: {
            display: false
        },
        plugins: {
            labels: false
        },
        events: ["click"],
        scales: {
            yAxes: [{
                ticks: {
                    callback: function(value) {
                        if(value.length > 32){
                            return value.substr(0, 28) + '...';
                        }
                        else{
                            return value.substr(0, 32);//truncate
                        }
                    },
                }
            }]
        },
        // tooltips: {
        //     enabled: true,
        //     mode: 'label',
        //     callbacks: {
        //         title: function(tooltipItems, data) {
        //             var idx = tooltipItems[0].index;
        //             return data.labels[idx];
        //         },
        //         label: function(tooltipItems, data) {
        //             return data.labeltooltips[tooltipItems.index].split(";");
        //         }
        //     }
        // },
        tooltips: {
            // Disable the on-canvas tooltip
            enabled: false,
        custom: function(tooltipModel) {
            // Tooltip Element
            // console.log('ssss', sessionStorage.getItem("barcharts_hall_of_fame"));
            // console.log(tooltipModel);
            var tooltipEl = document.getElementById('chartjs-tooltip');

            // Create element on first render
            if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.innerHTML = '<table></table>';
                tooltipEl.style.backgroundColor = "#000000";
                tooltipEl.style.borderColor = "#000000";
                tooltipEl.style.borderWidth = "thin";
                tooltipEl.style.borderStyle = "solid";
                tooltipEl.style.height = "250px";
                tooltipEl.style.maxHeight = "500px";
                tooltipEl.style.overflowY = "scroll"
                document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
            }

            // Set caret Position
            tooltipEl.classList.remove('above', 'below', 'no-transform');
            if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
                tooltipEl.classList.add('no-transform');
            }

            function getBody(bodyItem) {
                return bodyItem.lines;
            }

            // Set Text
            if (tooltipModel.body) {
                var titleLines = tooltipModel.title || [];
                dataIndex = tooltipModel.dataPoints[0].index;
                jsonData = JSON.parse(sessionStorage.getItem("barcharts_top_web"))[dataIndex];

                // console.log('json',jsonData);
                var bodyLines = tooltipModel.body.map(getBody);

                var innerHtml = '<thead>';

                titleLines.forEach(function(title) {
                    innerHtml += '<tr><th style="font-size:16px;">' + title + '</th></tr>';
                });
                innerHtml += '</thead><tbody>';
                // jsonData.forEach(function(body, i) {
                    // console.log('body',body,i);
                    var child = jsonData.split(";");
                    // console.log('body-child',child);

                    var i = '';
                    child.forEach(function(body2, i2){
                    // console.log('body-2',body2);

                        // var colors = tooltipModel.labelColors[i];
                        var style = '';
                        // style += '; border-color:' + colors.borderColor;
                        style += 'border-width: 2px';
                        var span = '<span style="' + style + '">&nbsp;&nbsp;</span>';
                        innerHtml += '<tr><td>' + span + body2 + '</td></tr>';
                    });

                // });
                innerHtml += '</tbody>';

                var tableRoot = tooltipEl.querySelector('table');
                tableRoot.innerHTML = innerHtml;
            }

            // `this` will be the overall tooltip
            var position = this._chart.canvas.getBoundingClientRect();

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 0.8;
            tooltipEl.style.color = "#FFFFFF";
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
            tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
            // tooltipEl.style.pointerEvents = 'none';
        }
    },
        // tooltips: {
        //     callbacks: {
        //         label: function(tooltipItem, data) {
        //             // return 'Score : ' + tooltipItem.value + '%';
        //             return data.labeltooltips[tooltipItem.index].split(";");
        //         }
        //     }
        // },
        // yAxes: [{
        //     display: true,
        //     ticks: {
        //         beginAtZero: true,
        //         callback: function(value) {if (value % 1   === 0) {return value;}}
        //     }
        // }]
    };

    // Chart Data

        var isRendered = false;
        var barcharts_top_web = null;
        window.render_barcharts_top_web = function(){
            $.ajax({
                type: 'POST',
                cache: true,
                url: '/dashboard/barcharts_top_web',
                data: {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                },
                success: function (result) {
                    if (result) {
                        sessionStorage.setItem("barcharts_top_web", JSON.stringify(result.chart.tooltips));
                        var chartData = {
                            labels: result.chart.label,
                            labeltooltips: result.chart.tooltips,
                            datasets: [{
                                axis: 'y',
                                label: "agency",
                                data: result.chart.datas,
                                backgroundColor: [
                                    'rgb(255, 99, 132)',
                                    'rgb(75, 192, 192)',
                                    'rgb(255, 205, 86)',
                                    'rgb(201, 203, 207)',
                                    'rgb(54, 162, 235)',
                                    'rgb(75, 192, 192)',
                                    'rgb(255, 205, 86)',
                                    'rgb(201, 203, 207)',
                                    'rgb(54, 162, 235)',
                                    'rgb(75, 192, 192)',
                                    'rgb(255, 205, 86)',
                                    'rgb(201, 203, 207)',
                                    'rgb(54, 162, 235)',
                                    'rgb(75, 192, 192)',
                                    'rgb(255, 205, 86)',
                                    'rgb(201, 203, 207)',
                                    'rgb(54, 162, 235)'
                                ],
                                hoverBackgroundColor: "#00acc1",
                                borderColor: "transparent"
                            }, ]
                        };

                        var config = {
                            // type: "bar",
                            type: 'horizontalBar',
                            // Chart Options
                            options: chartOptions,

                            data: chartData
                        };

                        // Create the chart
                        if (isRendered) {
                            barcharts_top_web.data.datasets[0].data = result.chart.datas;
                            barcharts_top_web.data.labels = result.chart.label;
                            barcharts_top_web.update();
                        } else {
                            barcharts_top_web = new Chart(ctx, config);
                            isRendered = !isRendered;
                        }

                        if (result.date) {
                            $('#barcharts_top_web_date').text(moment(result.chart.date_reported).format('DD/MM/YYYY'));
                        } else {
                            $('#barcharts_top_web_date').text(moment().format('DD/MM/YYYY'));
                        }

                        if (result.chart.datas.every( (val, i, arr) => val === arr[0] )) {
                            $('.barcharts_top_web').hide();
                        } else {
                            $('.barcharts_top_web').show();
                        }
                    }
                },
            });
        }
        if ($("#barcharts_top_web").length > 0 && $('meta[name="splask-login"]').length == 0) {
            window.render_barcharts_top_web();
        }



});
