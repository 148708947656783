$(document).ready(function () {

    var is_desktop = false;
    if ($('#is_desktop').length > 0) {
        is_desktop = $('#is_desktop').data('value') ? false : true;
    }

    if ($('#UsersTable').length > 0) {
        var length = Cookies.get('UsersTable_length') ? Cookies.get('UsersTable_length') : 25;
        var UsersTable = $('#UsersTable').DataTable({
            responsive: is_desktop,
            processing: true,
            serverSide: true,
            ajax: {
                "url": "/settings/users/ajax",
                "type": "POST",
                "data": function (d) {
                    d._token = $('meta[name="csrf-token"]').attr('content');
                    d.name_filter = $('#filter-name').val();
                    d.filter_status = $("#filter-status").val();
                    d.filter_user_role = $("#filter-user_role").val();
                    d.filter_ministry = $("#filter-minitry").val();
                    d.filter_type = $("#filter-type").val();
                },
            },
            columns: [{
                    data: 'DT_RowIndex',
                    name: 'DT_RowIndex',
                    orderable: false,
                    searchable: false,
                    className: "text-center",
                },
                {
                    data: 'name',
                    name: 'name',
                    render: function (data) {
                        return data.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
                    }
                },
                {
                    data: 'email',
                    name: 'email',
                },
                {
                    data: 'ministry_name',
                    name: 'ministry_name',
                    render: function (data, type, row) {
                        var agent = '-';
                        if (data) {
                            if (row.agency_name) {
                                agent = row.agency_name + '<br><small>' + data + '</small>';
                            } else {
                                agent = data;
                            }
                        }
                        return agent;
                    }
                },
                // {
                //     data: 'ministry',
                //     name: 'ministry',
                // },
                {
                    data: 'user_role',
                    name: 'user_role',
                    className: "text-left",
                    render: function (data) {
                        // let badge ={
                        //     'administrator' : '<div class="tooltip"><i class="fas fa-2x fa-user-cog"></i><span class="tooltiptext">Role: Administrator</span></div>',
                        //     'supervisor'    : '<div class="tooltip"><i class="fas fa-2x fa-user-shield"></i><span class="tooltiptext">Role: Supervisor</span></div>',
                        //     'webmaster'     : '<div class="tooltip"><i class="fa  fa-2x fa-user-tag"></i><span class="tooltiptext">Role: Webmaster</span></div>'
                        // };

                        let badge2 ={
                            'administrator' : '<img style="width: 1.6rem" src="/images/icons/user-crown.png" class="tooltipped" data-position="left" data-tooltip="Role: Administrator" >',
                            'supervisor'    : '<img style="width: 1.6rem" src="/images/icons/avatar.png" class="tooltipped" data-position="left" data-tooltip="Role: Supervisor" >',
                            'webmaster'     : '<img style="width: 1.6rem" src="/images/icons/user-vneck.png" class="tooltipped" data-position="left" data-tooltip="Role: Webmaster">'
                        };
                        return badge2[data];
                    }
                },
                {
                    data: 'status',
                    name: 'status',
                    className: "text-center",
                    render: function (data) {
                        if (data == 1) {
                            return data == 1 ? '<div class="tooltip" style="z-index:5;"><i class="material-icons green-text">radio_button_checked</i><span class="tooltiptext">Active</span></div>' : '';
                        }
                        else{
                            return '<div class="tooltip" style="z-index:5;"><i class="material-icons red-text">radio_button_checked</i><span class="tooltiptext">Inactive</span></div>';
                        }
                        // return data == 1 ? '<span class="chip green lighten-5"><span class="green-text">Active</span></span>' : '<span class="chip red lighten-5"><span class="red-text">Inactive</span></span>';
                    }
                },
                {
                    data: "user_id",
                    name: 'user_id',
                    orderable: false,
                    className: "text-center",
                    render: function (data, type, row) {
                        if (row.user_id != null) {
                            let disabled = $('#auth_user_id').data('value') == row.id ? 'disabled' : '';
                            let disabledsupervisor = '';
                            if(!row.isEditable){
                                disabledsupervisor = 'disabled';
                            }
                            return type === 'display' ?
                                '<a data-id="' + data + '" class="mb-6 btn-floating btn-small btn-small waves-effect waves-light indigo gradient-shadow mr-3 settingUserEdit tooltipped" data-position="left" data-tooltip="Edit" '+disabledsupervisor+'><i class="material-icons">edit</i></a>' +
                                '<a data-id="' + data + '" class="mb-6 btn-floating btn-small btn-small waves-effect waves-light red gradient-shadow mr-3 settingUserDelete tooltipped" data-position="left" data-tooltip="Delete" '+disabled+'><i class="material-icons">delete</i></a>' :
                                data;
                        } else {
                            return '';
                        }
                    },
                },
                {
                    data: 'agency_name',
                    name: 'agency_name',
                },
                {
                    data: 'is_ministry',
                    name: 'is_ministry',
                },
                {
                    data: 'agensi',
                    name: 'agensi',
                },
                {
                    data: 'ministry',
                    name: 'ministry',
                },
            ],
            columnDefs: [{
                targets: [7,8, 9, 10],
                searchable: true,
                visible: false,

            }],
            order: [
                [1, 'asc']
            ],
            pageLength: length,
            drawCallback: function( settings ) {
                $('.tooltipped').tooltip();
            }
        });
    }

    if ($('#UsersApprovalTable').length > 0) {
        var length = Cookies.get('UsersApprovalTable_length') ? Cookies.get('UsersApprovalTable_length') : 25;
        var UsersApprovalTable = $('#UsersApprovalTable').DataTable({
            responsive: is_desktop,
            processing: true,
            serverSide: true,
            ajax: {
                "url": "/settings/users/approval/ajax",
                "type": "POST",
                "data": function (d) {
                    d._token = $('meta[name="csrf-token"]').attr('content');
                    d.name_filter = $('#filter-name').val();
                    d.filter_status = $("#filter-status").val();
                    d.filter_user_role = $("#filter-user_role").val();
                    d.filter_ministry = $("#filter-minitry").val();
                    d.filter_type = $("#filter-type").val();
                },
            },
            columns: [{
                    data: 'DT_RowIndex',
                    name: 'DT_RowIndex',
                    orderable: false,
                    searchable: false,
                    className: "text-center",
                },
                {
                    data: 'name',
                    name: 'name',
                    render: function (data) {
                        return data.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
                    }
                },
                {
                    data: 'email',
                    name: 'email',
                },
                {
                    data: 'ministry_name',
                    name: 'ministry_name',
                    render: function (data, type, row) {
                        var agent = '-';
                        if (data) {
                            agent = data;
                            if (row.agency_name) {
                                let newAgency = '';
                                if(row.agency_is_new == null){
                                    newAgency = '<i class="material-icons red-text">fiber_new</i>';
                                }
                                agent = newAgency+ ' ' +row.agency_name + '<br><small>' + data + '</small>';
                            }
                        }
                        return agent;
                    }
                },
                {
                    data: 'updated_at',
                    // name: 'updated_at',
                    className: "text-left",
                    render: function (data) {
                        var dt = new Date(data);
                        var datime = dt.getFullYear() + '-' + ('0' + (dt.getMonth()+1)).slice(-2) + '-' + ('0' + dt.getDate()).slice(-2) + ' ' + dt.getHours() + ':' + dt.getMinutes() + ':' + dt.getSeconds();
                        return '<div style="text-align:left;">'+datime+'</div>';
                    }
                },
                {
                    data: 'user_role',
                    name: 'user_role',
                    className: "text-center",
                    render: function (data) {
                        // let badge ={
                        //     'administrator' : '<i class="fas fa-2x fa-user-cog tooltipped"    data-position="left" data-tooltip="Role: Administrator" ></i>',
                        //     'supervisor'    : '<i class="fas fa-2x fa-user-shield tooltipped" data-position="left" data-tooltip="Role: Supervisor" ></i>',
                        //     'webmaster'     : '<i class="fa  fa-2x fa-user-tag tooltipped"    data-position="left" data-tooltip="Role: Webmaster" ></i>'
                        // };

                        let badge2 ={
                            'administrator' : '<img style="width: 1.6rem" src="/images/icons/user-crown.png" class="tooltipped" data-position="left" data-tooltip="Role: Administrator" >',
                            'supervisor'    : '<img style="width: 1.6rem" src="/images/icons/avatar.png" class="tooltipped" data-position="left" data-tooltip="Role: Supervisor" >',
                            'webmaster'     : '<img style="width: 1.6rem" src="/images/icons/user-vneck.png" class="tooltipped" data-position="left" data-tooltip="Role: Webmaster">'
                        };
                        return badge2[data];
                    }
                },
                {
                    data: 'status',
                    name: 'status',
                    className: "text-center",
                    render: function (data, type, row) {
                        let email    = (row.email_verified_at) ? '<div style="text-align:center"><i class="fas fa-envelope-open fa-lg tooltipped" data-position="left" data-tooltip="Email Verified"></i></i></div>' : '<div style="text-align:center"><i class="fas fa-envelope fa-lg tooltipped" data-position="left" data-tooltip="Email Not Verified"></i></div>';
                        return email;
                    }
                },
                {
                    data: "user_id",
                    name: 'user_id',
                    orderable: false,
                    className: "right-align",
                    render: function (data, type, row) {
                        if (row.user_id != null) {
                            let approve =  (row.email_verified_at) ? '<button data-id="' + data + '" data-action="approveorreject" data-new="'+row.agency_is_new+'" class="mb-6 btn-floating btn-small waves-effect waves-light gradient-45deg-green-teal gradient-shadow settingUserApproval mr-3 tooltipped"  data-position="left" data-tooltip="Approve/Reject"><i class="material-icons">check</i></button>' : '' ;
                            return type === 'display' ?
                                approve +
                                '<button data-id="' + data + '"  class="mb-6 btn-floating btn-small waves-effect waves-light indigo gradient-shadow mr-3 settingUserApprovalEdit tooltipped" data-position="left" data-tooltip="View"><i class="material-icons">view_list</i></button>'
                                :
                                data;
                        } else {
                            return '';
                        }
                    },
                },
                {
                    data: 'agency_name',
                    name: 'agency_name',
                },
                {
                    data: 'is_ministry',
                    name: 'is_ministry',
                },
                {
                    data: 'agensi',
                    name: 'agensi',
                },

                {
                    data: 'ministry',
                    name: 'ministry',
                },
            ],
            columnDefs: [{
                targets: [8,9,10,11],
                searchable: true,
                visible: false,

            }],
            order: [
                [1, 'asc']
            ],
            "pageLength": length,
            drawCallback: function( settings ) {
                $('.tooltipped').tooltip();
            }
        });
    }

    $(document).on('change', '[name="UsersTable_length"]', function () {
        let val = $(this).val();
        Cookies.set('UsersTable_length', val, { sameSite: 'None' });
    });

    $(document).on('change', '[name="UsersApprovalTable_length"]', function () {
        let val = $(this).val();
        Cookies.set('UsersApprovalTable_length', val, { sameSite: 'None' });
    });

    $(document).on('click', '.settingUserEdit', function () {
        let data = $(this).data('id');
        window.location.replace('/settings/users/' + data + '/edit');
    });

    $(document).on('click', '.settingUserApprovalEdit', function () {
        let data = $(this).data('id');
        window.location.replace('/settings/users/approval/' + data + '/edit');
    });

    if ($('#filter-name').length > 0) {
        $('#filter-name').keydown(function(event){
            var keyCode = (event.keyCode ? event.keyCode : event.which);
            if (keyCode == 13) {
                $('.filter-users-btn').click();
            }
        });
    }

    $(document).on('click', '.filter-users-btn', function () {
        if ($('#UsersTable').length > 0) {

            // if($('#filter-name').val()){
            //     UsersTable.ajax.reload();
            // }
            // if($('#filter-type').val()){
            //     let data = $('#filter-type').val() == 'Ministry' ? 1 : ($('#filter-type').val() == 'Agency' ? 0 : '');
            //     UsersTable.column(8).search(data).draw();
            // }
            // UsersTable.column(5).search($('#filter-status').val())
            //             .column(4).search($('#filter-user_role').val())
            //             .draw();

            // if ($('#filter-minitry').val()) {
            //     let ministry = $('#filter-minitry').val().split('|');
            //     if (ministry[0] == 1) {
            //         if (ministry[1]) {
            //             UsersTable.column(10).search(  "^" + ministry[1] + "$", true, false, true  )
            //                     .column(9).search( '' )
            //                     .draw();
            //         } else {
            //             UsersTable.column(10).search('')
            //                         .column(9).search( '' )
            //                         .draw();
            //         }
            //     } else {
            //         if (ministry[1]) {
            //             UsersTable.column(9).search(  "^" + ministry[1] + "$", true, false, true  )
            //                     .column(10).search( '' )
            //                     .draw();
            //         } else {
            //             UsersTable.column(10).search('')
            //                         .column(9).search( '' )
            //                         .draw();
            //         }
            //     }
            // }

            UsersTable.draw();

            let store = {
                'name'      : $('#filter-name').val(),
                'type'      : $('#filter-type').val(),
                'status'    : $('#filter-status').val(),
                'user_role' : $('#filter-user_role').val(),
                'minitry'   : $('#filter-minitry').val()
            };

            Cookies.set('UsersFilter', JSON.stringify(store), { sameSite: 'None' });
        }

        if ($('#UsersApprovalTable').length > 0) {
            // if($('#filter-name').val()){
            //     UsersApprovalTable.ajax.reload();
            // }
            // if($('#filter-type').val()){
            //     let data = $('#filter-type').val() == 'Ministry' ? 1 : ($('#filter-type').val() == 'Agency' ? 0 : '');
            //     UsersApprovalTable.column(8).search(data).draw();
            // }
            // UsersApprovalTable.column(5).search($('#filter-status').val())
            //     .column(4).search($('#filter-user_role').val())
            //     .draw();
            // if ($('#filter-minitry').val()) {
            //     let ministry = $('#filter-minitry').val().split('|');
            //     if (ministry[0] == 1) {
            //         if (ministry[1]) {
            //             UsersApprovalTable.column(10).search(  "^" + ministry[1] + "$", true, false, true  )
            //                     .column(9).search( '' )
            //                     .draw();
            //         } else {
            //             UsersApprovalTable.column(10).search('')
            //                         .column(9).search( '' )
            //                         .draw();
            //         }
            //     } else {
            //         if (ministry[1]) {
            //             UsersApprovalTable.column(9).search(  "^" + ministry[1] + "$", true, false, true  )
            //                     .column(10).search( '' )
            //                     .draw();
            //         } else {
            //             UsersApprovalTable.column(10).search('')
            //                         .column(9).search( '' )
            //                         .draw();
            //         }
            //     }
            // }

            UsersApprovalTable.draw();

            let store = {
                'name'      : $('#filter-name').val(),
                'type'      : $('#filter-type').val(),
                'status'    : $('#filter-status').val(),
                'user_role' : $('#filter-user_role').val(),
                'minitry'   : $('#filter-minitry').val()
            };

            Cookies.set('UsersApprovalFilter', JSON.stringify(store), { sameSite: 'None' });
        }
    });

    if ($('.filter-users-btn').length > 0) {
        if ($('#UsersTable').length > 0) {
            let data = Cookies.get('UsersFilter');
            if (data) {
                data = JSON.parse(data);
                $('#filter-name').val(data.name);
                $('#filter-type').val(data.type);
                $('#filter-status').val(data.status);
                $('#filter-user_role').val(data.user_role);
                $('#filter-minitry').val(data.minitry);

                $('#filter-type').select2();
                $('#filter-status').select2();
                $('#filter-user_role').select2();
                $('#filter-minitry').select2();

                $('.filter-users-btn').click();
            }
        }

        if ($('#UsersApprovalTable').length > 0) {
            let data = Cookies.get('UsersApprovalFilter');
            if (data) {
                data = JSON.parse(data);
                $('#filter-name').val(data.name);
                $('#filter-type').val(data.type);
                $('#filter-status').val(data.status);
                $('#filter-user_role').val(data.user_role);
                $('#filter-minitry').val(data.minitry);

                $('#filter-type').select2();
                $('#filter-status').select2();
                $('#filter-user_role').select2();
                $('#filter-minitry').select2();

                $('.filter-users-btn').click();
            }
        }
    }

    $(document).on('click', '.filter-users-reset-btn', function () {
        $('#filter-name').val('');
        $('#filter-status').val('');
        $('#filter-user_role').val('');
        $('#filter-minitry').val('');
        $('#filter-type').val('');
        $('#filter-status').select2();
        $('#filter-user_role').select2();
        $('#filter-minitry').select2({
            dropdownAutoWidth: true,
            width: '100%',
            tags: false,
            templateResult: AgencyFormat2,
            templateSelection: AgencyFormat1,
            escapeMarkup: function (es) { return es; }
        });
        $('#filter-type').select2();
        if ($('#UsersTable').length > 0) {
            UsersTable.column(4).search('')
                        .column(5).search('')
                        .column(8).search('')
                        .column(9).search('')
                        .column(10).search('')
                        .draw();
            UsersTable.ajax.reload();
            Cookies.remove('UsersFilter');
        }

        if ($('#UsersApprovalTable').length > 0) {
            UsersApprovalTable.column(4).search('')
                              .column(5).search('')
                              .column(8).search('')
                              .column(9).search('')
                              .column(10).search('')
                              .draw();
            UsersApprovalTable.ajax.reload();
            Cookies.remove('UsersApprovalFilter');
        }
    });

    function AgencyFormat1(icon) {
        var originalOption = icon.element;
        if (!icon.id) { return icon.text; }
        var $icon = icon.text ;
        return $icon;
    }

    function AgencyFormat2(icon) {
        var originalOption = icon.element;
        if (!icon.id) { return icon.text; }
        if ($(icon.element).data('ministry')) {
            var $icon = icon.text + "<br><small>"+$(icon.element).data('ministry')+"</small>" ;
        } else {
            var $icon = icon.text ;
        }
        return $icon;
    }

    $(document).on('click', '.settingUserDelete', function () {
        let id = $(this).data("id");
        Swal.fire({
            title: 'Proceed With Caution!',
            html: "Do you really want to delete these record?<br>This process cannot be undone.",
            type: 'warning',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#9e9e9e',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                $('#SettingUsersDeleteForm').attr('action', "/settings/users/" + id);
                $('#SettingUsersDeleteForm').submit();
            }
        });
    });

    $(document).on('change', '#user_role', function () {
        if ($(this).val() == 'administrator') {
            $('.agency_id').hide();
            $('#agency_id').prop('required', false);
        } else {
            $('.agency_id').show();
            $('#agency_id').prop('required', true);
        }
    });

    $(document).on('click', '.settingUserApproval', function () {
        let id     = $(this).data("id");
        let action = $(this).data("action");
        let newTxt = $(this).data("new");
        let btn_color = (action == 'approve') ? '#4caf50' : '#f44336';
        if (action == 'approve') {
            Swal.fire({
                title: 'Proceed With Caution!',
                html: "Are you sure want to <b>" + action.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) + "</b> this user?",
                type: 'warning',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: btn_color,
                cancelButtonColor: '#9e9e9e',
                confirmButtonText: action.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
            }).then((result) => {
                if (result.value) {
                    $('#SettingUsersApprovalId').val(id);
                    $('#SettingUsersApprovalAction').val(action);
                    $('#SettingUsersApprovalForm').submit();
                }
            });
        }
        else if(action == 'approveorreject'){
            let txt = '';
            if(newTxt == null){
                txt = '<b>New Agency</b> will be created once approved.<br>';
            }
            Swal.fire({
                title: 'Proceed With Caution!',
                html: txt + "Please choose <b>Approve OR Reject</b> below.",
                type: 'warning',
                icon: 'warning',
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonColor: '#4caf50',
                cancelButtonColor: '#bbbbbb',
                confirmButtonText: 'Approve',
                cancelButtonText: 'Cancel',
                denyButtonText: 'Reject',
            }).then((result) => {
                if (result.isConfirmed) {
                    $('#SettingUsersApprovalId').val(id);
                    $('#SettingUsersApprovalAction').val('approve');
                    $('#SettingUsersApprovalForm').submit();
                }
                else if (result.isDenied) {
                    Swal.fire({
                        title: 'Proceed With Caution!',
                        html: "Are you sure want to <b>Reject</b> this user?",
                        type: 'warning',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: btn_color,
                        cancelButtonColor: '#9e9e9e',
                        confirmButtonText: 'reject'.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()),
                        input: 'textarea',
                        inputLabel: 'Reason',
                        inputPlaceholder: 'Type your reason here...',
                        inputAttributes: {
                            'aria-label': 'Type your message here',
                            // required: 'true'
                        },
                        preConfirm: (value) => {
                            if (!value) {
                                Swal.showValidationMessage('Reason is required');
                            }
                        }
                    }).then((result) => {
                        if (result.isConfirmed) {
                            $('#SettingUsersApprovalId').val(id);
                            $('#SettingUsersApprovalAction').val('reject');
                            $('#SettingUsersApprovalReason').val(result.value);
                            $('#SettingUsersApprovalForm').submit();
                        }
                    });
                }
            });
        }
        else {
            Swal.fire({
                title: 'Proceed With Caution!',
                html: "Are you sure want to <b>" + action.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) + "</b> this user?",
                type: 'warning',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: btn_color,
                cancelButtonColor: '#9e9e9e',
                confirmButtonText: action.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()),
                input: 'textarea',
                inputLabel: 'Reason',
                inputPlaceholder: 'Type your reason here...',
                inputAttributes: {
                    'aria-label': 'Type your message here',
                    // required: 'true'
                },
                preConfirm: (value) => {
                    if (!value) {
                        Swal.showValidationMessage('Reason is required');
                    }
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    $('#SettingUsersApprovalId').val(id);
                    $('#SettingUsersApprovalAction').val(action);
                    $('#SettingUsersApprovalReason').val(result.value);
                    $('#SettingUsersApprovalForm').submit();
                }
            });
        }
    });


    $(document).on('mouseover', '#CreateUserForm', function(){
        CreateUserFormvalidation();
    });


    // $("#CreateUserForm").validate({
    //     rules: {
    //       email: {
    //         required: true,
    //         email:true
    //       }
    //       },
    //       errorElement : 'div',
    //       errorPlacement: function(error, element) {
    //         var placement = $(element).data('error');
    //         if (placement) {
    //           $(placement).append(error)
    //         } else {
    //       error.insertAfter(element);
    //       }
    //     }
    //   });
    function isValidEmailAddress(emailAddress) {
        var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
        return pattern.test(emailAddress);
    };
    function CreateUserFormvalidation(){
        if ($('#CreateUserForm').length > 0) {
            let count = 0;

            if($('#name').length > 0){
                $("#name").on("keyup change", function(){
                    if($(this).val() == ''){
                        $('.validate_'+$(this).attr('name')+'_msg').html($(this).data('label')+' field is required');
                        count++;
                    }
                    else{
                        $('.validate_'+$(this).attr('name')+'_msg').html('');
                    }
                });
            }

            if($('#email').length > 0){
                $("#email").on("keyup change", function(){
                    $('.validate_'+$(this).attr('name')+'_msg').html('');
                    if($(this).val() == ''){
                        $('.validate_'+$(this).attr('name')+'_msg').html($(this).data('label')+' address is required');
                        count++;
                    }
                    else{
                        if ($(this).val().trim().match(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/) == null) {
                            $('.validate_'+$(this).attr('name')+'_msg').html($(this).data('label')+' address invalid');
                            count++;
                        }
                        else{
                            $('.validate_'+$(this).attr('name')+'_msg').html('');
                        }
                    }
                });
            }

            $( 'select' ).each(function() {
                if ($(this).attr('required')) {
                    if ($(this).val()) {
                        $('.validate_'+$(this).attr('name')+'_msg').html('');
                    } else {
                        // $('.validate_'+$(this).attr('name')+'_msg').html($(this).data('label')+' field is required');
                        count++;
                    }
                }
            });

            // $('input').each(function( ) {
            //     if ($(this).attr('required') && $(this).attr('name')) {
            //         if ($(this).val()) {
            //             if($(this).attr('name') === 'email' && !isValidEmailAddress($(this).val())){
            //                 $('.validate_'+$(this).attr('name')+'_msg').html($(this).data('label')+' address invalid');
            //                 count++;
            //             }
            //             else{
            //                 $('.validate_'+$(this).attr('name')+'_msg').html('');
            //             }
            //         } else {
            //             // $('.validate_'+$(this).attr('name')+'_msg').html($(this).data('label')+' field is required');
            //             count++;
            //         }
            //     }
            // });

            if (count == 0) {
                $('[type="submit"]').prop('disabled', false);
            } else {
                $('[type="submit"]').prop('disabled', true);
            }
        }
    }

    $(document).on('mouseover', '#EditUserForm', function(){
        EditUserFormValidation();
    });

    function EditUserFormValidation(){
        if ($('#EditUserForm').length > 0) {
            let count = 0;
            $('#EditUserForm select').each(function( ) {
                if ($(this).attr('required')) {
                    if ($(this).val()) {
                        $('.validate_'+$(this).attr('name')+'_msg').html('');
                    } else {
                        $('.validate_'+$(this).attr('name')+'_msg').html($(this).data('label')+' field is required');
                        count++;
                    }
                }
            });

            $('#EditUserForm input').each(function( ) {
                if ($(this).attr('required') && $(this).attr('name')) {
                    if ($(this).val()) {
                        $('.validate_'+$(this).attr('name')+'_msg').html('');
                    } else {
                        $('.validate_'+$(this).attr('name')+'_msg').html($(this).data('label')+' field is required');
                        count++;
                    }
                }
            });

            if (count == 0) {
                $('[type="submit"]').prop('disabled', false);
            } else {
                $('[type="submit"]').prop('disabled', true);
            }
        }
    }

    if ($('#EditUserForm').length > 0) {
        $(document).on('change', '#user_role', function(){
            if ($('#auth_user_role').data('value') == 'supervisor') {
                let val  = $(this).val();
                let val2 = $('#current_agency').data('value');
                let val3 = $('#current_user_role').data('value');
                $.ajax({
                    type: 'POST',
                    url: '/settings/users/ministry/ajax',
                    data: {
                        _token: $('meta[name="csrf-token"]').attr('content'),
                        select_user_role : val,
                        current_agency   : val2,
                        current_user_role: val3,
                    },
                    success: function (result) {
                        $('#agency_id').html('<option selected disabled>Please Select Agency</option>');
                        result.forEach(element => {
                            let html = element.is_ministry ? element.agency_name : '&emsp;&emsp;' + element.agency_name;
                            $('#agency_id').append('<option value="'+element.id+'">'+html+'</option>');
                        });
                        $("#agency_id").select2({
                            dropdownAutoWidth: true,
                            width: '100%',
                            templateSelection: reformat,
                            tags: false,
                        });
                        function reformat(icon) {
                            var originalOption = icon.element;
                            if (!icon.id) { return icon.text.replace(/&emsp;/g, ''); }
                            var $icon = icon.text.replace(/&emsp;/g, '') ;
                            return $icon;
                        }
                    },
                });
            }
        });
    }

    if($("#snow-container").length > 0){

        var quill = new Quill("#snow-container .editor",{bounds:"#snow-container .editor",modules:{formula:!0,syntax:!0,toolbar:"#snow-container .quill-toolbar"},theme:"snow"});

        var form = document.getElementById("eb");
        form.onsubmit = function() {
            // var name = document.querySelector('textarea[name=body]');
            // name.value = quill.root.innerHTML;
            $("#hiddenArea").html(quill.root.innerHTML);
            console.log(quill.root.innerHTML, $("#hiddenArea").html());
            return true; // submit form
        }
    }

});
