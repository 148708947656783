const moment = require("moment");

$(document).ready(function () {
    var loading;
    if($('a[href="#splask_analytics"]').length > 0){
        $('a[href="#splask_analytics"]').on("click", function(){
            window.render_barchart_compliance_scoring();
            window.render_barcharts_cms_type();
            window.render_barcharts_hall_of_fame();
            window.render_barcharts_top_web();
            window.render_linechart_average_downtime_annualy();
            window.render_linechart_average_downtime_monthly();
            window.render_linechart_average_loading_time_annualy();
            window.render_linechart_average_loading_time_monthly();
            window.render_linechart_average_downtime_monthly();

            // setTimeout(() => {
            //     window.render_barchart_compliance_scoring();
            //     window.render_barcharts_cms_type();
            //     window.render_barcharts_hall_of_fame();
            //     window.render_barcharts_top_web();
            //     window.render_linechart_average_downtime_annualy();
            //     window.render_linechart_average_downtime_monthly();
            //     window.render_linechart_average_loading_time_annualy();
            //     window.render_linechart_average_loading_time_monthly();
            //     window.render_linechart_average_downtime_monthly();
            // }, 1000);
        });
    }


    if($("#main").length > 0){
        if($("#linechart_average_loading_time_annualy").length > 0){
            window.render_linechart_average_loading_time_annualy();
        }
        if($("#linechart_average_loading_time_monthly").length > 0){
            window.render_linechart_average_loading_time_monthly();
        }
        if($("#linechart_average_downtime_annualy").length > 0){
            window.render_linechart_average_downtime_annualy();
        }
        if($("#linechart_average_downtime_monthly").length > 0){
            window.render_linechart_average_downtime_monthly();
        }
    }



    window.statistic_changes = function() {
        $.ajax({
            type: 'POST',
            url: '/statistics/getData',
            data: {
                _token: $('meta[name="csrf-token"]').attr('content'),
                ministry_id: $('#filter-analitic-ministry-login').val(),
                agency_id: $('#filter-analitic-agency-login').val(),
            },
            beforeSend: function(){
                $("#hidemeplease").css('visibility','hidden');
                $("#days90value").text(0);
                $(".hideme-s").css('display', 'none');
            },
            success: function (result) {
                console.log(result);
                if (result) {
                    if(result.visit_count2 > 0){
                        $("#hidemeplease").css('visibility','initial');
                        $("#days90value").text(result.visit_count2);
                    }
                    $('.total_hits').text(result.visit_count);
                    barcharts_visit_today.data = {
                        labels: ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", ],
                        datasets: [{
                            label: "Visits",
                            data: result.today_count,
                            backgroundColor: "#ffc107",
                            hoverBackgroundColor: "#00acc1",
                            borderColor: "transparent"
                        }, ]
                    };
                    barcharts_visit_today.update();
                    let html = '';
                    let other1 = 0;
                    let other1_ = '';
                    for (const [k, i] of Object.entries(result.devices_count)) {
                        let x = (i / result.visit_count2) * 100;
                        if (parseFloat(x) > 0.75) {
                            html += '<div class="row div_devices3_"><div class="col-7"><p for="">'+k+'</p></div><div class="col-5 text-right"><p class="reset_progress1 devices1_">'+x.toFixed(2)+' %</p></div></div><div class="row div_devices3_ mt-0 mb-3"><div class="col-12"><div class="progress reset_progress2 devices2_" data-toggle="tooltip" data-placement="top" title="'+i+' Devices"><div class="progress-bar reset_progress3 devices3_" role="progressbar" style="width: '+x+'%" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div></div></div></div>';
                        }
                        else{
                            other1 += i;
                            other1_ += k + " ("+i+")<br>";
                        }
                    }
                    if(other1 > 0){
                        let x = (other1 / result.visit_count2) * 100;
                        html += '<div class="row div_devices3_"><div class="col-7"><p for=""><a data-toggle="popover" title="Other Devices ('+other1+'))" data-content="'+other1_+'" data-html="true" data-timeout="2000">Others <i class="fa fa-info-circle" style="margin-top: auto;"></i></a></p></div><div class="col-5 text-right"><p class="reset_progress1 devices1_">'+x.toFixed(2)+' %</p></div></div><div class="row div_devices3_ mt-0 mb-3"><div class="col-12"><div class="progress reset_progress2 devices2_" data-toggle="tooltip" data-placement="top" title="'+other1+' Devices"><div class="progress-bar reset_progress3 devices3_" role="progressbar" style="width: '+x+'%" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div></div></div></div>';
                    }
                    $("#devices").html(html);
                    let html2 = '';
                    let other2 = 0;
                    let other2_ = '';
                    for (const [k2, i2] of Object.entries(result.browser_count)) {
                        let x = (i2 / result.visit_count2) * 100;
                        if (parseFloat(x) > 0.75) {
                            html2 += '<div class="row div_devices3_"><div class="col-7"><p for="">'+k2+'</p></div><div class="col-5 text-right"><p class="reset_progress1 devices1_">'+x.toFixed(2)+' %</p></div></div><div class="row div_devices3_ mt-0 mb-3"><div class="col-12"><div class="progress reset_progress2 devices2_" data-toggle="tooltip" data-placement="top" title="'+i2+' Devices"><div class="progress-bar reset_progress3 devices3_" role="progressbar" style="width: '+x+'%" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div></div></div></div>';
                        }
                        else{
                            other2 += i2;
                            other2_ += k2 + " ("+i2+")<br>";
                        }
                    }
                    if(other2 > 0){
                        let x = (other2 / result.visit_count2) * 100;
                        html2 += '<div class="row div_devices3_"><div class="col-7"><p for=""><a data-toggle="popover" title="Other Browsers ('+other2+')" data-content="'+other2_+'" data-html="true" data-timeout="2000">Others <i class="fa fa-info-circle" style="margin-top: auto;"></i></a></p></div><div class="col-5 text-right"><p class="reset_progress1 devices1_">'+x.toFixed(2)+' %</p></div></div><div class="row div_devices3_ mt-0 mb-3"><div class="col-12"><div class="progress reset_progress2 devices2_" data-toggle="tooltip" data-placement="top" title="'+other2+' Devices"><div class="progress-bar reset_progress3 devices3_" role="progressbar" style="width: '+x+'%" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div></div></div></div>';
                    }
                    $("#browsers").html(html2);
                    let html3 = '';
                    let other3 = 0;
                    let other3_ = '';
                    for (const [k3, i3] of Object.entries(result.os_count)) {
                        let x = (i3 / result.visit_count2) * 100;
                        if (parseInt(x) > 0.75) {
                            html3 += '<div class="row div_devices3_"><div class="col-7"><p for="">'+k3+'</p></div><div class="col-5 text-right"><p class="reset_progress1 devices1_">'+x.toFixed(2)+' %</p></div></div><div class="row div_devices3_ mt-0 mb-3"><div class="col-12"><div class="progress reset_progress2 devices2_" data-toggle="tooltip" data-placement="top" title="'+i3+' Devices"><div class="progress-bar reset_progress3 devices3_" role="progressbar" style="width: '+x+'%" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div></div></div></div>';
                        }
                        else{
                            other3 += i3;
                            other3_ += k3 + " ("+i3+")<br>";
                        }
                    }
                    if(other3 > 0){
                        let x = (other3 / result.visit_count2) * 100;
                        html3 += '<div class="row div_devices3_"><div class="col-7"><p for=""><a data-toggle="popover" title="Other Operation Systems ('+other3+')" data-content="'+other3_+'" data-html="true" data-timeout="2000">Others <i class="fa fa-info-circle" style="margin-top: auto;"></i></a></p></div><div class="col-5 text-right"><p class="reset_progress1 devices1_">'+x.toFixed(2)+' %</p></div></div><div class="row div_devices3_ mt-0 mb-3"><div class="col-12"><div class="progress reset_progress2 devices2_" data-toggle="tooltip" data-placement="top" title="'+other3+' Devices"><div class="progress-bar reset_progress3 devices3_" role="progressbar" style="width: '+x+'%" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div></div></div></div>';
                    }
                    $("#oss").html(html3);
                    $('html').on("click",function() {
                        $('[data-toggle="popover"]').popover('hide');
                    });
                    $('[data-toggle="tooltip"]').tooltip();
                    $('[data-toggle="popover"]').popover({html: true, container: 'body'}).click(function(evt) {
                        evt.stopPropagation();
                        $(this).popover('show');
                    });;

                }
                // loading.close();
            }
        });
    }

    if($('a[href="#government_analytics"]').length > 0){
        $('a[href="#government_analytics"]').on("click", function(){
            window.statistic_changes();
        });
    }

    setTimeout(() => {
        $(document).on('change', '.statistic_changes', function () {
            // loading = Swal.fire({
            //     // html: 'Please wait...',
            //     allowEscapeKey: false,
            //     allowOutsideClick: false,
            //     didOpen: () => {
            //         Swal.showLoading()
            //     }
            // });
            window.statistic_changes();
        });
    }, 500);

    if ($('.total_hits').length > 0) {
        $('.tooltipped').tooltip();
        // loading = Swal.fire({
        //     allowEscapeKey: false,
        //     allowOutsideClick: false,
        //     didOpen: () => {
        //         Swal.showLoading()
        //     }
        // });
        var ctx = $("#barcharts_visit_today");

        // Chart Options
        var chartOptions = {
            elements: {
                rectangle: {
                    borderWidth: 2,
                    borderColor: "rgb(0, 255, 0)",
                    borderSkipped: "left"
                }
            },
            responsive: true,
            maintainAspectRatio: true,
            responsiveAnimationDuration: 500,
            legend: {
                position: "top"
            },
            scales: {
                xAxes: [{
                    display: true,
                    gridLines: {
                        color: "#f3f3f3",
                        drawTicks: false
                    },
                    scaleLabel: {
                        display: true
                    },
                    ticks: {
                        padding: 15,
                        autoSkip: true,
                        maxRotation: 0,
                        minRotation: 0
                    }
                }],
                yAxes: [{
                    display: true,
                    gridLines: {
                        color: "#f3f3f3",
                        drawTicks: false
                    },
                    scaleLabel: {
                        display: true
                    },
                    ticks: {
                        beginAtZero: true,
                        callback: function(value) {if (value % 1   === 0) {return value;}},
                        // callback: function(value) {if (value % 1 === 0) {return value;}}
                        autoSkip: true,
                        maxRotation: 0,
                        minRotation: 0
                    }
                }]
            },
            title: {
                display: false,
                text: ""
            },
            plugins: {
                labels: false
            }
        };

        var chartData = {
            labels: ["12 AM", "1 AM", "2 AM", "3 AM", "4 AM", "5 AM", "6 AM", "7 AM", "8 AM", "9 AM", "10 AM", "11 AM", "12 PM", "1 PM", "2 PM", "3 PM", "4 PM", "5 PM", "6 PM", "7 PM", "8 PM", "9 PM", "10 PM", "11 PM", ],
            datasets: [{
                label: "Visits",
                data: [0],
                backgroundColor: "#ffc107",
                hoverBackgroundColor: "#00acc1",
                borderColor: "transparent"
            }, ]
        };

        var config = {
            type: "bar",

            // Chart Options
            options: chartOptions,

            data: chartData
        };

        if ($("#barcharts_visit_today").length > 0) {
            var barcharts_visit_today = new Chart(ctx, config);
        }
        // setTimeout(() => {

        // }, 700);
        window.statistic_changes();
    }

    if ($("#statistic_period_start").length > 0) {
        $('#statistic_period_start').datepicker({
            defaultDate: new Date(new Date().getFullYear(), 0, 1),
            format: 'dd-mm-yyyy',
            maxDate: new Date,
            autoClose: true,
            setDefaultDate: true,
        });
    }

    if ($("#statistic_period_end").length > 0) {
        $('#statistic_period_end').datepicker({
            defaultDate: new Date(),
            format: 'dd-mm-yyyy',
            maxDate: new Date,
            autoClose: true,
            setDefaultDate: true,
        });
    }

});
