require('bootstrap');
window.moment = require('moment');
window.bootbox = require('bootbox');

require('datatables.net-bs4');
import Swal from 'sweetalert2/dist/sweetalert2.js';
window.Swal = Swal;
import 'sweetalert2/src/sweetalert2.scss';
require('multiselect-two-sides');
import Cookies from 'js-cookie'
window.Cookies = Cookies;

require('./global');
require('./register');

// Charts
require('./charts/barchart_compliance_scoring');
require('./charts/piecharts_users');
require('./charts/barcharts_agencies');
require('./charts/barcharts_cms_type');
require('./charts/barcharts_hall_of_fame');
require('./charts/barcharts_top_web');
require('./charts/linechart_average_loading_time_annualy');
require('./charts/linechart_average_loading_time_monthly');
require('./charts/linechart_website_visitor_annualy');
require('./charts/linechart_website_visitor_monthly');
require('./charts/linechart_average_downtime_annualy');
require('./charts/linechart_average_downtime_monthly');

require('./charts/linechart_loading_time_miti_annualy');
require('./charts/linechart_loading_time_miti_monthly');
require('./charts/linechart_downtime_miti_annualy');
require('./charts/linechart_downtime_miti_monthly');

// Statistics
require('./statistics/compliance_scoring');
require('./statistics/index');

// Profiles
require('./profiles/website');

// Assessment
require('./assessments/website');

// Settings
require('./settings/users');
require('./settings/agency');
require('./settings/system_log');
require('./settings/import');
require('./settings/criteria');
