$(document).ready(function () {

    var is_desktop = false;
    if ($('#is_desktop').length > 0) {
        is_desktop = $('#is_desktop').data('value') ? false : true;
    }

    if ($('#AgencyTable').length > 0) {
        var length = Cookies.get('AgencyTable_length') ? Cookies.get('AgencyTable_length') : 25;
        var AgencyTable = $('#AgencyTable').DataTable({
            responsive: is_desktop,
            processing: true,
            serverSide: true,
            ajax: {
                "url": "/settings/agencies/ajax",
                "type": "POST",
                "data": function (d) {
                    d.filterministry = $("#filter-minitry").val();
                    d.filterstatus = $("#filter-status").val();
                    d.filtertype = $("#filter-type").val();
                    d._token = $('meta[name="csrf-token"]').attr('content');
                },
            },
            columns: [
                {
                    data: 'DT_RowIndex',
                    name: 'DT_RowIndex',
                    orderable: false,
                    searchable: false,
                    // className: "text-center",
                },
                {
                    data: 'ministry_name',
                    name: 'ministry_name',
                    orderable: false,
                    render: function (data, type, row) {
                        // console.log(row);
                        let name = '<b>' + data.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) + '</b>';
                        if (row.agency_name) {
                            name = '<b>' + row.agency_name.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) + '<br><small>' + data.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) + '</small>';
                        }
                        return name;
                    }
                },
                {
                    data: 'status',
                    name: 'status',
                    orderable: false,
                    className: "text-center",
                    render: function (data, type, row) {
                        if (data == 1) {
                            return data == 1 ? '<div class="tooltip" style="z-index:5;"><i class="material-icons green-text">radio_button_checked</i><span class="tooltiptext">Active</span></div>' : '';
                        }
                        else{
                            return '<div class="tooltip" style="z-index:5;"><i class="material-icons red-text">radio_button_checked</i><span class="tooltiptext">Inactive</span></div>';
                        }
                        // return (data == '1') ? 'Active' : 'Inactive';
                    }
                },
                {
                    data: "agency_id",
                    name: 'agency_id',
                    orderable: false,
                    className: "text-center",
                    render: function (data, type, row) {
                        if (row.agency_id != null) {
                            let disabled = $('#auth_user_agency').data('value') == row.id ? 'disabled' : '';
                            return type === 'display' ?
                                // '<a href="/settings/agencies/'+data+'/edit" class="indigo waves-effect waves-light btn-small" title="Edit"><i class="fas fa-edit"></i></a>&nbsp;' +
                                // '<button data-id="' + data + '" class="red waves-effect waves-light btn-small settingAgencyDelete" title="Delete"><i class="fas fa-trash"></i></button>' :
                                '<a data-id="' + data + '" class="mb-6 btn-floating btn-small waves-effect waves-light indigo gradient-shadow mr-3 agency_edit tooltipped" data-position="left" data-tooltip="Edit"><i class="material-icons">edit</i></a>' +
                                '<a data-id="' + data + '" class="mb-6 btn-floating btn-small waves-effect waves-light red gradient-shadow mr-3 settingAgencyDelete tooltipped" data-position="left" data-tooltip="Delete" '+disabled+'><i class="material-icons">delete</i></a>' :
                                data;
                        } else {
                            return '';
                        }
                    },
                },
                {
                    data: 'agency_code',
                    name: 'agency_code',
                },
                {
                    data: 'agency_type',
                    name: 'agency_type',
                },
                {
                    data: 'agency_name',
                    name: 'agency_name',
                },
                // {
                //     data: 'agency',
                //     name: 'agency',
                // },
                {
                    data: 'ministry',
                    name: 'ministry',
                },
                {
                    data: 'isMinistry',
                    name: 'isMinistry',
                },
            ],
            columnDefs: [{
                targets: [4,5,6,7,8], //7 //9
                searchable: true,
                visible: false,

            },
            { orderable: false, targets: 1 }
            ],
            // order: [[4, 'asc']],
            pageLength: length,
            drawCallback: function( settings ) {
                $('.tooltipped').tooltip();
            }
        });
    }

    $(document).on('change', '[name="AgencyTable_length"]', function () {
        let val = $(this).val();
        Cookies.set('AgencyTable_length', val, { sameSite: 'None' });
    });

    $(document).on('click', '.agency_edit', function () {
        let data = $(this).data('id');
        window.location.replace('/settings/agencies/'+data+'/edit');
    });

    $(document).on('click', '.filter-agency-btn', function () {

        // if ($('#filter-minitry').val()) {
        //     let ministry = $('#filter-minitry').val().split('|');
        //     if (ministry[0] == 1) {
        //         AgencyTable.column(8).search('')
        //                 .column(7).search('');
        //         // AgencyTable.column(8).search( "^" + ministry[1] + "$", true, false, true ).draw();
        //     } else {
        //         AgencyTable.column(8).search( '' );
        //                 //.column(7).search( '' );
        //         AgencyTable.column(7).search( "^" + ministry[1] + "$", true, false, true );
        //     }
        // }
        // AgencyTable.column(2).search( $('#filter-status').val()  )
        //             .column(9).search( $('#filter-type').val()  )
        //             .draw();
        AgencyTable.draw();
        let store = {
            'minitry' : $('#filter-minitry').val(),
            'status'  : $('#filter-status').val(),
            'type'    : $('#filter-type').val()
        };
        Cookies.set('AgencyFilter', JSON.stringify(store), { sameSite: 'None' });
    });

    if ($('.filter-agency-btn').length > 0) {
        let data = Cookies.get('AgencyFilter');
        if (data) {
            data = JSON.parse(data);
            $('#filter-minitry').val(data.minitry);
            $('#filter-status').val(data.status);
            $('#filter-type').val(data.type);
            $('#filter-minitry').select2();
            $('#filter-status').select2();
            $('#filter-type').select2();
            $('.filter-agency-btn').click();
        }
    }

    $(document).on('click', '.filter-agency-reset-btn', function () {
        $('#filter-minitry').val('');
        $('#filter-status').val('');
        $('#filter-type').val('');
        $('#filter-minitry').select2({
            dropdownAutoWidth: true,
            width: '100%',
            tags: false,
            templateResult: AgencyFormat2,
            templateSelection: AgencyFormat1,
            escapeMarkup: function (es) { return es; }
        });
        $('#filter-status').select2();
        $('#filter-type').select2();
        AgencyTable.column(7).search(    '' )
                      .column(8).search( '' )
                      .column(9).search( '' )
                      .column(2).search( '' )
                      .column(9).search( '' )
                      .draw();

        Cookies.remove('AgencyFilter');
    });

    function AgencyFormat1(icon) {
        var originalOption = icon.element;
        if (!icon.id) { return icon.text; }
        var $icon = icon.text ;
        return $icon;
    }

    function AgencyFormat2(icon) {
        var originalOption = icon.element;
        if (!icon.id) { return icon.text; }
        if ($(icon.element).data('ministry')) {
            var $icon = icon.text + "<br><small>"+$(icon.element).data('ministry')+"</small>" ;
        } else {
            var $icon = icon.text ;
        }
        return $icon;
    }

    $(document).on('click','.settingAgencyDelete', function(){
        let id = $(this).data("id");
        $.ajax({
            type: 'POST',
            url: '/settings/agencies/delete/details',
            data: {
                _token: $('meta[name="csrf-token"]').attr('content'),
                id: id,
            },
            success: function (result) {
                let agency  = result.agency ?   'Number of agency: <b>' + result.agency + '</b>'   : '';
                let user    = result.user ?     'Number of user: <b>' + result.user    + '</b>'    : '';
                let user_pending = result.user_pending ?  'Number of pending users: <b>' + result.user_pending + '</b>' : '';
                let website = result.website ?  'Number of website: <b>' + result.website + '</b>' : '';
                let assessment = result.assessment ?  'Number of assessment: <b>' + result.assessment + '</b>' : '';
                let label = '';
                label += agency ? agency +'<br>' : '';
                label += user ? user +'<br>' : '';
                label += user_pending ? user_pending +'<br>' : '';
                label += website ? website +'<br>' : '';
                label += assessment ? assessment +'<br>' : '';
                let label2 = label ? '<br><br>' + label : '';

                Swal.fire({
                    title: 'Proceed With Caution!',
                    html: "Do you really want to delete <b>"+ result.agency_name +"</b>?<br>This process cannot be undone." + label2,
                    type: 'warning',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#9e9e9e',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        $('#SettingAgencyDeleteForm').attr('action', "/settings/agencies/" + id);
                        $('#SettingAgencyDeleteForm').submit();
                    }
                });
            },
        });

    });

    // $(document).on('click','#isMinistry', function(){
    //     if ($(this).is(":checked")) {
    //         $('#not-ministry').hide();
    //         $('.not-ministry').prop('required', false);
    //         $('#is-ministry').show();
    //         $('.is-ministry').prop('required', true);
    //     } else {
    //         $('#not-ministry').show();
    //         $('.not-ministry').prop('required', true);
    //         $('#is-ministry').hide();
    //         $('.is-ministry').prop('required', false);
    //     }
    // });

    if ($(".select2_create_agency_ministry").length > 0) {
        $(".select2_create_agency_ministry").select2({
            dropdownAutoWidth: true,
            width: '100%',
            tags: true
        });
    }

    $(document).on('change','.select2_create_agency_ministry', function(){
        let val = $(this).val();
        //if(val.match(/[^a-z,^A-Z,^0-9 ,\.]/) != null){
        if(val.match(/[^a-z,^A-Z,^0-9 ,(),-\.]/) != null){
            $(this)[0].setCustomValidity('Invalid symbols except comma . , - ( )');
        }
        else{
            $(this)[0].setCustomValidity("");
        }
        $.ajax({
            type: 'POST',
            url: '/settings/agencies/ministry/details',
            data: {
                _token: $('meta[name="csrf-token"]').attr('content'),
                name: val,
            },
            success: function (result) {
                if (result.agency_name) {
                    $('#ministry_code').val(result.agency_code);
                    $('#ministry_type').val(result.agency_type);
                    $('#ministry_status').val(result.status);
                    $('#ministry_code').prop('disabled', true);
                    $('#ministry_type').prop('disabled', true);
                    $('#ministry_status').prop('disabled', true);
                    $('#ministry_status').select2();
                    $('#ministry_type').select2();
                    let html =  '<sector id="remove_agency_field_default">' +
                                    '<div class="col s12 m12 card-panel">' +
                                        '<div class="col s12 m12">'+
                                            '<div class="row">'+
                                                '<div class="col s12 m12 input-field">'+
                                                    '<h6 style="display:inline-block">Agency Details</h6>'+
                                                '</div>'+
                                                '<div class="col s12 m6 input-field">'+
                                                    '<input id="agency_name" name="agency_name[]" type="text" class="validate" data-error=".errorTxt3" required>'+
                                                    '<label for="agency_name">Agency Name<span class="red-text">*</span></label>'+
                                                    '<small class="errorTxt3"></small>'+
                                                '</div>'+
                                                '<div class="col s12 m6 input-field">'+
                                                    '<input id="agency_code" name="agency_code[]" type="text" class="validate" data-error=".errorTxt2">'+
                                                    '<label for="agency_code">Agency Code</label>'+
                                                    '<small class="errorTxt2"></small>'+
                                                '</div>'+
                                            '</div>'+
                                        '</div>'+
                                        '<div class="col s12 m12">'+
                                            '<div class="row">'+
                                                '<div class="col s12 m6 input-field">'+
                                                    '<p for="agency_type">Agency Type<span class="red-text">*</span></p>'+
                                                    '<select name="agency_type[]" class="select2 select22 browser-default" required>'+
                                                        '<option disabled selected value="">Please select the agency type</option>'+
                                                        '<option value="BBN">BBN | Badan Berkanun Negeri</option>'+
                                                        '<option value="BBP">BBP | Badan Berkanun Persekutuan</option>'+
                                                        '<option value="PAN">PAN | Perkhidmatan Awam Negeri</option>'+
                                                        '<option value="PAP">PAP | Perkhidmatan Awam Persekutuan</option>'+
                                                        '<option value="PBT">PBT | Pihak Berkuasa Tempatan</option>'+
                                                    '</select>'+
                                                '</div>'+
                                                '<div class="col s12 m6 input-field">' +
                                                    '<p>Status <span class="red-text">*</span></p>' +
                                                    '<select name="agency_status[]" class="select2 select22 browser-default" data-label="Status">' +
                                                        '<option value="1">Active</option>' +
                                                        '<option value="0">Inactive</option>' +
                                                    '</select>' +
                                                    '<small class="red-text font-weight-bold validate_status_msg"></small>' +
                                                '</div>' +
                                            '</div>'+
                                        '</div>'+
                                    '</div>'+
                                '</sector>';
                $('#remove_agency_field_default').remove();
                $('#append_agency_field').append(html);
                $('.select22').select2();
                initValidation();
                } else {
                    $('#ministry_code').val('');
                    $('#ministry_type').val('');
                    $('#ministry_code').prop('disabled', false);
                    $('#ministry_type').prop('disabled', false);
                    $('#ministry_status').prop('disabled', false);
                    $('#ministry_status').select2();
                    $('#ministry_type').select2();
                    $('#remove_agency_field_default').remove();
                }

            },
        });
    });

    $(document).on('click', '#add_agency_field', function(){
        let count = $('#add_agency_field').val();
        count = parseFloat(count)+ 1;
        $('#add_agency_field').val(count);

        let html =  '<sector id="remove_agency_field'+count+'">' +
                        '<div class="col s12 m12 card-panel">' +
                            '<div class="col s12 m12">'+
                                '<div class="row">'+
                                    '<div class="col s12 m12 input-field">'+
                                        '<h6 style="display:inline-block">Agency Details</h6>'+
                                        '<i class="material-icons float-right remove_agency_field" style="cursor:pointer" data-id="'+count+'">clear</i>' +
                                    '</div>'+
                                    '<div class="col s12 m6 input-field">'+
                                        '<input id="agency_name" name="agency_name[]" type="text" class="validate" data-error=".errorTxt3" required>'+
                                        '<label for="agency_name">Agency Name<span class="red-text">*</span></label>'+
                                        '<small class="errorTxt3"></small>'+
                                    '</div>'+
                                    '<div class="col s12 m6 input-field">'+
                                        '<input id="agency_code" name="agency_code[]" type="text" class="validate" data-error=".errorTxt2">'+
                                        '<label for="agency_code">Agency Code</label>'+
                                        '<small class="errorTxt2"></small>'+
                                    '</div>'+
                                '</div>'+
                            '</div>'+
                            '<div class="col s12 m12">'+
                                '<div class="row">'+
                                    '<div class="col s12 m6 input-field">'+
                                        '<p for="agency_type">Agency Type<span class="red-text">*</span></p>'+
                                        '<select name="agency_type[]" class="select2 select22 browser-default" required>'+
                                            '<option disabled selected value="">Please select the agency type</option>'+
                                            '<option value="BBN">BBN | Badan Berkanun Negeri</option>'+
                                            '<option value="BBP">BBP | Badan Berkanun Persekutuan</option>'+
                                            '<option value="PAN">PAN | Perkhidmatan Awam Negeri</option>'+
                                            '<option value="PAP">PAP | Perkhidmatan Awam Persekutuan</option>'+
                                            '<option value="PBT">PBT | Pihak Berkuasa Tempatan</option>'+
                                        '</select>'+
                                    '</div>'+
                                    '<div class="col s12 m6 input-field">' +
                                        '<p>Status <span class="red-text">*</span></p>' +
                                        '<select name="agency_status[]" class="select2 select22 browser-default" data-label="Status">' +
                                            '<option value="1">Active</option>' +
                                            '<option value="0">Inactive</option>' +
                                        '</select>' +
                                        '<small class="red-text font-weight-bold validate_status_msg"></small>' +
                                    '</div>' +
                                '</div>'+
                            '</div>'+
                        '</div>'+
                    '</sector>';

        $('#append_agency_field').append(html);
        $('.select22').select2();
        initValidation();
    });

    $(document).on('click','.remove_agency_field', function(){
        let id = $(this).data('id');
        $('#remove_agency_field' + id).remove();
    });


    function renderSelect2()
    {
        $(".select2").each(function() {
            $(this).select2({
                dropdownAutoWidth: true,
                width: '100%',
            });
        });
    }

    if($("#source-ministry").length > 0){
        $("#source-ministry").on("change", function(){
            let agency_id = $(this).val();
            let agency_name = $(this).select2('data')[0].text;
            $(".btn-store-agency-restructure").prop('disabled',true);
            $("#target-ministry").prop('disabled',true);
            $('#target-ministry option').prop("disabled", false);
            $(".collection-item-source").remove();
            $('#target-ministry').val('');
            $('#target-ministry').trigger('change');
            $(".collection-item-target").remove();
            $(".collection-source-ministry").css('display','none');
            $(".btn-source-to-target").prop("disabled", true);
            $(".btn-target-to-source").prop("disabled", true);
            $.post('/settings/agencies/restructure', { _token: $('meta[name="csrf-token"]').attr('content'), agency_id : agency_id},
                function(returnedData){
                    if(returnedData.length > 0){
                        // $(".collection-source-ministry").append('<li class="collection-item collection-item-source"><label><input class="source-ministry-checkItem source-ministry-checkAll" type="checkbox" name="ministry_source_agencies" value="'+agency_id+'" data-name="'+agency_name+'"/><span style="font-weight:bold;color:#888;">'+agency_name+'</span></label></li>');
                        for(var i = 0;i<returnedData.length;i++){
                            let data = returnedData[i];
                            $(".collection-source-ministry").append('<li class="collection-item collection-item-source"><label><input class="source-ministry-checkItem" type="checkbox" name="source_agencies[]" value="'+data.id+'" data-name="'+data.agency_name+'"/><span style="font-weight:bold;color:#888;"><i class="material-icons dp48">subdirectory_arrow_right</i> '+data.agency_name+'</span></label></li>');
                        }
                        $(".collection-source-ministry").css('display','initial');
                        $("#target-ministry").prop('disabled',false);
                        $('#target-ministry option[value="'+agency_id+'"]').prop("disabled", true);
                    }
            });

            //
        });
    }

    if($(".source-ministry-checkAll").length > 0){
        $(".source-ministry-checkAll").click(function(){
            $('.source-ministry-checkItem').not(this).prop('checked', this.checked);
        });
    }

    if($("#target-ministry").length > 0){
        $("#target-ministry").on("change", function(){
            let agency_id = $(this).val();
            let agency_name = $(this).select2('data')[0].text;

            $(".collection-item-target").remove();
            $(".btn-source-to-target").prop("disabled", false);
            $(".btn-target-to-source").prop("disabled", false);
            $(".btn-store-agency-restructure").prop('disabled',false);
            $(".collection-target-ministry").css('display','none');
            $.post('/settings/agencies/restructure', { _token: $('meta[name="csrf-token"]').attr('content'), agency_id : agency_id, agency_name: agency_name},
                function(returnedData){
                    if(returnedData.length > 0){
                        // $(".collection-target-ministry").append('<li class="collection-item collection-item-target"><label><input class="target-ministry-checkItem target-ministry-checkAll" name="ministry_target_agencies" type="checkbox" value="'+agency_id+'" data-name="'+agency_name+'"/><span style="font-weight:bold;color:#888;">'+agency_name+'</span></label></li>');
                        for(var i = 0;i<returnedData.length;i++){
                            let data = returnedData[i];
                            $(".collection-target-ministry").append('<li class="collection-item collection-item-target"><label><input class="target-ministry-checkItem" name="target_agencies[]" type="checkbox" value="'+data.id+'" data-name="'+data.agency_name+'"/><span style="font-weight:bold;color:#888;"><i class="material-icons dp48">subdirectory_arrow_right</i> '+data.agency_name+'</span></label></li>');
                            // $(".btn-source-to-target").prop("disabled", false);
                            // $(".btn-source-to-source").prop("disabled", false);
                        }
                        // $(".collection-target-ministry").css('display','initial');
                    }
                    $(".collection-target-ministry").css('display','initial');
            });

            //
        });
    }

    if($(".target-ministry-checkAll").length > 0){
        $(".target-ministry-checkAll").click(function(){
            $('.target-ministry-checkItem').not(this).prop('checked', this.checked);
        });
    }

    if($(".btn-source-to-target").length > 0){
        $(".btn-source-to-target").on("click", function(e){
            if($(".source-ministry-checkItem:checked").length > 0){
                $(".source-ministry-checkItem").each(function(){
                    if($(this).is(":checked")){
                        let id = $(this).val();
                        let name = $(this).data("name");
                        $(".collection-target-ministry").append('<li class="collection-item collection-item-target"><label><input class="target-ministry-checkItem" type="checkbox" name="target_agencies[]" value="'+id+'" data-name="'+name+'"/><span style="font-weight:bold;color:#888;"><i class="material-icons dp48">subdirectory_arrow_right</i>'+name+'</span></label></li>');
                        $(this).parent().parent().remove();
                    }
                });
            }
            else{
                Swal.fire(
                    'No Agency Selected',
                    'Please select at least one agency to proceed.',
                    'warning'
                  );
            }
        });
    }

    if($(".btn-target-to-source").length > 0){
        $(".btn-target-to-source").on("click", function(e){
            if($(".target-ministry-checkItem:checked").length > 0){
                $(".target-ministry-checkItem").each(function(){
                    if($(this).is(":checked")){
                        let id = $(this).val();
                        let name = $(this).data("name");
                        $(".collection-source-ministry").append('<li class="collection-item collection-item-source"><label><input class="source-ministry-checkItem" type="checkbox" name="source_agencies[]" value="'+id+'" data-name="'+name+'"/><span style="font-weight:bold;color:#888;"><i class="material-icons dp48">subdirectory_arrow_right</i>'+name+'</span></label></li>');
                        $(this).parent().parent().remove();
                    }
                });
            }
            else{
                Swal.fire(
                    'No Agency Selected',
                    'Please select at least one agency to proceed.',
                    'warning'
                  );
            }
        });
    }

    if($(".btn-store-agency-restructure").length > 0){
        $(".btn-store-agency-restructure").on("click", function(e){
            e.preventDefault();
            if($(".target-ministry-checkItem").length > 0){
                $(".target-ministry-checkItem").prop("checked", true);
                $(".source-ministry-checkItem").prop("checked", true);
                swal({
                    title: "Are you sure to save changes?",
                    // text: "Selected agencies will apply  new Ministry / SUK",
                    icon: 'warning',
                    buttons: {
                        cancel: true,
                        confirm: 'Yes, Confirm'
                    }
                }).then((result) => {
                    if (result) {
                        $("#form-agency-restructure").submit();
                    }
                })
            }
            else{
                Swal.fire(
                    'No Agency',
                    'Please select at least one agency to proceed.',
                    'warning'
                  );
            }

        });
    }

    if ($('#CreateAgencyForm').length > 0) {
        if ($('#GetRole').data('value') == 'supervisor') {
            $('.select2_create_agency_ministry').prop('readonly', true);
            $('.select2_create_agency_ministry').val($('#GetMinistryID').data('value'));
            $('.select2_create_agency_ministry').select2();
            let val = $('.select2_create_agency_ministry').val();
            $.ajax({
                type: 'POST',
                url: '/settings/agencies/ministry/details',
                data: {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                    name: val,
                },
                success: function (result) {
                    if (result.agency_name) {
                        $('#ministry_code').val(result.agency_code);
                        $('#ministry_type').val(result.agency_type);
                        $('#ministry_status').val(result.status);
                        $('#ministry_code').prop('disabled', true);
                        $('#ministry_type').prop('disabled', true);
                        $('#ministry_status').prop('disabled', true);
                        $('#ministry_id').prop('disabled', true);
                        if ($('#ministry_id_hidden').length == 0) {
                            $('#not-ministry').append('<input type="hidden" name="ministry_id" id="ministry_id_hidden">');
                            $('#ministry_id_hidden').val(result.agency_name);
                        }
                        $('#ministry_type').select2();
                        $('#ministry_status').select2();
                    } else {
                        $('#ministry_code').val('');
                        $('#ministry_type').val('');
                        $('#ministry_status').val('1');
                        $('#ministry_code').prop('disabled', false);
                        $('#ministry_type').prop('disabled', false);
                        $('#ministry_id').prop('disabled', false);
                        $('#ministry_status').prop('disabled', false);
                        if ($('#ministry_id_hidden').length > 0) {
                            $('#ministry_id_hidden').remove();
                        }
                        $('#ministry_type').select2();
                        $('#ministry_status').select2();
                    }
                },
            });
        }
    }
    initValidation();
    function initValidation(){
        // console.log('ssss');
        if($('input[name="agency_name[]"').length > 0){
            // console.log('load');
            $('input[name="agency_name[]"').on("keyup change", function(e){
                let val = $(this).val();
                if(val.match(/[^a-z,^A-Z,^0-9 ,(),-\.]/) != null){
                    $(this)[0].setCustomValidity('Invalid symbols except comma . , - ( )');
                }
                else{
                    $(this)[0].setCustomValidity("");
                }
            });
        }
        if($('#ministry_id').length > 0){
            // console.log('load');
            let val = $('#ministry_id').val();
            if(val != null){
                if(val.match(/[^a-z,^A-Z,^0-9 ,(),-\.]/) != null){
                    $(this)[0].setCustomValidity('Invalid symbols except comma . , - ( )');
                }
                else{
                    $('#ministry_id')[0].setCustomValidity("");
                }
            }
            else{
                $('#ministry_id')[0].setCustomValidity("");
            }
            $('#ministry_id').on("keyup change", function(e){
                let val = $(this).val();
                if(val.match(/[^a-z,^A-Z,^0-9 ,(),-\.]/) != null){
                    $(this)[0].setCustomValidity('Invalid symbols except comma . , - ( )');
                }
                else{
                    $(this)[0].setCustomValidity("");
                }
            });
        }
    }



});



